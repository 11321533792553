// Generated by purs version 0.15.4
import * as Data_Array from "../Data.Array/index.js";
import * as Data_CodePoint_Unicode_Internal from "../Data.CodePoint.Unicode.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordInt);
var zeroRec = function (code) {
    return {
        code: code,
        lower: [  ],
        title: [  ],
        upper: [  ],
        fold: 0,
        foldFull: [  ]
    };
};
var rules = [ {
    code: 65,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 97,
    foldFull: [ 97 ]
}, {
    code: 66,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 98,
    foldFull: [ 98 ]
}, {
    code: 67,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 99,
    foldFull: [ 99 ]
}, {
    code: 68,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 100,
    foldFull: [ 100 ]
}, {
    code: 69,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 101,
    foldFull: [ 101 ]
}, {
    code: 70,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 102,
    foldFull: [ 102 ]
}, {
    code: 71,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 103,
    foldFull: [ 103 ]
}, {
    code: 72,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 104,
    foldFull: [ 104 ]
}, {
    code: 73,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 105,
    foldFull: [ 105 ]
}, {
    code: 74,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 106,
    foldFull: [ 106 ]
}, {
    code: 75,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 107,
    foldFull: [ 107 ]
}, {
    code: 76,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 108,
    foldFull: [ 108 ]
}, {
    code: 77,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 109,
    foldFull: [ 109 ]
}, {
    code: 78,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 110,
    foldFull: [ 110 ]
}, {
    code: 79,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 111,
    foldFull: [ 111 ]
}, {
    code: 80,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 112,
    foldFull: [ 112 ]
}, {
    code: 81,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 113,
    foldFull: [ 113 ]
}, {
    code: 82,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 114,
    foldFull: [ 114 ]
}, {
    code: 83,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 115,
    foldFull: [ 115 ]
}, {
    code: 84,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 116,
    foldFull: [ 116 ]
}, {
    code: 85,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 117,
    foldFull: [ 117 ]
}, {
    code: 86,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 118,
    foldFull: [ 118 ]
}, {
    code: 87,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 119,
    foldFull: [ 119 ]
}, {
    code: 88,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 120,
    foldFull: [ 120 ]
}, {
    code: 89,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 121,
    foldFull: [ 121 ]
}, {
    code: 90,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 122,
    foldFull: [ 122 ]
}, {
    code: 181,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 956,
    foldFull: [ 956 ]
}, {
    code: 192,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 224,
    foldFull: [ 224 ]
}, {
    code: 193,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 225,
    foldFull: [ 225 ]
}, {
    code: 194,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 226,
    foldFull: [ 226 ]
}, {
    code: 195,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 227,
    foldFull: [ 227 ]
}, {
    code: 196,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 228,
    foldFull: [ 228 ]
}, {
    code: 197,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 229,
    foldFull: [ 229 ]
}, {
    code: 198,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 230,
    foldFull: [ 230 ]
}, {
    code: 199,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 231,
    foldFull: [ 231 ]
}, {
    code: 200,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 232,
    foldFull: [ 232 ]
}, {
    code: 201,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 233,
    foldFull: [ 233 ]
}, {
    code: 202,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 234,
    foldFull: [ 234 ]
}, {
    code: 203,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 235,
    foldFull: [ 235 ]
}, {
    code: 204,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 236,
    foldFull: [ 236 ]
}, {
    code: 205,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 237,
    foldFull: [ 237 ]
}, {
    code: 206,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 238,
    foldFull: [ 238 ]
}, {
    code: 207,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 239,
    foldFull: [ 239 ]
}, {
    code: 208,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 240,
    foldFull: [ 240 ]
}, {
    code: 209,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 241,
    foldFull: [ 241 ]
}, {
    code: 210,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 242,
    foldFull: [ 242 ]
}, {
    code: 211,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 243,
    foldFull: [ 243 ]
}, {
    code: 212,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 244,
    foldFull: [ 244 ]
}, {
    code: 213,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 245,
    foldFull: [ 245 ]
}, {
    code: 214,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 246,
    foldFull: [ 246 ]
}, {
    code: 216,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 248,
    foldFull: [ 248 ]
}, {
    code: 217,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 249,
    foldFull: [ 249 ]
}, {
    code: 218,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 250,
    foldFull: [ 250 ]
}, {
    code: 219,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 251,
    foldFull: [ 251 ]
}, {
    code: 220,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 252,
    foldFull: [ 252 ]
}, {
    code: 221,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 253,
    foldFull: [ 253 ]
}, {
    code: 222,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 254,
    foldFull: [ 254 ]
}, {
    code: 223,
    lower: [ 223 ],
    title: [ 83, 115 ],
    upper: [ 83, 83 ],
    fold: 0,
    foldFull: [ 115, 115 ]
}, {
    code: 223,
    lower: [ 223 ],
    title: [ 83, 115 ],
    upper: [ 83, 83 ],
    fold: 0,
    foldFull: [ 115, 115 ]
}, {
    code: 256,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 257,
    foldFull: [ 257 ]
}, {
    code: 258,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 259,
    foldFull: [ 259 ]
}, {
    code: 260,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 261,
    foldFull: [ 261 ]
}, {
    code: 262,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 263,
    foldFull: [ 263 ]
}, {
    code: 264,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 265,
    foldFull: [ 265 ]
}, {
    code: 266,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 267,
    foldFull: [ 267 ]
}, {
    code: 268,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 269,
    foldFull: [ 269 ]
}, {
    code: 270,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 271,
    foldFull: [ 271 ]
}, {
    code: 272,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 273,
    foldFull: [ 273 ]
}, {
    code: 274,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 275,
    foldFull: [ 275 ]
}, {
    code: 276,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 277,
    foldFull: [ 277 ]
}, {
    code: 278,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 279,
    foldFull: [ 279 ]
}, {
    code: 280,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 281,
    foldFull: [ 281 ]
}, {
    code: 282,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 283,
    foldFull: [ 283 ]
}, {
    code: 284,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 285,
    foldFull: [ 285 ]
}, {
    code: 286,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 287,
    foldFull: [ 287 ]
}, {
    code: 288,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 289,
    foldFull: [ 289 ]
}, {
    code: 290,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 291,
    foldFull: [ 291 ]
}, {
    code: 292,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 293,
    foldFull: [ 293 ]
}, {
    code: 294,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 295,
    foldFull: [ 295 ]
}, {
    code: 296,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 297,
    foldFull: [ 297 ]
}, {
    code: 298,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 299,
    foldFull: [ 299 ]
}, {
    code: 300,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 301,
    foldFull: [ 301 ]
}, {
    code: 302,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 303,
    foldFull: [ 303 ]
}, {
    code: 304,
    lower: [ 105, 775 ],
    title: [ 304 ],
    upper: [ 304 ],
    fold: 0,
    foldFull: [ 105, 775 ]
}, {
    code: 304,
    lower: [ 105, 775 ],
    title: [ 304 ],
    upper: [ 304 ],
    fold: 0,
    foldFull: [ 105, 775 ]
}, {
    code: 306,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 307,
    foldFull: [ 307 ]
}, {
    code: 308,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 309,
    foldFull: [ 309 ]
}, {
    code: 310,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 311,
    foldFull: [ 311 ]
}, {
    code: 313,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 314,
    foldFull: [ 314 ]
}, {
    code: 315,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 316,
    foldFull: [ 316 ]
}, {
    code: 317,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 318,
    foldFull: [ 318 ]
}, {
    code: 319,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 320,
    foldFull: [ 320 ]
}, {
    code: 321,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 322,
    foldFull: [ 322 ]
}, {
    code: 323,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 324,
    foldFull: [ 324 ]
}, {
    code: 325,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 326,
    foldFull: [ 326 ]
}, {
    code: 327,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 328,
    foldFull: [ 328 ]
}, {
    code: 329,
    lower: [ 329 ],
    title: [ 700, 78 ],
    upper: [ 700, 78 ],
    fold: 0,
    foldFull: [ 700, 110 ]
}, {
    code: 329,
    lower: [ 329 ],
    title: [ 700, 78 ],
    upper: [ 700, 78 ],
    fold: 0,
    foldFull: [ 700, 110 ]
}, {
    code: 330,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 331,
    foldFull: [ 331 ]
}, {
    code: 332,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 333,
    foldFull: [ 333 ]
}, {
    code: 334,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 335,
    foldFull: [ 335 ]
}, {
    code: 336,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 337,
    foldFull: [ 337 ]
}, {
    code: 338,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 339,
    foldFull: [ 339 ]
}, {
    code: 340,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 341,
    foldFull: [ 341 ]
}, {
    code: 342,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 343,
    foldFull: [ 343 ]
}, {
    code: 344,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 345,
    foldFull: [ 345 ]
}, {
    code: 346,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 347,
    foldFull: [ 347 ]
}, {
    code: 348,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 349,
    foldFull: [ 349 ]
}, {
    code: 350,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 351,
    foldFull: [ 351 ]
}, {
    code: 352,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 353,
    foldFull: [ 353 ]
}, {
    code: 354,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 355,
    foldFull: [ 355 ]
}, {
    code: 356,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 357,
    foldFull: [ 357 ]
}, {
    code: 358,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 359,
    foldFull: [ 359 ]
}, {
    code: 360,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 361,
    foldFull: [ 361 ]
}, {
    code: 362,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 363,
    foldFull: [ 363 ]
}, {
    code: 364,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 365,
    foldFull: [ 365 ]
}, {
    code: 366,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 367,
    foldFull: [ 367 ]
}, {
    code: 368,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 369,
    foldFull: [ 369 ]
}, {
    code: 370,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 371,
    foldFull: [ 371 ]
}, {
    code: 372,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 373,
    foldFull: [ 373 ]
}, {
    code: 374,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 375,
    foldFull: [ 375 ]
}, {
    code: 376,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 255,
    foldFull: [ 255 ]
}, {
    code: 377,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 378,
    foldFull: [ 378 ]
}, {
    code: 379,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 380,
    foldFull: [ 380 ]
}, {
    code: 381,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 382,
    foldFull: [ 382 ]
}, {
    code: 383,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 115,
    foldFull: [ 115 ]
}, {
    code: 385,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 595,
    foldFull: [ 595 ]
}, {
    code: 386,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 387,
    foldFull: [ 387 ]
}, {
    code: 388,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 389,
    foldFull: [ 389 ]
}, {
    code: 390,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 596,
    foldFull: [ 596 ]
}, {
    code: 391,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 392,
    foldFull: [ 392 ]
}, {
    code: 393,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 598,
    foldFull: [ 598 ]
}, {
    code: 394,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 599,
    foldFull: [ 599 ]
}, {
    code: 395,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 396,
    foldFull: [ 396 ]
}, {
    code: 398,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 477,
    foldFull: [ 477 ]
}, {
    code: 399,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 601,
    foldFull: [ 601 ]
}, {
    code: 400,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 603,
    foldFull: [ 603 ]
}, {
    code: 401,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 402,
    foldFull: [ 402 ]
}, {
    code: 403,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 608,
    foldFull: [ 608 ]
}, {
    code: 404,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 611,
    foldFull: [ 611 ]
}, {
    code: 406,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 617,
    foldFull: [ 617 ]
}, {
    code: 407,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 616,
    foldFull: [ 616 ]
}, {
    code: 408,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 409,
    foldFull: [ 409 ]
}, {
    code: 412,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 623,
    foldFull: [ 623 ]
}, {
    code: 413,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 626,
    foldFull: [ 626 ]
}, {
    code: 415,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 629,
    foldFull: [ 629 ]
}, {
    code: 416,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 417,
    foldFull: [ 417 ]
}, {
    code: 418,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 419,
    foldFull: [ 419 ]
}, {
    code: 420,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 421,
    foldFull: [ 421 ]
}, {
    code: 422,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 640,
    foldFull: [ 640 ]
}, {
    code: 423,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 424,
    foldFull: [ 424 ]
}, {
    code: 425,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 643,
    foldFull: [ 643 ]
}, {
    code: 428,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 429,
    foldFull: [ 429 ]
}, {
    code: 430,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 648,
    foldFull: [ 648 ]
}, {
    code: 431,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 432,
    foldFull: [ 432 ]
}, {
    code: 433,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 650,
    foldFull: [ 650 ]
}, {
    code: 434,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 651,
    foldFull: [ 651 ]
}, {
    code: 435,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 436,
    foldFull: [ 436 ]
}, {
    code: 437,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 438,
    foldFull: [ 438 ]
}, {
    code: 439,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 658,
    foldFull: [ 658 ]
}, {
    code: 440,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 441,
    foldFull: [ 441 ]
}, {
    code: 444,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 445,
    foldFull: [ 445 ]
}, {
    code: 452,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 454,
    foldFull: [ 454 ]
}, {
    code: 453,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 454,
    foldFull: [ 454 ]
}, {
    code: 455,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 457,
    foldFull: [ 457 ]
}, {
    code: 456,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 457,
    foldFull: [ 457 ]
}, {
    code: 458,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 460,
    foldFull: [ 460 ]
}, {
    code: 459,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 460,
    foldFull: [ 460 ]
}, {
    code: 461,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 462,
    foldFull: [ 462 ]
}, {
    code: 463,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 464,
    foldFull: [ 464 ]
}, {
    code: 465,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 466,
    foldFull: [ 466 ]
}, {
    code: 467,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 468,
    foldFull: [ 468 ]
}, {
    code: 469,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 470,
    foldFull: [ 470 ]
}, {
    code: 471,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 472,
    foldFull: [ 472 ]
}, {
    code: 473,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 474,
    foldFull: [ 474 ]
}, {
    code: 475,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 476,
    foldFull: [ 476 ]
}, {
    code: 478,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 479,
    foldFull: [ 479 ]
}, {
    code: 480,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 481,
    foldFull: [ 481 ]
}, {
    code: 482,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 483,
    foldFull: [ 483 ]
}, {
    code: 484,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 485,
    foldFull: [ 485 ]
}, {
    code: 486,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 487,
    foldFull: [ 487 ]
}, {
    code: 488,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 489,
    foldFull: [ 489 ]
}, {
    code: 490,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 491,
    foldFull: [ 491 ]
}, {
    code: 492,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 493,
    foldFull: [ 493 ]
}, {
    code: 494,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 495,
    foldFull: [ 495 ]
}, {
    code: 496,
    lower: [ 496 ],
    title: [ 74, 780 ],
    upper: [ 74, 780 ],
    fold: 0,
    foldFull: [ 106, 780 ]
}, {
    code: 496,
    lower: [ 496 ],
    title: [ 74, 780 ],
    upper: [ 74, 780 ],
    fold: 0,
    foldFull: [ 106, 780 ]
}, {
    code: 497,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 499,
    foldFull: [ 499 ]
}, {
    code: 498,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 499,
    foldFull: [ 499 ]
}, {
    code: 500,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 501,
    foldFull: [ 501 ]
}, {
    code: 502,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 405,
    foldFull: [ 405 ]
}, {
    code: 503,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 447,
    foldFull: [ 447 ]
}, {
    code: 504,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 505,
    foldFull: [ 505 ]
}, {
    code: 506,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 507,
    foldFull: [ 507 ]
}, {
    code: 508,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 509,
    foldFull: [ 509 ]
}, {
    code: 510,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 511,
    foldFull: [ 511 ]
}, {
    code: 512,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 513,
    foldFull: [ 513 ]
}, {
    code: 514,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 515,
    foldFull: [ 515 ]
}, {
    code: 516,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 517,
    foldFull: [ 517 ]
}, {
    code: 518,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 519,
    foldFull: [ 519 ]
}, {
    code: 520,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 521,
    foldFull: [ 521 ]
}, {
    code: 522,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 523,
    foldFull: [ 523 ]
}, {
    code: 524,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 525,
    foldFull: [ 525 ]
}, {
    code: 526,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 527,
    foldFull: [ 527 ]
}, {
    code: 528,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 529,
    foldFull: [ 529 ]
}, {
    code: 530,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 531,
    foldFull: [ 531 ]
}, {
    code: 532,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 533,
    foldFull: [ 533 ]
}, {
    code: 534,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 535,
    foldFull: [ 535 ]
}, {
    code: 536,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 537,
    foldFull: [ 537 ]
}, {
    code: 538,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 539,
    foldFull: [ 539 ]
}, {
    code: 540,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 541,
    foldFull: [ 541 ]
}, {
    code: 542,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 543,
    foldFull: [ 543 ]
}, {
    code: 544,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 414,
    foldFull: [ 414 ]
}, {
    code: 546,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 547,
    foldFull: [ 547 ]
}, {
    code: 548,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 549,
    foldFull: [ 549 ]
}, {
    code: 550,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 551,
    foldFull: [ 551 ]
}, {
    code: 552,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 553,
    foldFull: [ 553 ]
}, {
    code: 554,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 555,
    foldFull: [ 555 ]
}, {
    code: 556,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 557,
    foldFull: [ 557 ]
}, {
    code: 558,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 559,
    foldFull: [ 559 ]
}, {
    code: 560,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 561,
    foldFull: [ 561 ]
}, {
    code: 562,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 563,
    foldFull: [ 563 ]
}, {
    code: 570,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11365,
    foldFull: [ 11365 ]
}, {
    code: 571,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 572,
    foldFull: [ 572 ]
}, {
    code: 573,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 410,
    foldFull: [ 410 ]
}, {
    code: 574,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11366,
    foldFull: [ 11366 ]
}, {
    code: 577,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 578,
    foldFull: [ 578 ]
}, {
    code: 579,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 384,
    foldFull: [ 384 ]
}, {
    code: 580,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 649,
    foldFull: [ 649 ]
}, {
    code: 581,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 652,
    foldFull: [ 652 ]
}, {
    code: 582,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 583,
    foldFull: [ 583 ]
}, {
    code: 584,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 585,
    foldFull: [ 585 ]
}, {
    code: 586,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 587,
    foldFull: [ 587 ]
}, {
    code: 588,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 589,
    foldFull: [ 589 ]
}, {
    code: 590,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 591,
    foldFull: [ 591 ]
}, {
    code: 837,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 953,
    foldFull: [ 953 ]
}, {
    code: 880,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 881,
    foldFull: [ 881 ]
}, {
    code: 882,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 883,
    foldFull: [ 883 ]
}, {
    code: 886,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 887,
    foldFull: [ 887 ]
}, {
    code: 895,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1011,
    foldFull: [ 1011 ]
}, {
    code: 902,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 940,
    foldFull: [ 940 ]
}, {
    code: 904,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 941,
    foldFull: [ 941 ]
}, {
    code: 905,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 942,
    foldFull: [ 942 ]
}, {
    code: 906,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 943,
    foldFull: [ 943 ]
}, {
    code: 908,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 972,
    foldFull: [ 972 ]
}, {
    code: 910,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 973,
    foldFull: [ 973 ]
}, {
    code: 911,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 974,
    foldFull: [ 974 ]
}, {
    code: 912,
    lower: [ 912 ],
    title: [ 921, 776, 769 ],
    upper: [ 921, 776, 769 ],
    fold: 0,
    foldFull: [ 953, 776, 769 ]
}, {
    code: 912,
    lower: [ 912 ],
    title: [ 921, 776, 769 ],
    upper: [ 921, 776, 769 ],
    fold: 0,
    foldFull: [ 953, 776, 769 ]
}, {
    code: 913,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 945,
    foldFull: [ 945 ]
}, {
    code: 914,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 946,
    foldFull: [ 946 ]
}, {
    code: 915,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 947,
    foldFull: [ 947 ]
}, {
    code: 916,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 948,
    foldFull: [ 948 ]
}, {
    code: 917,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 949,
    foldFull: [ 949 ]
}, {
    code: 918,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 950,
    foldFull: [ 950 ]
}, {
    code: 919,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 951,
    foldFull: [ 951 ]
}, {
    code: 920,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 952,
    foldFull: [ 952 ]
}, {
    code: 921,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 953,
    foldFull: [ 953 ]
}, {
    code: 922,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 954,
    foldFull: [ 954 ]
}, {
    code: 923,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 955,
    foldFull: [ 955 ]
}, {
    code: 924,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 956,
    foldFull: [ 956 ]
}, {
    code: 925,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 957,
    foldFull: [ 957 ]
}, {
    code: 926,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 958,
    foldFull: [ 958 ]
}, {
    code: 927,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 959,
    foldFull: [ 959 ]
}, {
    code: 928,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 960,
    foldFull: [ 960 ]
}, {
    code: 929,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 961,
    foldFull: [ 961 ]
}, {
    code: 931,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 963,
    foldFull: [ 963 ]
}, {
    code: 932,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 964,
    foldFull: [ 964 ]
}, {
    code: 933,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 965,
    foldFull: [ 965 ]
}, {
    code: 934,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 966,
    foldFull: [ 966 ]
}, {
    code: 935,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 967,
    foldFull: [ 967 ]
}, {
    code: 936,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 968,
    foldFull: [ 968 ]
}, {
    code: 937,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 969,
    foldFull: [ 969 ]
}, {
    code: 938,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 970,
    foldFull: [ 970 ]
}, {
    code: 939,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 971,
    foldFull: [ 971 ]
}, {
    code: 944,
    lower: [ 944 ],
    title: [ 933, 776, 769 ],
    upper: [ 933, 776, 769 ],
    fold: 0,
    foldFull: [ 965, 776, 769 ]
}, {
    code: 944,
    lower: [ 944 ],
    title: [ 933, 776, 769 ],
    upper: [ 933, 776, 769 ],
    fold: 0,
    foldFull: [ 965, 776, 769 ]
}, {
    code: 962,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 963,
    foldFull: [ 963 ]
}, {
    code: 975,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 983,
    foldFull: [ 983 ]
}, {
    code: 976,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 946,
    foldFull: [ 946 ]
}, {
    code: 977,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 952,
    foldFull: [ 952 ]
}, {
    code: 981,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 966,
    foldFull: [ 966 ]
}, {
    code: 982,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 960,
    foldFull: [ 960 ]
}, {
    code: 984,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 985,
    foldFull: [ 985 ]
}, {
    code: 986,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 987,
    foldFull: [ 987 ]
}, {
    code: 988,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 989,
    foldFull: [ 989 ]
}, {
    code: 990,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 991,
    foldFull: [ 991 ]
}, {
    code: 992,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 993,
    foldFull: [ 993 ]
}, {
    code: 994,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 995,
    foldFull: [ 995 ]
}, {
    code: 996,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 997,
    foldFull: [ 997 ]
}, {
    code: 998,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 999,
    foldFull: [ 999 ]
}, {
    code: 1000,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1001,
    foldFull: [ 1001 ]
}, {
    code: 1002,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1003,
    foldFull: [ 1003 ]
}, {
    code: 1004,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1005,
    foldFull: [ 1005 ]
}, {
    code: 1006,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1007,
    foldFull: [ 1007 ]
}, {
    code: 1008,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 954,
    foldFull: [ 954 ]
}, {
    code: 1009,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 961,
    foldFull: [ 961 ]
}, {
    code: 1012,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 952,
    foldFull: [ 952 ]
}, {
    code: 1013,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 949,
    foldFull: [ 949 ]
}, {
    code: 1015,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1016,
    foldFull: [ 1016 ]
}, {
    code: 1017,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1010,
    foldFull: [ 1010 ]
}, {
    code: 1018,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1019,
    foldFull: [ 1019 ]
}, {
    code: 1021,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 891,
    foldFull: [ 891 ]
}, {
    code: 1022,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 892,
    foldFull: [ 892 ]
}, {
    code: 1023,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 893,
    foldFull: [ 893 ]
}, {
    code: 1024,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1104,
    foldFull: [ 1104 ]
}, {
    code: 1025,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1105,
    foldFull: [ 1105 ]
}, {
    code: 1026,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1106,
    foldFull: [ 1106 ]
}, {
    code: 1027,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1107,
    foldFull: [ 1107 ]
}, {
    code: 1028,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1108,
    foldFull: [ 1108 ]
}, {
    code: 1029,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1109,
    foldFull: [ 1109 ]
}, {
    code: 1030,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1110,
    foldFull: [ 1110 ]
}, {
    code: 1031,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1111,
    foldFull: [ 1111 ]
}, {
    code: 1032,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1112,
    foldFull: [ 1112 ]
}, {
    code: 1033,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1113,
    foldFull: [ 1113 ]
}, {
    code: 1034,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1114,
    foldFull: [ 1114 ]
}, {
    code: 1035,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1115,
    foldFull: [ 1115 ]
}, {
    code: 1036,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1116,
    foldFull: [ 1116 ]
}, {
    code: 1037,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1117,
    foldFull: [ 1117 ]
}, {
    code: 1038,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1118,
    foldFull: [ 1118 ]
}, {
    code: 1039,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1119,
    foldFull: [ 1119 ]
}, {
    code: 1040,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1072,
    foldFull: [ 1072 ]
}, {
    code: 1041,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1073,
    foldFull: [ 1073 ]
}, {
    code: 1042,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1074,
    foldFull: [ 1074 ]
}, {
    code: 1043,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1075,
    foldFull: [ 1075 ]
}, {
    code: 1044,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1076,
    foldFull: [ 1076 ]
}, {
    code: 1045,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1077,
    foldFull: [ 1077 ]
}, {
    code: 1046,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1078,
    foldFull: [ 1078 ]
}, {
    code: 1047,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1079,
    foldFull: [ 1079 ]
}, {
    code: 1048,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1080,
    foldFull: [ 1080 ]
}, {
    code: 1049,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1081,
    foldFull: [ 1081 ]
}, {
    code: 1050,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1082,
    foldFull: [ 1082 ]
}, {
    code: 1051,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1083,
    foldFull: [ 1083 ]
}, {
    code: 1052,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1084,
    foldFull: [ 1084 ]
}, {
    code: 1053,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1085,
    foldFull: [ 1085 ]
}, {
    code: 1054,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1086,
    foldFull: [ 1086 ]
}, {
    code: 1055,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1087,
    foldFull: [ 1087 ]
}, {
    code: 1056,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1088,
    foldFull: [ 1088 ]
}, {
    code: 1057,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1089,
    foldFull: [ 1089 ]
}, {
    code: 1058,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1090,
    foldFull: [ 1090 ]
}, {
    code: 1059,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1091,
    foldFull: [ 1091 ]
}, {
    code: 1060,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1092,
    foldFull: [ 1092 ]
}, {
    code: 1061,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1093,
    foldFull: [ 1093 ]
}, {
    code: 1062,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1094,
    foldFull: [ 1094 ]
}, {
    code: 1063,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1095,
    foldFull: [ 1095 ]
}, {
    code: 1064,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1096,
    foldFull: [ 1096 ]
}, {
    code: 1065,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1097,
    foldFull: [ 1097 ]
}, {
    code: 1066,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1098,
    foldFull: [ 1098 ]
}, {
    code: 1067,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1099,
    foldFull: [ 1099 ]
}, {
    code: 1068,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1100,
    foldFull: [ 1100 ]
}, {
    code: 1069,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1101,
    foldFull: [ 1101 ]
}, {
    code: 1070,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1102,
    foldFull: [ 1102 ]
}, {
    code: 1071,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1103,
    foldFull: [ 1103 ]
}, {
    code: 1120,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1121,
    foldFull: [ 1121 ]
}, {
    code: 1122,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1123,
    foldFull: [ 1123 ]
}, {
    code: 1124,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1125,
    foldFull: [ 1125 ]
}, {
    code: 1126,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1127,
    foldFull: [ 1127 ]
}, {
    code: 1128,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1129,
    foldFull: [ 1129 ]
}, {
    code: 1130,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1131,
    foldFull: [ 1131 ]
}, {
    code: 1132,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1133,
    foldFull: [ 1133 ]
}, {
    code: 1134,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1135,
    foldFull: [ 1135 ]
}, {
    code: 1136,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1137,
    foldFull: [ 1137 ]
}, {
    code: 1138,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1139,
    foldFull: [ 1139 ]
}, {
    code: 1140,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1141,
    foldFull: [ 1141 ]
}, {
    code: 1142,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1143,
    foldFull: [ 1143 ]
}, {
    code: 1144,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1145,
    foldFull: [ 1145 ]
}, {
    code: 1146,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1147,
    foldFull: [ 1147 ]
}, {
    code: 1148,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1149,
    foldFull: [ 1149 ]
}, {
    code: 1150,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1151,
    foldFull: [ 1151 ]
}, {
    code: 1152,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1153,
    foldFull: [ 1153 ]
}, {
    code: 1162,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1163,
    foldFull: [ 1163 ]
}, {
    code: 1164,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1165,
    foldFull: [ 1165 ]
}, {
    code: 1166,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1167,
    foldFull: [ 1167 ]
}, {
    code: 1168,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1169,
    foldFull: [ 1169 ]
}, {
    code: 1170,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1171,
    foldFull: [ 1171 ]
}, {
    code: 1172,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1173,
    foldFull: [ 1173 ]
}, {
    code: 1174,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1175,
    foldFull: [ 1175 ]
}, {
    code: 1176,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1177,
    foldFull: [ 1177 ]
}, {
    code: 1178,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1179,
    foldFull: [ 1179 ]
}, {
    code: 1180,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1181,
    foldFull: [ 1181 ]
}, {
    code: 1182,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1183,
    foldFull: [ 1183 ]
}, {
    code: 1184,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1185,
    foldFull: [ 1185 ]
}, {
    code: 1186,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1187,
    foldFull: [ 1187 ]
}, {
    code: 1188,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1189,
    foldFull: [ 1189 ]
}, {
    code: 1190,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1191,
    foldFull: [ 1191 ]
}, {
    code: 1192,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1193,
    foldFull: [ 1193 ]
}, {
    code: 1194,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1195,
    foldFull: [ 1195 ]
}, {
    code: 1196,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1197,
    foldFull: [ 1197 ]
}, {
    code: 1198,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1199,
    foldFull: [ 1199 ]
}, {
    code: 1200,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1201,
    foldFull: [ 1201 ]
}, {
    code: 1202,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1203,
    foldFull: [ 1203 ]
}, {
    code: 1204,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1205,
    foldFull: [ 1205 ]
}, {
    code: 1206,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1207,
    foldFull: [ 1207 ]
}, {
    code: 1208,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1209,
    foldFull: [ 1209 ]
}, {
    code: 1210,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1211,
    foldFull: [ 1211 ]
}, {
    code: 1212,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1213,
    foldFull: [ 1213 ]
}, {
    code: 1214,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1215,
    foldFull: [ 1215 ]
}, {
    code: 1216,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1231,
    foldFull: [ 1231 ]
}, {
    code: 1217,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1218,
    foldFull: [ 1218 ]
}, {
    code: 1219,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1220,
    foldFull: [ 1220 ]
}, {
    code: 1221,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1222,
    foldFull: [ 1222 ]
}, {
    code: 1223,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1224,
    foldFull: [ 1224 ]
}, {
    code: 1225,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1226,
    foldFull: [ 1226 ]
}, {
    code: 1227,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1228,
    foldFull: [ 1228 ]
}, {
    code: 1229,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1230,
    foldFull: [ 1230 ]
}, {
    code: 1232,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1233,
    foldFull: [ 1233 ]
}, {
    code: 1234,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1235,
    foldFull: [ 1235 ]
}, {
    code: 1236,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1237,
    foldFull: [ 1237 ]
}, {
    code: 1238,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1239,
    foldFull: [ 1239 ]
}, {
    code: 1240,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1241,
    foldFull: [ 1241 ]
}, {
    code: 1242,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1243,
    foldFull: [ 1243 ]
}, {
    code: 1244,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1245,
    foldFull: [ 1245 ]
}, {
    code: 1246,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1247,
    foldFull: [ 1247 ]
}, {
    code: 1248,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1249,
    foldFull: [ 1249 ]
}, {
    code: 1250,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1251,
    foldFull: [ 1251 ]
}, {
    code: 1252,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1253,
    foldFull: [ 1253 ]
}, {
    code: 1254,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1255,
    foldFull: [ 1255 ]
}, {
    code: 1256,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1257,
    foldFull: [ 1257 ]
}, {
    code: 1258,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1259,
    foldFull: [ 1259 ]
}, {
    code: 1260,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1261,
    foldFull: [ 1261 ]
}, {
    code: 1262,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1263,
    foldFull: [ 1263 ]
}, {
    code: 1264,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1265,
    foldFull: [ 1265 ]
}, {
    code: 1266,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1267,
    foldFull: [ 1267 ]
}, {
    code: 1268,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1269,
    foldFull: [ 1269 ]
}, {
    code: 1270,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1271,
    foldFull: [ 1271 ]
}, {
    code: 1272,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1273,
    foldFull: [ 1273 ]
}, {
    code: 1274,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1275,
    foldFull: [ 1275 ]
}, {
    code: 1276,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1277,
    foldFull: [ 1277 ]
}, {
    code: 1278,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1279,
    foldFull: [ 1279 ]
}, {
    code: 1280,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1281,
    foldFull: [ 1281 ]
}, {
    code: 1282,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1283,
    foldFull: [ 1283 ]
}, {
    code: 1284,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1285,
    foldFull: [ 1285 ]
}, {
    code: 1286,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1287,
    foldFull: [ 1287 ]
}, {
    code: 1288,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1289,
    foldFull: [ 1289 ]
}, {
    code: 1290,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1291,
    foldFull: [ 1291 ]
}, {
    code: 1292,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1293,
    foldFull: [ 1293 ]
}, {
    code: 1294,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1295,
    foldFull: [ 1295 ]
}, {
    code: 1296,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1297,
    foldFull: [ 1297 ]
}, {
    code: 1298,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1299,
    foldFull: [ 1299 ]
}, {
    code: 1300,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1301,
    foldFull: [ 1301 ]
}, {
    code: 1302,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1303,
    foldFull: [ 1303 ]
}, {
    code: 1304,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1305,
    foldFull: [ 1305 ]
}, {
    code: 1306,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1307,
    foldFull: [ 1307 ]
}, {
    code: 1308,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1309,
    foldFull: [ 1309 ]
}, {
    code: 1310,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1311,
    foldFull: [ 1311 ]
}, {
    code: 1312,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1313,
    foldFull: [ 1313 ]
}, {
    code: 1314,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1315,
    foldFull: [ 1315 ]
}, {
    code: 1316,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1317,
    foldFull: [ 1317 ]
}, {
    code: 1318,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1319,
    foldFull: [ 1319 ]
}, {
    code: 1320,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1321,
    foldFull: [ 1321 ]
}, {
    code: 1322,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1323,
    foldFull: [ 1323 ]
}, {
    code: 1324,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1325,
    foldFull: [ 1325 ]
}, {
    code: 1326,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1327,
    foldFull: [ 1327 ]
}, {
    code: 1329,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1377,
    foldFull: [ 1377 ]
}, {
    code: 1330,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1378,
    foldFull: [ 1378 ]
}, {
    code: 1331,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1379,
    foldFull: [ 1379 ]
}, {
    code: 1332,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1380,
    foldFull: [ 1380 ]
}, {
    code: 1333,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1381,
    foldFull: [ 1381 ]
}, {
    code: 1334,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1382,
    foldFull: [ 1382 ]
}, {
    code: 1335,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1383,
    foldFull: [ 1383 ]
}, {
    code: 1336,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1384,
    foldFull: [ 1384 ]
}, {
    code: 1337,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1385,
    foldFull: [ 1385 ]
}, {
    code: 1338,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1386,
    foldFull: [ 1386 ]
}, {
    code: 1339,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1387,
    foldFull: [ 1387 ]
}, {
    code: 1340,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1388,
    foldFull: [ 1388 ]
}, {
    code: 1341,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1389,
    foldFull: [ 1389 ]
}, {
    code: 1342,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1390,
    foldFull: [ 1390 ]
}, {
    code: 1343,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1391,
    foldFull: [ 1391 ]
}, {
    code: 1344,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1392,
    foldFull: [ 1392 ]
}, {
    code: 1345,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1393,
    foldFull: [ 1393 ]
}, {
    code: 1346,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1394,
    foldFull: [ 1394 ]
}, {
    code: 1347,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1395,
    foldFull: [ 1395 ]
}, {
    code: 1348,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1396,
    foldFull: [ 1396 ]
}, {
    code: 1349,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1397,
    foldFull: [ 1397 ]
}, {
    code: 1350,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1398,
    foldFull: [ 1398 ]
}, {
    code: 1351,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1399,
    foldFull: [ 1399 ]
}, {
    code: 1352,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1400,
    foldFull: [ 1400 ]
}, {
    code: 1353,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1401,
    foldFull: [ 1401 ]
}, {
    code: 1354,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1402,
    foldFull: [ 1402 ]
}, {
    code: 1355,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1403,
    foldFull: [ 1403 ]
}, {
    code: 1356,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1404,
    foldFull: [ 1404 ]
}, {
    code: 1357,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1405,
    foldFull: [ 1405 ]
}, {
    code: 1358,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1406,
    foldFull: [ 1406 ]
}, {
    code: 1359,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1407,
    foldFull: [ 1407 ]
}, {
    code: 1360,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1408,
    foldFull: [ 1408 ]
}, {
    code: 1361,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1409,
    foldFull: [ 1409 ]
}, {
    code: 1362,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1410,
    foldFull: [ 1410 ]
}, {
    code: 1363,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1411,
    foldFull: [ 1411 ]
}, {
    code: 1364,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1412,
    foldFull: [ 1412 ]
}, {
    code: 1365,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1413,
    foldFull: [ 1413 ]
}, {
    code: 1366,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1414,
    foldFull: [ 1414 ]
}, {
    code: 1415,
    lower: [ 1415 ],
    title: [ 1333, 1410 ],
    upper: [ 1333, 1362 ],
    fold: 0,
    foldFull: [ 1381, 1410 ]
}, {
    code: 1415,
    lower: [ 1415 ],
    title: [ 1333, 1410 ],
    upper: [ 1333, 1362 ],
    fold: 0,
    foldFull: [ 1381, 1410 ]
}, {
    code: 4256,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11520,
    foldFull: [ 11520 ]
}, {
    code: 4257,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11521,
    foldFull: [ 11521 ]
}, {
    code: 4258,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11522,
    foldFull: [ 11522 ]
}, {
    code: 4259,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11523,
    foldFull: [ 11523 ]
}, {
    code: 4260,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11524,
    foldFull: [ 11524 ]
}, {
    code: 4261,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11525,
    foldFull: [ 11525 ]
}, {
    code: 4262,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11526,
    foldFull: [ 11526 ]
}, {
    code: 4263,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11527,
    foldFull: [ 11527 ]
}, {
    code: 4264,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11528,
    foldFull: [ 11528 ]
}, {
    code: 4265,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11529,
    foldFull: [ 11529 ]
}, {
    code: 4266,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11530,
    foldFull: [ 11530 ]
}, {
    code: 4267,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11531,
    foldFull: [ 11531 ]
}, {
    code: 4268,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11532,
    foldFull: [ 11532 ]
}, {
    code: 4269,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11533,
    foldFull: [ 11533 ]
}, {
    code: 4270,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11534,
    foldFull: [ 11534 ]
}, {
    code: 4271,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11535,
    foldFull: [ 11535 ]
}, {
    code: 4272,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11536,
    foldFull: [ 11536 ]
}, {
    code: 4273,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11537,
    foldFull: [ 11537 ]
}, {
    code: 4274,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11538,
    foldFull: [ 11538 ]
}, {
    code: 4275,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11539,
    foldFull: [ 11539 ]
}, {
    code: 4276,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11540,
    foldFull: [ 11540 ]
}, {
    code: 4277,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11541,
    foldFull: [ 11541 ]
}, {
    code: 4278,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11542,
    foldFull: [ 11542 ]
}, {
    code: 4279,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11543,
    foldFull: [ 11543 ]
}, {
    code: 4280,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11544,
    foldFull: [ 11544 ]
}, {
    code: 4281,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11545,
    foldFull: [ 11545 ]
}, {
    code: 4282,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11546,
    foldFull: [ 11546 ]
}, {
    code: 4283,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11547,
    foldFull: [ 11547 ]
}, {
    code: 4284,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11548,
    foldFull: [ 11548 ]
}, {
    code: 4285,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11549,
    foldFull: [ 11549 ]
}, {
    code: 4286,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11550,
    foldFull: [ 11550 ]
}, {
    code: 4287,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11551,
    foldFull: [ 11551 ]
}, {
    code: 4288,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11552,
    foldFull: [ 11552 ]
}, {
    code: 4289,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11553,
    foldFull: [ 11553 ]
}, {
    code: 4290,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11554,
    foldFull: [ 11554 ]
}, {
    code: 4291,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11555,
    foldFull: [ 11555 ]
}, {
    code: 4292,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11556,
    foldFull: [ 11556 ]
}, {
    code: 4293,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11557,
    foldFull: [ 11557 ]
}, {
    code: 4295,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11559,
    foldFull: [ 11559 ]
}, {
    code: 4301,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11565,
    foldFull: [ 11565 ]
}, {
    code: 5112,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5104,
    foldFull: [ 5104 ]
}, {
    code: 5113,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5105,
    foldFull: [ 5105 ]
}, {
    code: 5114,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5106,
    foldFull: [ 5106 ]
}, {
    code: 5115,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5107,
    foldFull: [ 5107 ]
}, {
    code: 5116,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5108,
    foldFull: [ 5108 ]
}, {
    code: 5117,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5109,
    foldFull: [ 5109 ]
}, {
    code: 7296,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1074,
    foldFull: [ 1074 ]
}, {
    code: 7297,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1076,
    foldFull: [ 1076 ]
}, {
    code: 7298,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1086,
    foldFull: [ 1086 ]
}, {
    code: 7299,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1089,
    foldFull: [ 1089 ]
}, {
    code: 7300,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1090,
    foldFull: [ 1090 ]
}, {
    code: 7301,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1090,
    foldFull: [ 1090 ]
}, {
    code: 7302,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1098,
    foldFull: [ 1098 ]
}, {
    code: 7303,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 1123,
    foldFull: [ 1123 ]
}, {
    code: 7304,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42571,
    foldFull: [ 42571 ]
}, {
    code: 7312,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4304,
    foldFull: [ 4304 ]
}, {
    code: 7313,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4305,
    foldFull: [ 4305 ]
}, {
    code: 7314,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4306,
    foldFull: [ 4306 ]
}, {
    code: 7315,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4307,
    foldFull: [ 4307 ]
}, {
    code: 7316,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4308,
    foldFull: [ 4308 ]
}, {
    code: 7317,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4309,
    foldFull: [ 4309 ]
}, {
    code: 7318,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4310,
    foldFull: [ 4310 ]
}, {
    code: 7319,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4311,
    foldFull: [ 4311 ]
}, {
    code: 7320,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4312,
    foldFull: [ 4312 ]
}, {
    code: 7321,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4313,
    foldFull: [ 4313 ]
}, {
    code: 7322,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4314,
    foldFull: [ 4314 ]
}, {
    code: 7323,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4315,
    foldFull: [ 4315 ]
}, {
    code: 7324,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4316,
    foldFull: [ 4316 ]
}, {
    code: 7325,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4317,
    foldFull: [ 4317 ]
}, {
    code: 7326,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4318,
    foldFull: [ 4318 ]
}, {
    code: 7327,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4319,
    foldFull: [ 4319 ]
}, {
    code: 7328,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4320,
    foldFull: [ 4320 ]
}, {
    code: 7329,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4321,
    foldFull: [ 4321 ]
}, {
    code: 7330,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4322,
    foldFull: [ 4322 ]
}, {
    code: 7331,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4323,
    foldFull: [ 4323 ]
}, {
    code: 7332,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4324,
    foldFull: [ 4324 ]
}, {
    code: 7333,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4325,
    foldFull: [ 4325 ]
}, {
    code: 7334,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4326,
    foldFull: [ 4326 ]
}, {
    code: 7335,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4327,
    foldFull: [ 4327 ]
}, {
    code: 7336,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4328,
    foldFull: [ 4328 ]
}, {
    code: 7337,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4329,
    foldFull: [ 4329 ]
}, {
    code: 7338,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4330,
    foldFull: [ 4330 ]
}, {
    code: 7339,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4331,
    foldFull: [ 4331 ]
}, {
    code: 7340,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4332,
    foldFull: [ 4332 ]
}, {
    code: 7341,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4333,
    foldFull: [ 4333 ]
}, {
    code: 7342,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4334,
    foldFull: [ 4334 ]
}, {
    code: 7343,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4335,
    foldFull: [ 4335 ]
}, {
    code: 7344,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4336,
    foldFull: [ 4336 ]
}, {
    code: 7345,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4337,
    foldFull: [ 4337 ]
}, {
    code: 7346,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4338,
    foldFull: [ 4338 ]
}, {
    code: 7347,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4339,
    foldFull: [ 4339 ]
}, {
    code: 7348,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4340,
    foldFull: [ 4340 ]
}, {
    code: 7349,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4341,
    foldFull: [ 4341 ]
}, {
    code: 7350,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4342,
    foldFull: [ 4342 ]
}, {
    code: 7351,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4343,
    foldFull: [ 4343 ]
}, {
    code: 7352,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4344,
    foldFull: [ 4344 ]
}, {
    code: 7353,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4345,
    foldFull: [ 4345 ]
}, {
    code: 7354,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4346,
    foldFull: [ 4346 ]
}, {
    code: 7357,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4349,
    foldFull: [ 4349 ]
}, {
    code: 7358,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4350,
    foldFull: [ 4350 ]
}, {
    code: 7359,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 4351,
    foldFull: [ 4351 ]
}, {
    code: 7680,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7681,
    foldFull: [ 7681 ]
}, {
    code: 7682,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7683,
    foldFull: [ 7683 ]
}, {
    code: 7684,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7685,
    foldFull: [ 7685 ]
}, {
    code: 7686,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7687,
    foldFull: [ 7687 ]
}, {
    code: 7688,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7689,
    foldFull: [ 7689 ]
}, {
    code: 7690,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7691,
    foldFull: [ 7691 ]
}, {
    code: 7692,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7693,
    foldFull: [ 7693 ]
}, {
    code: 7694,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7695,
    foldFull: [ 7695 ]
}, {
    code: 7696,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7697,
    foldFull: [ 7697 ]
}, {
    code: 7698,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7699,
    foldFull: [ 7699 ]
}, {
    code: 7700,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7701,
    foldFull: [ 7701 ]
}, {
    code: 7702,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7703,
    foldFull: [ 7703 ]
}, {
    code: 7704,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7705,
    foldFull: [ 7705 ]
}, {
    code: 7706,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7707,
    foldFull: [ 7707 ]
}, {
    code: 7708,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7709,
    foldFull: [ 7709 ]
}, {
    code: 7710,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7711,
    foldFull: [ 7711 ]
}, {
    code: 7712,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7713,
    foldFull: [ 7713 ]
}, {
    code: 7714,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7715,
    foldFull: [ 7715 ]
}, {
    code: 7716,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7717,
    foldFull: [ 7717 ]
}, {
    code: 7718,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7719,
    foldFull: [ 7719 ]
}, {
    code: 7720,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7721,
    foldFull: [ 7721 ]
}, {
    code: 7722,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7723,
    foldFull: [ 7723 ]
}, {
    code: 7724,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7725,
    foldFull: [ 7725 ]
}, {
    code: 7726,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7727,
    foldFull: [ 7727 ]
}, {
    code: 7728,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7729,
    foldFull: [ 7729 ]
}, {
    code: 7730,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7731,
    foldFull: [ 7731 ]
}, {
    code: 7732,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7733,
    foldFull: [ 7733 ]
}, {
    code: 7734,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7735,
    foldFull: [ 7735 ]
}, {
    code: 7736,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7737,
    foldFull: [ 7737 ]
}, {
    code: 7738,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7739,
    foldFull: [ 7739 ]
}, {
    code: 7740,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7741,
    foldFull: [ 7741 ]
}, {
    code: 7742,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7743,
    foldFull: [ 7743 ]
}, {
    code: 7744,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7745,
    foldFull: [ 7745 ]
}, {
    code: 7746,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7747,
    foldFull: [ 7747 ]
}, {
    code: 7748,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7749,
    foldFull: [ 7749 ]
}, {
    code: 7750,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7751,
    foldFull: [ 7751 ]
}, {
    code: 7752,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7753,
    foldFull: [ 7753 ]
}, {
    code: 7754,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7755,
    foldFull: [ 7755 ]
}, {
    code: 7756,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7757,
    foldFull: [ 7757 ]
}, {
    code: 7758,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7759,
    foldFull: [ 7759 ]
}, {
    code: 7760,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7761,
    foldFull: [ 7761 ]
}, {
    code: 7762,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7763,
    foldFull: [ 7763 ]
}, {
    code: 7764,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7765,
    foldFull: [ 7765 ]
}, {
    code: 7766,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7767,
    foldFull: [ 7767 ]
}, {
    code: 7768,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7769,
    foldFull: [ 7769 ]
}, {
    code: 7770,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7771,
    foldFull: [ 7771 ]
}, {
    code: 7772,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7773,
    foldFull: [ 7773 ]
}, {
    code: 7774,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7775,
    foldFull: [ 7775 ]
}, {
    code: 7776,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7777,
    foldFull: [ 7777 ]
}, {
    code: 7778,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7779,
    foldFull: [ 7779 ]
}, {
    code: 7780,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7781,
    foldFull: [ 7781 ]
}, {
    code: 7782,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7783,
    foldFull: [ 7783 ]
}, {
    code: 7784,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7785,
    foldFull: [ 7785 ]
}, {
    code: 7786,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7787,
    foldFull: [ 7787 ]
}, {
    code: 7788,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7789,
    foldFull: [ 7789 ]
}, {
    code: 7790,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7791,
    foldFull: [ 7791 ]
}, {
    code: 7792,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7793,
    foldFull: [ 7793 ]
}, {
    code: 7794,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7795,
    foldFull: [ 7795 ]
}, {
    code: 7796,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7797,
    foldFull: [ 7797 ]
}, {
    code: 7798,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7799,
    foldFull: [ 7799 ]
}, {
    code: 7800,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7801,
    foldFull: [ 7801 ]
}, {
    code: 7802,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7803,
    foldFull: [ 7803 ]
}, {
    code: 7804,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7805,
    foldFull: [ 7805 ]
}, {
    code: 7806,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7807,
    foldFull: [ 7807 ]
}, {
    code: 7808,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7809,
    foldFull: [ 7809 ]
}, {
    code: 7810,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7811,
    foldFull: [ 7811 ]
}, {
    code: 7812,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7813,
    foldFull: [ 7813 ]
}, {
    code: 7814,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7815,
    foldFull: [ 7815 ]
}, {
    code: 7816,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7817,
    foldFull: [ 7817 ]
}, {
    code: 7818,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7819,
    foldFull: [ 7819 ]
}, {
    code: 7820,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7821,
    foldFull: [ 7821 ]
}, {
    code: 7822,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7823,
    foldFull: [ 7823 ]
}, {
    code: 7824,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7825,
    foldFull: [ 7825 ]
}, {
    code: 7826,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7827,
    foldFull: [ 7827 ]
}, {
    code: 7828,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7829,
    foldFull: [ 7829 ]
}, {
    code: 7830,
    lower: [ 7830 ],
    title: [ 72, 817 ],
    upper: [ 72, 817 ],
    fold: 0,
    foldFull: [ 104, 817 ]
}, {
    code: 7830,
    lower: [ 7830 ],
    title: [ 72, 817 ],
    upper: [ 72, 817 ],
    fold: 0,
    foldFull: [ 104, 817 ]
}, {
    code: 7831,
    lower: [ 7831 ],
    title: [ 84, 776 ],
    upper: [ 84, 776 ],
    fold: 0,
    foldFull: [ 116, 776 ]
}, {
    code: 7831,
    lower: [ 7831 ],
    title: [ 84, 776 ],
    upper: [ 84, 776 ],
    fold: 0,
    foldFull: [ 116, 776 ]
}, {
    code: 7832,
    lower: [ 7832 ],
    title: [ 87, 778 ],
    upper: [ 87, 778 ],
    fold: 0,
    foldFull: [ 119, 778 ]
}, {
    code: 7832,
    lower: [ 7832 ],
    title: [ 87, 778 ],
    upper: [ 87, 778 ],
    fold: 0,
    foldFull: [ 119, 778 ]
}, {
    code: 7833,
    lower: [ 7833 ],
    title: [ 89, 778 ],
    upper: [ 89, 778 ],
    fold: 0,
    foldFull: [ 121, 778 ]
}, {
    code: 7833,
    lower: [ 7833 ],
    title: [ 89, 778 ],
    upper: [ 89, 778 ],
    fold: 0,
    foldFull: [ 121, 778 ]
}, {
    code: 7834,
    lower: [ 7834 ],
    title: [ 65, 702 ],
    upper: [ 65, 702 ],
    fold: 0,
    foldFull: [ 97, 702 ]
}, {
    code: 7834,
    lower: [ 7834 ],
    title: [ 65, 702 ],
    upper: [ 65, 702 ],
    fold: 0,
    foldFull: [ 97, 702 ]
}, {
    code: 7835,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7777,
    foldFull: [ 7777 ]
}, {
    code: 7838,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 223,
    foldFull: [ 115, 115 ]
}, {
    code: 7840,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7841,
    foldFull: [ 7841 ]
}, {
    code: 7842,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7843,
    foldFull: [ 7843 ]
}, {
    code: 7844,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7845,
    foldFull: [ 7845 ]
}, {
    code: 7846,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7847,
    foldFull: [ 7847 ]
}, {
    code: 7848,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7849,
    foldFull: [ 7849 ]
}, {
    code: 7850,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7851,
    foldFull: [ 7851 ]
}, {
    code: 7852,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7853,
    foldFull: [ 7853 ]
}, {
    code: 7854,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7855,
    foldFull: [ 7855 ]
}, {
    code: 7856,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7857,
    foldFull: [ 7857 ]
}, {
    code: 7858,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7859,
    foldFull: [ 7859 ]
}, {
    code: 7860,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7861,
    foldFull: [ 7861 ]
}, {
    code: 7862,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7863,
    foldFull: [ 7863 ]
}, {
    code: 7864,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7865,
    foldFull: [ 7865 ]
}, {
    code: 7866,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7867,
    foldFull: [ 7867 ]
}, {
    code: 7868,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7869,
    foldFull: [ 7869 ]
}, {
    code: 7870,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7871,
    foldFull: [ 7871 ]
}, {
    code: 7872,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7873,
    foldFull: [ 7873 ]
}, {
    code: 7874,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7875,
    foldFull: [ 7875 ]
}, {
    code: 7876,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7877,
    foldFull: [ 7877 ]
}, {
    code: 7878,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7879,
    foldFull: [ 7879 ]
}, {
    code: 7880,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7881,
    foldFull: [ 7881 ]
}, {
    code: 7882,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7883,
    foldFull: [ 7883 ]
}, {
    code: 7884,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7885,
    foldFull: [ 7885 ]
}, {
    code: 7886,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7887,
    foldFull: [ 7887 ]
}, {
    code: 7888,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7889,
    foldFull: [ 7889 ]
}, {
    code: 7890,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7891,
    foldFull: [ 7891 ]
}, {
    code: 7892,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7893,
    foldFull: [ 7893 ]
}, {
    code: 7894,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7895,
    foldFull: [ 7895 ]
}, {
    code: 7896,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7897,
    foldFull: [ 7897 ]
}, {
    code: 7898,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7899,
    foldFull: [ 7899 ]
}, {
    code: 7900,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7901,
    foldFull: [ 7901 ]
}, {
    code: 7902,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7903,
    foldFull: [ 7903 ]
}, {
    code: 7904,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7905,
    foldFull: [ 7905 ]
}, {
    code: 7906,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7907,
    foldFull: [ 7907 ]
}, {
    code: 7908,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7909,
    foldFull: [ 7909 ]
}, {
    code: 7910,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7911,
    foldFull: [ 7911 ]
}, {
    code: 7912,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7913,
    foldFull: [ 7913 ]
}, {
    code: 7914,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7915,
    foldFull: [ 7915 ]
}, {
    code: 7916,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7917,
    foldFull: [ 7917 ]
}, {
    code: 7918,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7919,
    foldFull: [ 7919 ]
}, {
    code: 7920,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7921,
    foldFull: [ 7921 ]
}, {
    code: 7922,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7923,
    foldFull: [ 7923 ]
}, {
    code: 7924,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7925,
    foldFull: [ 7925 ]
}, {
    code: 7926,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7927,
    foldFull: [ 7927 ]
}, {
    code: 7928,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7929,
    foldFull: [ 7929 ]
}, {
    code: 7930,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7931,
    foldFull: [ 7931 ]
}, {
    code: 7932,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7933,
    foldFull: [ 7933 ]
}, {
    code: 7934,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7935,
    foldFull: [ 7935 ]
}, {
    code: 7944,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7936,
    foldFull: [ 7936 ]
}, {
    code: 7945,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7937,
    foldFull: [ 7937 ]
}, {
    code: 7946,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7938,
    foldFull: [ 7938 ]
}, {
    code: 7947,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7939,
    foldFull: [ 7939 ]
}, {
    code: 7948,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7940,
    foldFull: [ 7940 ]
}, {
    code: 7949,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7941,
    foldFull: [ 7941 ]
}, {
    code: 7950,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7942,
    foldFull: [ 7942 ]
}, {
    code: 7951,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7943,
    foldFull: [ 7943 ]
}, {
    code: 7960,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7952,
    foldFull: [ 7952 ]
}, {
    code: 7961,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7953,
    foldFull: [ 7953 ]
}, {
    code: 7962,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7954,
    foldFull: [ 7954 ]
}, {
    code: 7963,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7955,
    foldFull: [ 7955 ]
}, {
    code: 7964,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7956,
    foldFull: [ 7956 ]
}, {
    code: 7965,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7957,
    foldFull: [ 7957 ]
}, {
    code: 7976,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7968,
    foldFull: [ 7968 ]
}, {
    code: 7977,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7969,
    foldFull: [ 7969 ]
}, {
    code: 7978,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7970,
    foldFull: [ 7970 ]
}, {
    code: 7979,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7971,
    foldFull: [ 7971 ]
}, {
    code: 7980,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7972,
    foldFull: [ 7972 ]
}, {
    code: 7981,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7973,
    foldFull: [ 7973 ]
}, {
    code: 7982,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7974,
    foldFull: [ 7974 ]
}, {
    code: 7983,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7975,
    foldFull: [ 7975 ]
}, {
    code: 7992,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7984,
    foldFull: [ 7984 ]
}, {
    code: 7993,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7985,
    foldFull: [ 7985 ]
}, {
    code: 7994,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7986,
    foldFull: [ 7986 ]
}, {
    code: 7995,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7987,
    foldFull: [ 7987 ]
}, {
    code: 7996,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7988,
    foldFull: [ 7988 ]
}, {
    code: 7997,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7989,
    foldFull: [ 7989 ]
}, {
    code: 7998,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7990,
    foldFull: [ 7990 ]
}, {
    code: 7999,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7991,
    foldFull: [ 7991 ]
}, {
    code: 8008,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8000,
    foldFull: [ 8000 ]
}, {
    code: 8009,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8001,
    foldFull: [ 8001 ]
}, {
    code: 8010,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8002,
    foldFull: [ 8002 ]
}, {
    code: 8011,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8003,
    foldFull: [ 8003 ]
}, {
    code: 8012,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8004,
    foldFull: [ 8004 ]
}, {
    code: 8013,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8005,
    foldFull: [ 8005 ]
}, {
    code: 8016,
    lower: [ 8016 ],
    title: [ 933, 787 ],
    upper: [ 933, 787 ],
    fold: 0,
    foldFull: [ 965, 787 ]
}, {
    code: 8016,
    lower: [ 8016 ],
    title: [ 933, 787 ],
    upper: [ 933, 787 ],
    fold: 0,
    foldFull: [ 965, 787 ]
}, {
    code: 8018,
    lower: [ 8018 ],
    title: [ 933, 787, 768 ],
    upper: [ 933, 787, 768 ],
    fold: 0,
    foldFull: [ 965, 787, 768 ]
}, {
    code: 8018,
    lower: [ 8018 ],
    title: [ 933, 787, 768 ],
    upper: [ 933, 787, 768 ],
    fold: 0,
    foldFull: [ 965, 787, 768 ]
}, {
    code: 8020,
    lower: [ 8020 ],
    title: [ 933, 787, 769 ],
    upper: [ 933, 787, 769 ],
    fold: 0,
    foldFull: [ 965, 787, 769 ]
}, {
    code: 8020,
    lower: [ 8020 ],
    title: [ 933, 787, 769 ],
    upper: [ 933, 787, 769 ],
    fold: 0,
    foldFull: [ 965, 787, 769 ]
}, {
    code: 8022,
    lower: [ 8022 ],
    title: [ 933, 787, 834 ],
    upper: [ 933, 787, 834 ],
    fold: 0,
    foldFull: [ 965, 787, 834 ]
}, {
    code: 8022,
    lower: [ 8022 ],
    title: [ 933, 787, 834 ],
    upper: [ 933, 787, 834 ],
    fold: 0,
    foldFull: [ 965, 787, 834 ]
}, {
    code: 8025,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8017,
    foldFull: [ 8017 ]
}, {
    code: 8027,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8019,
    foldFull: [ 8019 ]
}, {
    code: 8029,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8021,
    foldFull: [ 8021 ]
}, {
    code: 8031,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8023,
    foldFull: [ 8023 ]
}, {
    code: 8040,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8032,
    foldFull: [ 8032 ]
}, {
    code: 8041,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8033,
    foldFull: [ 8033 ]
}, {
    code: 8042,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8034,
    foldFull: [ 8034 ]
}, {
    code: 8043,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8035,
    foldFull: [ 8035 ]
}, {
    code: 8044,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8036,
    foldFull: [ 8036 ]
}, {
    code: 8045,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8037,
    foldFull: [ 8037 ]
}, {
    code: 8046,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8038,
    foldFull: [ 8038 ]
}, {
    code: 8047,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8039,
    foldFull: [ 8039 ]
}, {
    code: 8064,
    lower: [ 8064 ],
    title: [ 8072 ],
    upper: [ 7944, 921 ],
    fold: 0,
    foldFull: [ 7936, 953 ]
}, {
    code: 8064,
    lower: [ 8064 ],
    title: [ 8072 ],
    upper: [ 7944, 921 ],
    fold: 0,
    foldFull: [ 7936, 953 ]
}, {
    code: 8065,
    lower: [ 8065 ],
    title: [ 8073 ],
    upper: [ 7945, 921 ],
    fold: 0,
    foldFull: [ 7937, 953 ]
}, {
    code: 8065,
    lower: [ 8065 ],
    title: [ 8073 ],
    upper: [ 7945, 921 ],
    fold: 0,
    foldFull: [ 7937, 953 ]
}, {
    code: 8066,
    lower: [ 8066 ],
    title: [ 8074 ],
    upper: [ 7946, 921 ],
    fold: 0,
    foldFull: [ 7938, 953 ]
}, {
    code: 8066,
    lower: [ 8066 ],
    title: [ 8074 ],
    upper: [ 7946, 921 ],
    fold: 0,
    foldFull: [ 7938, 953 ]
}, {
    code: 8067,
    lower: [ 8067 ],
    title: [ 8075 ],
    upper: [ 7947, 921 ],
    fold: 0,
    foldFull: [ 7939, 953 ]
}, {
    code: 8067,
    lower: [ 8067 ],
    title: [ 8075 ],
    upper: [ 7947, 921 ],
    fold: 0,
    foldFull: [ 7939, 953 ]
}, {
    code: 8068,
    lower: [ 8068 ],
    title: [ 8076 ],
    upper: [ 7948, 921 ],
    fold: 0,
    foldFull: [ 7940, 953 ]
}, {
    code: 8068,
    lower: [ 8068 ],
    title: [ 8076 ],
    upper: [ 7948, 921 ],
    fold: 0,
    foldFull: [ 7940, 953 ]
}, {
    code: 8069,
    lower: [ 8069 ],
    title: [ 8077 ],
    upper: [ 7949, 921 ],
    fold: 0,
    foldFull: [ 7941, 953 ]
}, {
    code: 8069,
    lower: [ 8069 ],
    title: [ 8077 ],
    upper: [ 7949, 921 ],
    fold: 0,
    foldFull: [ 7941, 953 ]
}, {
    code: 8070,
    lower: [ 8070 ],
    title: [ 8078 ],
    upper: [ 7950, 921 ],
    fold: 0,
    foldFull: [ 7942, 953 ]
}, {
    code: 8070,
    lower: [ 8070 ],
    title: [ 8078 ],
    upper: [ 7950, 921 ],
    fold: 0,
    foldFull: [ 7942, 953 ]
}, {
    code: 8071,
    lower: [ 8071 ],
    title: [ 8079 ],
    upper: [ 7951, 921 ],
    fold: 0,
    foldFull: [ 7943, 953 ]
}, {
    code: 8071,
    lower: [ 8071 ],
    title: [ 8079 ],
    upper: [ 7951, 921 ],
    fold: 0,
    foldFull: [ 7943, 953 ]
}, {
    code: 8072,
    lower: [ 8064 ],
    title: [ 8072 ],
    upper: [ 7944, 921 ],
    fold: 8064,
    foldFull: [ 7936, 953 ]
}, {
    code: 8072,
    lower: [ 8064 ],
    title: [ 8072 ],
    upper: [ 7944, 921 ],
    fold: 8064,
    foldFull: [ 7936, 953 ]
}, {
    code: 8073,
    lower: [ 8065 ],
    title: [ 8073 ],
    upper: [ 7945, 921 ],
    fold: 8065,
    foldFull: [ 7937, 953 ]
}, {
    code: 8073,
    lower: [ 8065 ],
    title: [ 8073 ],
    upper: [ 7945, 921 ],
    fold: 8065,
    foldFull: [ 7937, 953 ]
}, {
    code: 8074,
    lower: [ 8066 ],
    title: [ 8074 ],
    upper: [ 7946, 921 ],
    fold: 8066,
    foldFull: [ 7938, 953 ]
}, {
    code: 8074,
    lower: [ 8066 ],
    title: [ 8074 ],
    upper: [ 7946, 921 ],
    fold: 8066,
    foldFull: [ 7938, 953 ]
}, {
    code: 8075,
    lower: [ 8067 ],
    title: [ 8075 ],
    upper: [ 7947, 921 ],
    fold: 8067,
    foldFull: [ 7939, 953 ]
}, {
    code: 8075,
    lower: [ 8067 ],
    title: [ 8075 ],
    upper: [ 7947, 921 ],
    fold: 8067,
    foldFull: [ 7939, 953 ]
}, {
    code: 8076,
    lower: [ 8068 ],
    title: [ 8076 ],
    upper: [ 7948, 921 ],
    fold: 8068,
    foldFull: [ 7940, 953 ]
}, {
    code: 8076,
    lower: [ 8068 ],
    title: [ 8076 ],
    upper: [ 7948, 921 ],
    fold: 8068,
    foldFull: [ 7940, 953 ]
}, {
    code: 8077,
    lower: [ 8069 ],
    title: [ 8077 ],
    upper: [ 7949, 921 ],
    fold: 8069,
    foldFull: [ 7941, 953 ]
}, {
    code: 8077,
    lower: [ 8069 ],
    title: [ 8077 ],
    upper: [ 7949, 921 ],
    fold: 8069,
    foldFull: [ 7941, 953 ]
}, {
    code: 8078,
    lower: [ 8070 ],
    title: [ 8078 ],
    upper: [ 7950, 921 ],
    fold: 8070,
    foldFull: [ 7942, 953 ]
}, {
    code: 8078,
    lower: [ 8070 ],
    title: [ 8078 ],
    upper: [ 7950, 921 ],
    fold: 8070,
    foldFull: [ 7942, 953 ]
}, {
    code: 8079,
    lower: [ 8071 ],
    title: [ 8079 ],
    upper: [ 7951, 921 ],
    fold: 8071,
    foldFull: [ 7943, 953 ]
}, {
    code: 8079,
    lower: [ 8071 ],
    title: [ 8079 ],
    upper: [ 7951, 921 ],
    fold: 8071,
    foldFull: [ 7943, 953 ]
}, {
    code: 8080,
    lower: [ 8080 ],
    title: [ 8088 ],
    upper: [ 7976, 921 ],
    fold: 0,
    foldFull: [ 7968, 953 ]
}, {
    code: 8080,
    lower: [ 8080 ],
    title: [ 8088 ],
    upper: [ 7976, 921 ],
    fold: 0,
    foldFull: [ 7968, 953 ]
}, {
    code: 8081,
    lower: [ 8081 ],
    title: [ 8089 ],
    upper: [ 7977, 921 ],
    fold: 0,
    foldFull: [ 7969, 953 ]
}, {
    code: 8081,
    lower: [ 8081 ],
    title: [ 8089 ],
    upper: [ 7977, 921 ],
    fold: 0,
    foldFull: [ 7969, 953 ]
}, {
    code: 8082,
    lower: [ 8082 ],
    title: [ 8090 ],
    upper: [ 7978, 921 ],
    fold: 0,
    foldFull: [ 7970, 953 ]
}, {
    code: 8082,
    lower: [ 8082 ],
    title: [ 8090 ],
    upper: [ 7978, 921 ],
    fold: 0,
    foldFull: [ 7970, 953 ]
}, {
    code: 8083,
    lower: [ 8083 ],
    title: [ 8091 ],
    upper: [ 7979, 921 ],
    fold: 0,
    foldFull: [ 7971, 953 ]
}, {
    code: 8083,
    lower: [ 8083 ],
    title: [ 8091 ],
    upper: [ 7979, 921 ],
    fold: 0,
    foldFull: [ 7971, 953 ]
}, {
    code: 8084,
    lower: [ 8084 ],
    title: [ 8092 ],
    upper: [ 7980, 921 ],
    fold: 0,
    foldFull: [ 7972, 953 ]
}, {
    code: 8084,
    lower: [ 8084 ],
    title: [ 8092 ],
    upper: [ 7980, 921 ],
    fold: 0,
    foldFull: [ 7972, 953 ]
}, {
    code: 8085,
    lower: [ 8085 ],
    title: [ 8093 ],
    upper: [ 7981, 921 ],
    fold: 0,
    foldFull: [ 7973, 953 ]
}, {
    code: 8085,
    lower: [ 8085 ],
    title: [ 8093 ],
    upper: [ 7981, 921 ],
    fold: 0,
    foldFull: [ 7973, 953 ]
}, {
    code: 8086,
    lower: [ 8086 ],
    title: [ 8094 ],
    upper: [ 7982, 921 ],
    fold: 0,
    foldFull: [ 7974, 953 ]
}, {
    code: 8086,
    lower: [ 8086 ],
    title: [ 8094 ],
    upper: [ 7982, 921 ],
    fold: 0,
    foldFull: [ 7974, 953 ]
}, {
    code: 8087,
    lower: [ 8087 ],
    title: [ 8095 ],
    upper: [ 7983, 921 ],
    fold: 0,
    foldFull: [ 7975, 953 ]
}, {
    code: 8087,
    lower: [ 8087 ],
    title: [ 8095 ],
    upper: [ 7983, 921 ],
    fold: 0,
    foldFull: [ 7975, 953 ]
}, {
    code: 8088,
    lower: [ 8080 ],
    title: [ 8088 ],
    upper: [ 7976, 921 ],
    fold: 8080,
    foldFull: [ 7968, 953 ]
}, {
    code: 8088,
    lower: [ 8080 ],
    title: [ 8088 ],
    upper: [ 7976, 921 ],
    fold: 8080,
    foldFull: [ 7968, 953 ]
}, {
    code: 8089,
    lower: [ 8081 ],
    title: [ 8089 ],
    upper: [ 7977, 921 ],
    fold: 8081,
    foldFull: [ 7969, 953 ]
}, {
    code: 8089,
    lower: [ 8081 ],
    title: [ 8089 ],
    upper: [ 7977, 921 ],
    fold: 8081,
    foldFull: [ 7969, 953 ]
}, {
    code: 8090,
    lower: [ 8082 ],
    title: [ 8090 ],
    upper: [ 7978, 921 ],
    fold: 8082,
    foldFull: [ 7970, 953 ]
}, {
    code: 8090,
    lower: [ 8082 ],
    title: [ 8090 ],
    upper: [ 7978, 921 ],
    fold: 8082,
    foldFull: [ 7970, 953 ]
}, {
    code: 8091,
    lower: [ 8083 ],
    title: [ 8091 ],
    upper: [ 7979, 921 ],
    fold: 8083,
    foldFull: [ 7971, 953 ]
}, {
    code: 8091,
    lower: [ 8083 ],
    title: [ 8091 ],
    upper: [ 7979, 921 ],
    fold: 8083,
    foldFull: [ 7971, 953 ]
}, {
    code: 8092,
    lower: [ 8084 ],
    title: [ 8092 ],
    upper: [ 7980, 921 ],
    fold: 8084,
    foldFull: [ 7972, 953 ]
}, {
    code: 8092,
    lower: [ 8084 ],
    title: [ 8092 ],
    upper: [ 7980, 921 ],
    fold: 8084,
    foldFull: [ 7972, 953 ]
}, {
    code: 8093,
    lower: [ 8085 ],
    title: [ 8093 ],
    upper: [ 7981, 921 ],
    fold: 8085,
    foldFull: [ 7973, 953 ]
}, {
    code: 8093,
    lower: [ 8085 ],
    title: [ 8093 ],
    upper: [ 7981, 921 ],
    fold: 8085,
    foldFull: [ 7973, 953 ]
}, {
    code: 8094,
    lower: [ 8086 ],
    title: [ 8094 ],
    upper: [ 7982, 921 ],
    fold: 8086,
    foldFull: [ 7974, 953 ]
}, {
    code: 8094,
    lower: [ 8086 ],
    title: [ 8094 ],
    upper: [ 7982, 921 ],
    fold: 8086,
    foldFull: [ 7974, 953 ]
}, {
    code: 8095,
    lower: [ 8087 ],
    title: [ 8095 ],
    upper: [ 7983, 921 ],
    fold: 8087,
    foldFull: [ 7975, 953 ]
}, {
    code: 8095,
    lower: [ 8087 ],
    title: [ 8095 ],
    upper: [ 7983, 921 ],
    fold: 8087,
    foldFull: [ 7975, 953 ]
}, {
    code: 8096,
    lower: [ 8096 ],
    title: [ 8104 ],
    upper: [ 8040, 921 ],
    fold: 0,
    foldFull: [ 8032, 953 ]
}, {
    code: 8096,
    lower: [ 8096 ],
    title: [ 8104 ],
    upper: [ 8040, 921 ],
    fold: 0,
    foldFull: [ 8032, 953 ]
}, {
    code: 8097,
    lower: [ 8097 ],
    title: [ 8105 ],
    upper: [ 8041, 921 ],
    fold: 0,
    foldFull: [ 8033, 953 ]
}, {
    code: 8097,
    lower: [ 8097 ],
    title: [ 8105 ],
    upper: [ 8041, 921 ],
    fold: 0,
    foldFull: [ 8033, 953 ]
}, {
    code: 8098,
    lower: [ 8098 ],
    title: [ 8106 ],
    upper: [ 8042, 921 ],
    fold: 0,
    foldFull: [ 8034, 953 ]
}, {
    code: 8098,
    lower: [ 8098 ],
    title: [ 8106 ],
    upper: [ 8042, 921 ],
    fold: 0,
    foldFull: [ 8034, 953 ]
}, {
    code: 8099,
    lower: [ 8099 ],
    title: [ 8107 ],
    upper: [ 8043, 921 ],
    fold: 0,
    foldFull: [ 8035, 953 ]
}, {
    code: 8099,
    lower: [ 8099 ],
    title: [ 8107 ],
    upper: [ 8043, 921 ],
    fold: 0,
    foldFull: [ 8035, 953 ]
}, {
    code: 8100,
    lower: [ 8100 ],
    title: [ 8108 ],
    upper: [ 8044, 921 ],
    fold: 0,
    foldFull: [ 8036, 953 ]
}, {
    code: 8100,
    lower: [ 8100 ],
    title: [ 8108 ],
    upper: [ 8044, 921 ],
    fold: 0,
    foldFull: [ 8036, 953 ]
}, {
    code: 8101,
    lower: [ 8101 ],
    title: [ 8109 ],
    upper: [ 8045, 921 ],
    fold: 0,
    foldFull: [ 8037, 953 ]
}, {
    code: 8101,
    lower: [ 8101 ],
    title: [ 8109 ],
    upper: [ 8045, 921 ],
    fold: 0,
    foldFull: [ 8037, 953 ]
}, {
    code: 8102,
    lower: [ 8102 ],
    title: [ 8110 ],
    upper: [ 8046, 921 ],
    fold: 0,
    foldFull: [ 8038, 953 ]
}, {
    code: 8102,
    lower: [ 8102 ],
    title: [ 8110 ],
    upper: [ 8046, 921 ],
    fold: 0,
    foldFull: [ 8038, 953 ]
}, {
    code: 8103,
    lower: [ 8103 ],
    title: [ 8111 ],
    upper: [ 8047, 921 ],
    fold: 0,
    foldFull: [ 8039, 953 ]
}, {
    code: 8103,
    lower: [ 8103 ],
    title: [ 8111 ],
    upper: [ 8047, 921 ],
    fold: 0,
    foldFull: [ 8039, 953 ]
}, {
    code: 8104,
    lower: [ 8096 ],
    title: [ 8104 ],
    upper: [ 8040, 921 ],
    fold: 8096,
    foldFull: [ 8032, 953 ]
}, {
    code: 8104,
    lower: [ 8096 ],
    title: [ 8104 ],
    upper: [ 8040, 921 ],
    fold: 8096,
    foldFull: [ 8032, 953 ]
}, {
    code: 8105,
    lower: [ 8097 ],
    title: [ 8105 ],
    upper: [ 8041, 921 ],
    fold: 8097,
    foldFull: [ 8033, 953 ]
}, {
    code: 8105,
    lower: [ 8097 ],
    title: [ 8105 ],
    upper: [ 8041, 921 ],
    fold: 8097,
    foldFull: [ 8033, 953 ]
}, {
    code: 8106,
    lower: [ 8098 ],
    title: [ 8106 ],
    upper: [ 8042, 921 ],
    fold: 8098,
    foldFull: [ 8034, 953 ]
}, {
    code: 8106,
    lower: [ 8098 ],
    title: [ 8106 ],
    upper: [ 8042, 921 ],
    fold: 8098,
    foldFull: [ 8034, 953 ]
}, {
    code: 8107,
    lower: [ 8099 ],
    title: [ 8107 ],
    upper: [ 8043, 921 ],
    fold: 8099,
    foldFull: [ 8035, 953 ]
}, {
    code: 8107,
    lower: [ 8099 ],
    title: [ 8107 ],
    upper: [ 8043, 921 ],
    fold: 8099,
    foldFull: [ 8035, 953 ]
}, {
    code: 8108,
    lower: [ 8100 ],
    title: [ 8108 ],
    upper: [ 8044, 921 ],
    fold: 8100,
    foldFull: [ 8036, 953 ]
}, {
    code: 8108,
    lower: [ 8100 ],
    title: [ 8108 ],
    upper: [ 8044, 921 ],
    fold: 8100,
    foldFull: [ 8036, 953 ]
}, {
    code: 8109,
    lower: [ 8101 ],
    title: [ 8109 ],
    upper: [ 8045, 921 ],
    fold: 8101,
    foldFull: [ 8037, 953 ]
}, {
    code: 8109,
    lower: [ 8101 ],
    title: [ 8109 ],
    upper: [ 8045, 921 ],
    fold: 8101,
    foldFull: [ 8037, 953 ]
}, {
    code: 8110,
    lower: [ 8102 ],
    title: [ 8110 ],
    upper: [ 8046, 921 ],
    fold: 8102,
    foldFull: [ 8038, 953 ]
}, {
    code: 8110,
    lower: [ 8102 ],
    title: [ 8110 ],
    upper: [ 8046, 921 ],
    fold: 8102,
    foldFull: [ 8038, 953 ]
}, {
    code: 8111,
    lower: [ 8103 ],
    title: [ 8111 ],
    upper: [ 8047, 921 ],
    fold: 8103,
    foldFull: [ 8039, 953 ]
}, {
    code: 8111,
    lower: [ 8103 ],
    title: [ 8111 ],
    upper: [ 8047, 921 ],
    fold: 8103,
    foldFull: [ 8039, 953 ]
}, {
    code: 8114,
    lower: [ 8114 ],
    title: [ 8122, 837 ],
    upper: [ 8122, 921 ],
    fold: 0,
    foldFull: [ 8048, 953 ]
}, {
    code: 8114,
    lower: [ 8114 ],
    title: [ 8122, 837 ],
    upper: [ 8122, 921 ],
    fold: 0,
    foldFull: [ 8048, 953 ]
}, {
    code: 8115,
    lower: [ 8115 ],
    title: [ 8124 ],
    upper: [ 913, 921 ],
    fold: 0,
    foldFull: [ 945, 953 ]
}, {
    code: 8115,
    lower: [ 8115 ],
    title: [ 8124 ],
    upper: [ 913, 921 ],
    fold: 0,
    foldFull: [ 945, 953 ]
}, {
    code: 8116,
    lower: [ 8116 ],
    title: [ 902, 837 ],
    upper: [ 902, 921 ],
    fold: 0,
    foldFull: [ 940, 953 ]
}, {
    code: 8116,
    lower: [ 8116 ],
    title: [ 902, 837 ],
    upper: [ 902, 921 ],
    fold: 0,
    foldFull: [ 940, 953 ]
}, {
    code: 8118,
    lower: [ 8118 ],
    title: [ 913, 834 ],
    upper: [ 913, 834 ],
    fold: 0,
    foldFull: [ 945, 834 ]
}, {
    code: 8118,
    lower: [ 8118 ],
    title: [ 913, 834 ],
    upper: [ 913, 834 ],
    fold: 0,
    foldFull: [ 945, 834 ]
}, {
    code: 8119,
    lower: [ 8119 ],
    title: [ 913, 834, 837 ],
    upper: [ 913, 834, 921 ],
    fold: 0,
    foldFull: [ 945, 834, 953 ]
}, {
    code: 8119,
    lower: [ 8119 ],
    title: [ 913, 834, 837 ],
    upper: [ 913, 834, 921 ],
    fold: 0,
    foldFull: [ 945, 834, 953 ]
}, {
    code: 8120,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8112,
    foldFull: [ 8112 ]
}, {
    code: 8121,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8113,
    foldFull: [ 8113 ]
}, {
    code: 8122,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8048,
    foldFull: [ 8048 ]
}, {
    code: 8123,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8049,
    foldFull: [ 8049 ]
}, {
    code: 8124,
    lower: [ 8115 ],
    title: [ 8124 ],
    upper: [ 913, 921 ],
    fold: 8115,
    foldFull: [ 945, 953 ]
}, {
    code: 8124,
    lower: [ 8115 ],
    title: [ 8124 ],
    upper: [ 913, 921 ],
    fold: 8115,
    foldFull: [ 945, 953 ]
}, {
    code: 8126,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 953,
    foldFull: [ 953 ]
}, {
    code: 8130,
    lower: [ 8130 ],
    title: [ 8138, 837 ],
    upper: [ 8138, 921 ],
    fold: 0,
    foldFull: [ 8052, 953 ]
}, {
    code: 8130,
    lower: [ 8130 ],
    title: [ 8138, 837 ],
    upper: [ 8138, 921 ],
    fold: 0,
    foldFull: [ 8052, 953 ]
}, {
    code: 8131,
    lower: [ 8131 ],
    title: [ 8140 ],
    upper: [ 919, 921 ],
    fold: 0,
    foldFull: [ 951, 953 ]
}, {
    code: 8131,
    lower: [ 8131 ],
    title: [ 8140 ],
    upper: [ 919, 921 ],
    fold: 0,
    foldFull: [ 951, 953 ]
}, {
    code: 8132,
    lower: [ 8132 ],
    title: [ 905, 837 ],
    upper: [ 905, 921 ],
    fold: 0,
    foldFull: [ 942, 953 ]
}, {
    code: 8132,
    lower: [ 8132 ],
    title: [ 905, 837 ],
    upper: [ 905, 921 ],
    fold: 0,
    foldFull: [ 942, 953 ]
}, {
    code: 8134,
    lower: [ 8134 ],
    title: [ 919, 834 ],
    upper: [ 919, 834 ],
    fold: 0,
    foldFull: [ 951, 834 ]
}, {
    code: 8134,
    lower: [ 8134 ],
    title: [ 919, 834 ],
    upper: [ 919, 834 ],
    fold: 0,
    foldFull: [ 951, 834 ]
}, {
    code: 8135,
    lower: [ 8135 ],
    title: [ 919, 834, 837 ],
    upper: [ 919, 834, 921 ],
    fold: 0,
    foldFull: [ 951, 834, 953 ]
}, {
    code: 8135,
    lower: [ 8135 ],
    title: [ 919, 834, 837 ],
    upper: [ 919, 834, 921 ],
    fold: 0,
    foldFull: [ 951, 834, 953 ]
}, {
    code: 8136,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8050,
    foldFull: [ 8050 ]
}, {
    code: 8137,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8051,
    foldFull: [ 8051 ]
}, {
    code: 8138,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8052,
    foldFull: [ 8052 ]
}, {
    code: 8139,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8053,
    foldFull: [ 8053 ]
}, {
    code: 8140,
    lower: [ 8131 ],
    title: [ 8140 ],
    upper: [ 919, 921 ],
    fold: 8131,
    foldFull: [ 951, 953 ]
}, {
    code: 8140,
    lower: [ 8131 ],
    title: [ 8140 ],
    upper: [ 919, 921 ],
    fold: 8131,
    foldFull: [ 951, 953 ]
}, {
    code: 8146,
    lower: [ 8146 ],
    title: [ 921, 776, 768 ],
    upper: [ 921, 776, 768 ],
    fold: 0,
    foldFull: [ 953, 776, 768 ]
}, {
    code: 8146,
    lower: [ 8146 ],
    title: [ 921, 776, 768 ],
    upper: [ 921, 776, 768 ],
    fold: 0,
    foldFull: [ 953, 776, 768 ]
}, {
    code: 8147,
    lower: [ 8147 ],
    title: [ 921, 776, 769 ],
    upper: [ 921, 776, 769 ],
    fold: 0,
    foldFull: [ 953, 776, 769 ]
}, {
    code: 8147,
    lower: [ 8147 ],
    title: [ 921, 776, 769 ],
    upper: [ 921, 776, 769 ],
    fold: 0,
    foldFull: [ 953, 776, 769 ]
}, {
    code: 8150,
    lower: [ 8150 ],
    title: [ 921, 834 ],
    upper: [ 921, 834 ],
    fold: 0,
    foldFull: [ 953, 834 ]
}, {
    code: 8150,
    lower: [ 8150 ],
    title: [ 921, 834 ],
    upper: [ 921, 834 ],
    fold: 0,
    foldFull: [ 953, 834 ]
}, {
    code: 8151,
    lower: [ 8151 ],
    title: [ 921, 776, 834 ],
    upper: [ 921, 776, 834 ],
    fold: 0,
    foldFull: [ 953, 776, 834 ]
}, {
    code: 8151,
    lower: [ 8151 ],
    title: [ 921, 776, 834 ],
    upper: [ 921, 776, 834 ],
    fold: 0,
    foldFull: [ 953, 776, 834 ]
}, {
    code: 8152,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8144,
    foldFull: [ 8144 ]
}, {
    code: 8153,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8145,
    foldFull: [ 8145 ]
}, {
    code: 8154,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8054,
    foldFull: [ 8054 ]
}, {
    code: 8155,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8055,
    foldFull: [ 8055 ]
}, {
    code: 8162,
    lower: [ 8162 ],
    title: [ 933, 776, 768 ],
    upper: [ 933, 776, 768 ],
    fold: 0,
    foldFull: [ 965, 776, 768 ]
}, {
    code: 8162,
    lower: [ 8162 ],
    title: [ 933, 776, 768 ],
    upper: [ 933, 776, 768 ],
    fold: 0,
    foldFull: [ 965, 776, 768 ]
}, {
    code: 8163,
    lower: [ 8163 ],
    title: [ 933, 776, 769 ],
    upper: [ 933, 776, 769 ],
    fold: 0,
    foldFull: [ 965, 776, 769 ]
}, {
    code: 8163,
    lower: [ 8163 ],
    title: [ 933, 776, 769 ],
    upper: [ 933, 776, 769 ],
    fold: 0,
    foldFull: [ 965, 776, 769 ]
}, {
    code: 8164,
    lower: [ 8164 ],
    title: [ 929, 787 ],
    upper: [ 929, 787 ],
    fold: 0,
    foldFull: [ 961, 787 ]
}, {
    code: 8164,
    lower: [ 8164 ],
    title: [ 929, 787 ],
    upper: [ 929, 787 ],
    fold: 0,
    foldFull: [ 961, 787 ]
}, {
    code: 8166,
    lower: [ 8166 ],
    title: [ 933, 834 ],
    upper: [ 933, 834 ],
    fold: 0,
    foldFull: [ 965, 834 ]
}, {
    code: 8166,
    lower: [ 8166 ],
    title: [ 933, 834 ],
    upper: [ 933, 834 ],
    fold: 0,
    foldFull: [ 965, 834 ]
}, {
    code: 8167,
    lower: [ 8167 ],
    title: [ 933, 776, 834 ],
    upper: [ 933, 776, 834 ],
    fold: 0,
    foldFull: [ 965, 776, 834 ]
}, {
    code: 8167,
    lower: [ 8167 ],
    title: [ 933, 776, 834 ],
    upper: [ 933, 776, 834 ],
    fold: 0,
    foldFull: [ 965, 776, 834 ]
}, {
    code: 8168,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8160,
    foldFull: [ 8160 ]
}, {
    code: 8169,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8161,
    foldFull: [ 8161 ]
}, {
    code: 8170,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8058,
    foldFull: [ 8058 ]
}, {
    code: 8171,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8059,
    foldFull: [ 8059 ]
}, {
    code: 8172,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8165,
    foldFull: [ 8165 ]
}, {
    code: 8178,
    lower: [ 8178 ],
    title: [ 8186, 837 ],
    upper: [ 8186, 921 ],
    fold: 0,
    foldFull: [ 8060, 953 ]
}, {
    code: 8178,
    lower: [ 8178 ],
    title: [ 8186, 837 ],
    upper: [ 8186, 921 ],
    fold: 0,
    foldFull: [ 8060, 953 ]
}, {
    code: 8179,
    lower: [ 8179 ],
    title: [ 8188 ],
    upper: [ 937, 921 ],
    fold: 0,
    foldFull: [ 969, 953 ]
}, {
    code: 8179,
    lower: [ 8179 ],
    title: [ 8188 ],
    upper: [ 937, 921 ],
    fold: 0,
    foldFull: [ 969, 953 ]
}, {
    code: 8180,
    lower: [ 8180 ],
    title: [ 911, 837 ],
    upper: [ 911, 921 ],
    fold: 0,
    foldFull: [ 974, 953 ]
}, {
    code: 8180,
    lower: [ 8180 ],
    title: [ 911, 837 ],
    upper: [ 911, 921 ],
    fold: 0,
    foldFull: [ 974, 953 ]
}, {
    code: 8182,
    lower: [ 8182 ],
    title: [ 937, 834 ],
    upper: [ 937, 834 ],
    fold: 0,
    foldFull: [ 969, 834 ]
}, {
    code: 8182,
    lower: [ 8182 ],
    title: [ 937, 834 ],
    upper: [ 937, 834 ],
    fold: 0,
    foldFull: [ 969, 834 ]
}, {
    code: 8183,
    lower: [ 8183 ],
    title: [ 937, 834, 837 ],
    upper: [ 937, 834, 921 ],
    fold: 0,
    foldFull: [ 969, 834, 953 ]
}, {
    code: 8183,
    lower: [ 8183 ],
    title: [ 937, 834, 837 ],
    upper: [ 937, 834, 921 ],
    fold: 0,
    foldFull: [ 969, 834, 953 ]
}, {
    code: 8184,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8056,
    foldFull: [ 8056 ]
}, {
    code: 8185,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8057,
    foldFull: [ 8057 ]
}, {
    code: 8186,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8060,
    foldFull: [ 8060 ]
}, {
    code: 8187,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8061,
    foldFull: [ 8061 ]
}, {
    code: 8188,
    lower: [ 8179 ],
    title: [ 8188 ],
    upper: [ 937, 921 ],
    fold: 8179,
    foldFull: [ 969, 953 ]
}, {
    code: 8188,
    lower: [ 8179 ],
    title: [ 8188 ],
    upper: [ 937, 921 ],
    fold: 8179,
    foldFull: [ 969, 953 ]
}, {
    code: 8486,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 969,
    foldFull: [ 969 ]
}, {
    code: 8490,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 107,
    foldFull: [ 107 ]
}, {
    code: 8491,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 229,
    foldFull: [ 229 ]
}, {
    code: 8498,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8526,
    foldFull: [ 8526 ]
}, {
    code: 8544,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8560,
    foldFull: [ 8560 ]
}, {
    code: 8545,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8561,
    foldFull: [ 8561 ]
}, {
    code: 8546,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8562,
    foldFull: [ 8562 ]
}, {
    code: 8547,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8563,
    foldFull: [ 8563 ]
}, {
    code: 8548,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8564,
    foldFull: [ 8564 ]
}, {
    code: 8549,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8565,
    foldFull: [ 8565 ]
}, {
    code: 8550,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8566,
    foldFull: [ 8566 ]
}, {
    code: 8551,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8567,
    foldFull: [ 8567 ]
}, {
    code: 8552,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8568,
    foldFull: [ 8568 ]
}, {
    code: 8553,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8569,
    foldFull: [ 8569 ]
}, {
    code: 8554,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8570,
    foldFull: [ 8570 ]
}, {
    code: 8555,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8571,
    foldFull: [ 8571 ]
}, {
    code: 8556,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8572,
    foldFull: [ 8572 ]
}, {
    code: 8557,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8573,
    foldFull: [ 8573 ]
}, {
    code: 8558,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8574,
    foldFull: [ 8574 ]
}, {
    code: 8559,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8575,
    foldFull: [ 8575 ]
}, {
    code: 8579,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 8580,
    foldFull: [ 8580 ]
}, {
    code: 9398,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9424,
    foldFull: [ 9424 ]
}, {
    code: 9399,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9425,
    foldFull: [ 9425 ]
}, {
    code: 9400,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9426,
    foldFull: [ 9426 ]
}, {
    code: 9401,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9427,
    foldFull: [ 9427 ]
}, {
    code: 9402,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9428,
    foldFull: [ 9428 ]
}, {
    code: 9403,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9429,
    foldFull: [ 9429 ]
}, {
    code: 9404,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9430,
    foldFull: [ 9430 ]
}, {
    code: 9405,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9431,
    foldFull: [ 9431 ]
}, {
    code: 9406,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9432,
    foldFull: [ 9432 ]
}, {
    code: 9407,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9433,
    foldFull: [ 9433 ]
}, {
    code: 9408,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9434,
    foldFull: [ 9434 ]
}, {
    code: 9409,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9435,
    foldFull: [ 9435 ]
}, {
    code: 9410,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9436,
    foldFull: [ 9436 ]
}, {
    code: 9411,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9437,
    foldFull: [ 9437 ]
}, {
    code: 9412,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9438,
    foldFull: [ 9438 ]
}, {
    code: 9413,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9439,
    foldFull: [ 9439 ]
}, {
    code: 9414,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9440,
    foldFull: [ 9440 ]
}, {
    code: 9415,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9441,
    foldFull: [ 9441 ]
}, {
    code: 9416,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9442,
    foldFull: [ 9442 ]
}, {
    code: 9417,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9443,
    foldFull: [ 9443 ]
}, {
    code: 9418,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9444,
    foldFull: [ 9444 ]
}, {
    code: 9419,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9445,
    foldFull: [ 9445 ]
}, {
    code: 9420,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9446,
    foldFull: [ 9446 ]
}, {
    code: 9421,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9447,
    foldFull: [ 9447 ]
}, {
    code: 9422,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9448,
    foldFull: [ 9448 ]
}, {
    code: 9423,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 9449,
    foldFull: [ 9449 ]
}, {
    code: 11264,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11312,
    foldFull: [ 11312 ]
}, {
    code: 11265,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11313,
    foldFull: [ 11313 ]
}, {
    code: 11266,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11314,
    foldFull: [ 11314 ]
}, {
    code: 11267,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11315,
    foldFull: [ 11315 ]
}, {
    code: 11268,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11316,
    foldFull: [ 11316 ]
}, {
    code: 11269,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11317,
    foldFull: [ 11317 ]
}, {
    code: 11270,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11318,
    foldFull: [ 11318 ]
}, {
    code: 11271,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11319,
    foldFull: [ 11319 ]
}, {
    code: 11272,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11320,
    foldFull: [ 11320 ]
}, {
    code: 11273,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11321,
    foldFull: [ 11321 ]
}, {
    code: 11274,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11322,
    foldFull: [ 11322 ]
}, {
    code: 11275,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11323,
    foldFull: [ 11323 ]
}, {
    code: 11276,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11324,
    foldFull: [ 11324 ]
}, {
    code: 11277,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11325,
    foldFull: [ 11325 ]
}, {
    code: 11278,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11326,
    foldFull: [ 11326 ]
}, {
    code: 11279,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11327,
    foldFull: [ 11327 ]
}, {
    code: 11280,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11328,
    foldFull: [ 11328 ]
}, {
    code: 11281,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11329,
    foldFull: [ 11329 ]
}, {
    code: 11282,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11330,
    foldFull: [ 11330 ]
}, {
    code: 11283,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11331,
    foldFull: [ 11331 ]
}, {
    code: 11284,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11332,
    foldFull: [ 11332 ]
}, {
    code: 11285,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11333,
    foldFull: [ 11333 ]
}, {
    code: 11286,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11334,
    foldFull: [ 11334 ]
}, {
    code: 11287,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11335,
    foldFull: [ 11335 ]
}, {
    code: 11288,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11336,
    foldFull: [ 11336 ]
}, {
    code: 11289,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11337,
    foldFull: [ 11337 ]
}, {
    code: 11290,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11338,
    foldFull: [ 11338 ]
}, {
    code: 11291,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11339,
    foldFull: [ 11339 ]
}, {
    code: 11292,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11340,
    foldFull: [ 11340 ]
}, {
    code: 11293,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11341,
    foldFull: [ 11341 ]
}, {
    code: 11294,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11342,
    foldFull: [ 11342 ]
}, {
    code: 11295,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11343,
    foldFull: [ 11343 ]
}, {
    code: 11296,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11344,
    foldFull: [ 11344 ]
}, {
    code: 11297,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11345,
    foldFull: [ 11345 ]
}, {
    code: 11298,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11346,
    foldFull: [ 11346 ]
}, {
    code: 11299,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11347,
    foldFull: [ 11347 ]
}, {
    code: 11300,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11348,
    foldFull: [ 11348 ]
}, {
    code: 11301,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11349,
    foldFull: [ 11349 ]
}, {
    code: 11302,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11350,
    foldFull: [ 11350 ]
}, {
    code: 11303,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11351,
    foldFull: [ 11351 ]
}, {
    code: 11304,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11352,
    foldFull: [ 11352 ]
}, {
    code: 11305,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11353,
    foldFull: [ 11353 ]
}, {
    code: 11306,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11354,
    foldFull: [ 11354 ]
}, {
    code: 11307,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11355,
    foldFull: [ 11355 ]
}, {
    code: 11308,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11356,
    foldFull: [ 11356 ]
}, {
    code: 11309,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11357,
    foldFull: [ 11357 ]
}, {
    code: 11310,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11358,
    foldFull: [ 11358 ]
}, {
    code: 11360,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11361,
    foldFull: [ 11361 ]
}, {
    code: 11362,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 619,
    foldFull: [ 619 ]
}, {
    code: 11363,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7549,
    foldFull: [ 7549 ]
}, {
    code: 11364,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 637,
    foldFull: [ 637 ]
}, {
    code: 11367,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11368,
    foldFull: [ 11368 ]
}, {
    code: 11369,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11370,
    foldFull: [ 11370 ]
}, {
    code: 11371,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11372,
    foldFull: [ 11372 ]
}, {
    code: 11373,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 593,
    foldFull: [ 593 ]
}, {
    code: 11374,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 625,
    foldFull: [ 625 ]
}, {
    code: 11375,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 592,
    foldFull: [ 592 ]
}, {
    code: 11376,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 594,
    foldFull: [ 594 ]
}, {
    code: 11378,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11379,
    foldFull: [ 11379 ]
}, {
    code: 11381,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11382,
    foldFull: [ 11382 ]
}, {
    code: 11390,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 575,
    foldFull: [ 575 ]
}, {
    code: 11391,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 576,
    foldFull: [ 576 ]
}, {
    code: 11392,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11393,
    foldFull: [ 11393 ]
}, {
    code: 11394,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11395,
    foldFull: [ 11395 ]
}, {
    code: 11396,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11397,
    foldFull: [ 11397 ]
}, {
    code: 11398,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11399,
    foldFull: [ 11399 ]
}, {
    code: 11400,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11401,
    foldFull: [ 11401 ]
}, {
    code: 11402,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11403,
    foldFull: [ 11403 ]
}, {
    code: 11404,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11405,
    foldFull: [ 11405 ]
}, {
    code: 11406,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11407,
    foldFull: [ 11407 ]
}, {
    code: 11408,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11409,
    foldFull: [ 11409 ]
}, {
    code: 11410,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11411,
    foldFull: [ 11411 ]
}, {
    code: 11412,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11413,
    foldFull: [ 11413 ]
}, {
    code: 11414,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11415,
    foldFull: [ 11415 ]
}, {
    code: 11416,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11417,
    foldFull: [ 11417 ]
}, {
    code: 11418,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11419,
    foldFull: [ 11419 ]
}, {
    code: 11420,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11421,
    foldFull: [ 11421 ]
}, {
    code: 11422,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11423,
    foldFull: [ 11423 ]
}, {
    code: 11424,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11425,
    foldFull: [ 11425 ]
}, {
    code: 11426,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11427,
    foldFull: [ 11427 ]
}, {
    code: 11428,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11429,
    foldFull: [ 11429 ]
}, {
    code: 11430,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11431,
    foldFull: [ 11431 ]
}, {
    code: 11432,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11433,
    foldFull: [ 11433 ]
}, {
    code: 11434,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11435,
    foldFull: [ 11435 ]
}, {
    code: 11436,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11437,
    foldFull: [ 11437 ]
}, {
    code: 11438,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11439,
    foldFull: [ 11439 ]
}, {
    code: 11440,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11441,
    foldFull: [ 11441 ]
}, {
    code: 11442,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11443,
    foldFull: [ 11443 ]
}, {
    code: 11444,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11445,
    foldFull: [ 11445 ]
}, {
    code: 11446,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11447,
    foldFull: [ 11447 ]
}, {
    code: 11448,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11449,
    foldFull: [ 11449 ]
}, {
    code: 11450,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11451,
    foldFull: [ 11451 ]
}, {
    code: 11452,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11453,
    foldFull: [ 11453 ]
}, {
    code: 11454,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11455,
    foldFull: [ 11455 ]
}, {
    code: 11456,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11457,
    foldFull: [ 11457 ]
}, {
    code: 11458,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11459,
    foldFull: [ 11459 ]
}, {
    code: 11460,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11461,
    foldFull: [ 11461 ]
}, {
    code: 11462,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11463,
    foldFull: [ 11463 ]
}, {
    code: 11464,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11465,
    foldFull: [ 11465 ]
}, {
    code: 11466,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11467,
    foldFull: [ 11467 ]
}, {
    code: 11468,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11469,
    foldFull: [ 11469 ]
}, {
    code: 11470,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11471,
    foldFull: [ 11471 ]
}, {
    code: 11472,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11473,
    foldFull: [ 11473 ]
}, {
    code: 11474,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11475,
    foldFull: [ 11475 ]
}, {
    code: 11476,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11477,
    foldFull: [ 11477 ]
}, {
    code: 11478,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11479,
    foldFull: [ 11479 ]
}, {
    code: 11480,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11481,
    foldFull: [ 11481 ]
}, {
    code: 11482,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11483,
    foldFull: [ 11483 ]
}, {
    code: 11484,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11485,
    foldFull: [ 11485 ]
}, {
    code: 11486,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11487,
    foldFull: [ 11487 ]
}, {
    code: 11488,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11489,
    foldFull: [ 11489 ]
}, {
    code: 11490,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11491,
    foldFull: [ 11491 ]
}, {
    code: 11499,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11500,
    foldFull: [ 11500 ]
}, {
    code: 11501,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11502,
    foldFull: [ 11502 ]
}, {
    code: 11506,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 11507,
    foldFull: [ 11507 ]
}, {
    code: 42560,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42561,
    foldFull: [ 42561 ]
}, {
    code: 42562,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42563,
    foldFull: [ 42563 ]
}, {
    code: 42564,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42565,
    foldFull: [ 42565 ]
}, {
    code: 42566,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42567,
    foldFull: [ 42567 ]
}, {
    code: 42568,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42569,
    foldFull: [ 42569 ]
}, {
    code: 42570,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42571,
    foldFull: [ 42571 ]
}, {
    code: 42572,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42573,
    foldFull: [ 42573 ]
}, {
    code: 42574,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42575,
    foldFull: [ 42575 ]
}, {
    code: 42576,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42577,
    foldFull: [ 42577 ]
}, {
    code: 42578,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42579,
    foldFull: [ 42579 ]
}, {
    code: 42580,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42581,
    foldFull: [ 42581 ]
}, {
    code: 42582,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42583,
    foldFull: [ 42583 ]
}, {
    code: 42584,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42585,
    foldFull: [ 42585 ]
}, {
    code: 42586,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42587,
    foldFull: [ 42587 ]
}, {
    code: 42588,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42589,
    foldFull: [ 42589 ]
}, {
    code: 42590,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42591,
    foldFull: [ 42591 ]
}, {
    code: 42592,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42593,
    foldFull: [ 42593 ]
}, {
    code: 42594,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42595,
    foldFull: [ 42595 ]
}, {
    code: 42596,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42597,
    foldFull: [ 42597 ]
}, {
    code: 42598,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42599,
    foldFull: [ 42599 ]
}, {
    code: 42600,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42601,
    foldFull: [ 42601 ]
}, {
    code: 42602,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42603,
    foldFull: [ 42603 ]
}, {
    code: 42604,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42605,
    foldFull: [ 42605 ]
}, {
    code: 42624,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42625,
    foldFull: [ 42625 ]
}, {
    code: 42626,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42627,
    foldFull: [ 42627 ]
}, {
    code: 42628,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42629,
    foldFull: [ 42629 ]
}, {
    code: 42630,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42631,
    foldFull: [ 42631 ]
}, {
    code: 42632,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42633,
    foldFull: [ 42633 ]
}, {
    code: 42634,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42635,
    foldFull: [ 42635 ]
}, {
    code: 42636,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42637,
    foldFull: [ 42637 ]
}, {
    code: 42638,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42639,
    foldFull: [ 42639 ]
}, {
    code: 42640,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42641,
    foldFull: [ 42641 ]
}, {
    code: 42642,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42643,
    foldFull: [ 42643 ]
}, {
    code: 42644,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42645,
    foldFull: [ 42645 ]
}, {
    code: 42646,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42647,
    foldFull: [ 42647 ]
}, {
    code: 42648,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42649,
    foldFull: [ 42649 ]
}, {
    code: 42650,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42651,
    foldFull: [ 42651 ]
}, {
    code: 42786,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42787,
    foldFull: [ 42787 ]
}, {
    code: 42788,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42789,
    foldFull: [ 42789 ]
}, {
    code: 42790,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42791,
    foldFull: [ 42791 ]
}, {
    code: 42792,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42793,
    foldFull: [ 42793 ]
}, {
    code: 42794,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42795,
    foldFull: [ 42795 ]
}, {
    code: 42796,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42797,
    foldFull: [ 42797 ]
}, {
    code: 42798,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42799,
    foldFull: [ 42799 ]
}, {
    code: 42802,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42803,
    foldFull: [ 42803 ]
}, {
    code: 42804,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42805,
    foldFull: [ 42805 ]
}, {
    code: 42806,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42807,
    foldFull: [ 42807 ]
}, {
    code: 42808,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42809,
    foldFull: [ 42809 ]
}, {
    code: 42810,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42811,
    foldFull: [ 42811 ]
}, {
    code: 42812,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42813,
    foldFull: [ 42813 ]
}, {
    code: 42814,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42815,
    foldFull: [ 42815 ]
}, {
    code: 42816,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42817,
    foldFull: [ 42817 ]
}, {
    code: 42818,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42819,
    foldFull: [ 42819 ]
}, {
    code: 42820,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42821,
    foldFull: [ 42821 ]
}, {
    code: 42822,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42823,
    foldFull: [ 42823 ]
}, {
    code: 42824,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42825,
    foldFull: [ 42825 ]
}, {
    code: 42826,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42827,
    foldFull: [ 42827 ]
}, {
    code: 42828,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42829,
    foldFull: [ 42829 ]
}, {
    code: 42830,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42831,
    foldFull: [ 42831 ]
}, {
    code: 42832,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42833,
    foldFull: [ 42833 ]
}, {
    code: 42834,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42835,
    foldFull: [ 42835 ]
}, {
    code: 42836,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42837,
    foldFull: [ 42837 ]
}, {
    code: 42838,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42839,
    foldFull: [ 42839 ]
}, {
    code: 42840,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42841,
    foldFull: [ 42841 ]
}, {
    code: 42842,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42843,
    foldFull: [ 42843 ]
}, {
    code: 42844,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42845,
    foldFull: [ 42845 ]
}, {
    code: 42846,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42847,
    foldFull: [ 42847 ]
}, {
    code: 42848,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42849,
    foldFull: [ 42849 ]
}, {
    code: 42850,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42851,
    foldFull: [ 42851 ]
}, {
    code: 42852,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42853,
    foldFull: [ 42853 ]
}, {
    code: 42854,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42855,
    foldFull: [ 42855 ]
}, {
    code: 42856,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42857,
    foldFull: [ 42857 ]
}, {
    code: 42858,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42859,
    foldFull: [ 42859 ]
}, {
    code: 42860,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42861,
    foldFull: [ 42861 ]
}, {
    code: 42862,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42863,
    foldFull: [ 42863 ]
}, {
    code: 42873,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42874,
    foldFull: [ 42874 ]
}, {
    code: 42875,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42876,
    foldFull: [ 42876 ]
}, {
    code: 42877,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7545,
    foldFull: [ 7545 ]
}, {
    code: 42878,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42879,
    foldFull: [ 42879 ]
}, {
    code: 42880,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42881,
    foldFull: [ 42881 ]
}, {
    code: 42882,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42883,
    foldFull: [ 42883 ]
}, {
    code: 42884,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42885,
    foldFull: [ 42885 ]
}, {
    code: 42886,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42887,
    foldFull: [ 42887 ]
}, {
    code: 42891,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42892,
    foldFull: [ 42892 ]
}, {
    code: 42893,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 613,
    foldFull: [ 613 ]
}, {
    code: 42896,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42897,
    foldFull: [ 42897 ]
}, {
    code: 42898,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42899,
    foldFull: [ 42899 ]
}, {
    code: 42902,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42903,
    foldFull: [ 42903 ]
}, {
    code: 42904,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42905,
    foldFull: [ 42905 ]
}, {
    code: 42906,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42907,
    foldFull: [ 42907 ]
}, {
    code: 42908,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42909,
    foldFull: [ 42909 ]
}, {
    code: 42910,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42911,
    foldFull: [ 42911 ]
}, {
    code: 42912,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42913,
    foldFull: [ 42913 ]
}, {
    code: 42914,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42915,
    foldFull: [ 42915 ]
}, {
    code: 42916,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42917,
    foldFull: [ 42917 ]
}, {
    code: 42918,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42919,
    foldFull: [ 42919 ]
}, {
    code: 42920,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42921,
    foldFull: [ 42921 ]
}, {
    code: 42922,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 614,
    foldFull: [ 614 ]
}, {
    code: 42923,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 604,
    foldFull: [ 604 ]
}, {
    code: 42924,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 609,
    foldFull: [ 609 ]
}, {
    code: 42925,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 620,
    foldFull: [ 620 ]
}, {
    code: 42926,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 618,
    foldFull: [ 618 ]
}, {
    code: 42928,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 670,
    foldFull: [ 670 ]
}, {
    code: 42929,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 647,
    foldFull: [ 647 ]
}, {
    code: 42930,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 669,
    foldFull: [ 669 ]
}, {
    code: 42931,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 43859,
    foldFull: [ 43859 ]
}, {
    code: 42932,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42933,
    foldFull: [ 42933 ]
}, {
    code: 42934,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42935,
    foldFull: [ 42935 ]
}, {
    code: 42936,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42937,
    foldFull: [ 42937 ]
}, {
    code: 42938,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42939,
    foldFull: [ 42939 ]
}, {
    code: 42940,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42941,
    foldFull: [ 42941 ]
}, {
    code: 42942,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42943,
    foldFull: [ 42943 ]
}, {
    code: 42946,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42947,
    foldFull: [ 42947 ]
}, {
    code: 42948,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42900,
    foldFull: [ 42900 ]
}, {
    code: 42949,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 642,
    foldFull: [ 642 ]
}, {
    code: 42950,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 7566,
    foldFull: [ 7566 ]
}, {
    code: 42951,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42952,
    foldFull: [ 42952 ]
}, {
    code: 42953,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42954,
    foldFull: [ 42954 ]
}, {
    code: 42997,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 42998,
    foldFull: [ 42998 ]
}, {
    code: 43888,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5024,
    foldFull: [ 5024 ]
}, {
    code: 43889,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5025,
    foldFull: [ 5025 ]
}, {
    code: 43890,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5026,
    foldFull: [ 5026 ]
}, {
    code: 43891,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5027,
    foldFull: [ 5027 ]
}, {
    code: 43892,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5028,
    foldFull: [ 5028 ]
}, {
    code: 43893,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5029,
    foldFull: [ 5029 ]
}, {
    code: 43894,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5030,
    foldFull: [ 5030 ]
}, {
    code: 43895,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5031,
    foldFull: [ 5031 ]
}, {
    code: 43896,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5032,
    foldFull: [ 5032 ]
}, {
    code: 43897,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5033,
    foldFull: [ 5033 ]
}, {
    code: 43898,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5034,
    foldFull: [ 5034 ]
}, {
    code: 43899,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5035,
    foldFull: [ 5035 ]
}, {
    code: 43900,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5036,
    foldFull: [ 5036 ]
}, {
    code: 43901,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5037,
    foldFull: [ 5037 ]
}, {
    code: 43902,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5038,
    foldFull: [ 5038 ]
}, {
    code: 43903,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5039,
    foldFull: [ 5039 ]
}, {
    code: 43904,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5040,
    foldFull: [ 5040 ]
}, {
    code: 43905,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5041,
    foldFull: [ 5041 ]
}, {
    code: 43906,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5042,
    foldFull: [ 5042 ]
}, {
    code: 43907,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5043,
    foldFull: [ 5043 ]
}, {
    code: 43908,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5044,
    foldFull: [ 5044 ]
}, {
    code: 43909,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5045,
    foldFull: [ 5045 ]
}, {
    code: 43910,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5046,
    foldFull: [ 5046 ]
}, {
    code: 43911,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5047,
    foldFull: [ 5047 ]
}, {
    code: 43912,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5048,
    foldFull: [ 5048 ]
}, {
    code: 43913,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5049,
    foldFull: [ 5049 ]
}, {
    code: 43914,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5050,
    foldFull: [ 5050 ]
}, {
    code: 43915,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5051,
    foldFull: [ 5051 ]
}, {
    code: 43916,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5052,
    foldFull: [ 5052 ]
}, {
    code: 43917,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5053,
    foldFull: [ 5053 ]
}, {
    code: 43918,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5054,
    foldFull: [ 5054 ]
}, {
    code: 43919,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5055,
    foldFull: [ 5055 ]
}, {
    code: 43920,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5056,
    foldFull: [ 5056 ]
}, {
    code: 43921,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5057,
    foldFull: [ 5057 ]
}, {
    code: 43922,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5058,
    foldFull: [ 5058 ]
}, {
    code: 43923,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5059,
    foldFull: [ 5059 ]
}, {
    code: 43924,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5060,
    foldFull: [ 5060 ]
}, {
    code: 43925,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5061,
    foldFull: [ 5061 ]
}, {
    code: 43926,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5062,
    foldFull: [ 5062 ]
}, {
    code: 43927,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5063,
    foldFull: [ 5063 ]
}, {
    code: 43928,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5064,
    foldFull: [ 5064 ]
}, {
    code: 43929,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5065,
    foldFull: [ 5065 ]
}, {
    code: 43930,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5066,
    foldFull: [ 5066 ]
}, {
    code: 43931,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5067,
    foldFull: [ 5067 ]
}, {
    code: 43932,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5068,
    foldFull: [ 5068 ]
}, {
    code: 43933,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5069,
    foldFull: [ 5069 ]
}, {
    code: 43934,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5070,
    foldFull: [ 5070 ]
}, {
    code: 43935,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5071,
    foldFull: [ 5071 ]
}, {
    code: 43936,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5072,
    foldFull: [ 5072 ]
}, {
    code: 43937,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5073,
    foldFull: [ 5073 ]
}, {
    code: 43938,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5074,
    foldFull: [ 5074 ]
}, {
    code: 43939,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5075,
    foldFull: [ 5075 ]
}, {
    code: 43940,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5076,
    foldFull: [ 5076 ]
}, {
    code: 43941,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5077,
    foldFull: [ 5077 ]
}, {
    code: 43942,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5078,
    foldFull: [ 5078 ]
}, {
    code: 43943,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5079,
    foldFull: [ 5079 ]
}, {
    code: 43944,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5080,
    foldFull: [ 5080 ]
}, {
    code: 43945,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5081,
    foldFull: [ 5081 ]
}, {
    code: 43946,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5082,
    foldFull: [ 5082 ]
}, {
    code: 43947,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5083,
    foldFull: [ 5083 ]
}, {
    code: 43948,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5084,
    foldFull: [ 5084 ]
}, {
    code: 43949,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5085,
    foldFull: [ 5085 ]
}, {
    code: 43950,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5086,
    foldFull: [ 5086 ]
}, {
    code: 43951,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5087,
    foldFull: [ 5087 ]
}, {
    code: 43952,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5088,
    foldFull: [ 5088 ]
}, {
    code: 43953,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5089,
    foldFull: [ 5089 ]
}, {
    code: 43954,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5090,
    foldFull: [ 5090 ]
}, {
    code: 43955,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5091,
    foldFull: [ 5091 ]
}, {
    code: 43956,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5092,
    foldFull: [ 5092 ]
}, {
    code: 43957,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5093,
    foldFull: [ 5093 ]
}, {
    code: 43958,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5094,
    foldFull: [ 5094 ]
}, {
    code: 43959,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5095,
    foldFull: [ 5095 ]
}, {
    code: 43960,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5096,
    foldFull: [ 5096 ]
}, {
    code: 43961,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5097,
    foldFull: [ 5097 ]
}, {
    code: 43962,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5098,
    foldFull: [ 5098 ]
}, {
    code: 43963,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5099,
    foldFull: [ 5099 ]
}, {
    code: 43964,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5100,
    foldFull: [ 5100 ]
}, {
    code: 43965,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5101,
    foldFull: [ 5101 ]
}, {
    code: 43966,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5102,
    foldFull: [ 5102 ]
}, {
    code: 43967,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 5103,
    foldFull: [ 5103 ]
}, {
    code: 64256,
    lower: [ 64256 ],
    title: [ 70, 102 ],
    upper: [ 70, 70 ],
    fold: 0,
    foldFull: [ 102, 102 ]
}, {
    code: 64256,
    lower: [ 64256 ],
    title: [ 70, 102 ],
    upper: [ 70, 70 ],
    fold: 0,
    foldFull: [ 102, 102 ]
}, {
    code: 64257,
    lower: [ 64257 ],
    title: [ 70, 105 ],
    upper: [ 70, 73 ],
    fold: 0,
    foldFull: [ 102, 105 ]
}, {
    code: 64257,
    lower: [ 64257 ],
    title: [ 70, 105 ],
    upper: [ 70, 73 ],
    fold: 0,
    foldFull: [ 102, 105 ]
}, {
    code: 64258,
    lower: [ 64258 ],
    title: [ 70, 108 ],
    upper: [ 70, 76 ],
    fold: 0,
    foldFull: [ 102, 108 ]
}, {
    code: 64258,
    lower: [ 64258 ],
    title: [ 70, 108 ],
    upper: [ 70, 76 ],
    fold: 0,
    foldFull: [ 102, 108 ]
}, {
    code: 64259,
    lower: [ 64259 ],
    title: [ 70, 102, 105 ],
    upper: [ 70, 70, 73 ],
    fold: 0,
    foldFull: [ 102, 102, 105 ]
}, {
    code: 64259,
    lower: [ 64259 ],
    title: [ 70, 102, 105 ],
    upper: [ 70, 70, 73 ],
    fold: 0,
    foldFull: [ 102, 102, 105 ]
}, {
    code: 64260,
    lower: [ 64260 ],
    title: [ 70, 102, 108 ],
    upper: [ 70, 70, 76 ],
    fold: 0,
    foldFull: [ 102, 102, 108 ]
}, {
    code: 64260,
    lower: [ 64260 ],
    title: [ 70, 102, 108 ],
    upper: [ 70, 70, 76 ],
    fold: 0,
    foldFull: [ 102, 102, 108 ]
}, {
    code: 64261,
    lower: [ 64261 ],
    title: [ 83, 116 ],
    upper: [ 83, 84 ],
    fold: 0,
    foldFull: [ 115, 116 ]
}, {
    code: 64261,
    lower: [ 64261 ],
    title: [ 83, 116 ],
    upper: [ 83, 84 ],
    fold: 0,
    foldFull: [ 115, 116 ]
}, {
    code: 64262,
    lower: [ 64262 ],
    title: [ 83, 116 ],
    upper: [ 83, 84 ],
    fold: 0,
    foldFull: [ 115, 116 ]
}, {
    code: 64262,
    lower: [ 64262 ],
    title: [ 83, 116 ],
    upper: [ 83, 84 ],
    fold: 0,
    foldFull: [ 115, 116 ]
}, {
    code: 64275,
    lower: [ 64275 ],
    title: [ 1348, 1398 ],
    upper: [ 1348, 1350 ],
    fold: 0,
    foldFull: [ 1396, 1398 ]
}, {
    code: 64275,
    lower: [ 64275 ],
    title: [ 1348, 1398 ],
    upper: [ 1348, 1350 ],
    fold: 0,
    foldFull: [ 1396, 1398 ]
}, {
    code: 64276,
    lower: [ 64276 ],
    title: [ 1348, 1381 ],
    upper: [ 1348, 1333 ],
    fold: 0,
    foldFull: [ 1396, 1381 ]
}, {
    code: 64276,
    lower: [ 64276 ],
    title: [ 1348, 1381 ],
    upper: [ 1348, 1333 ],
    fold: 0,
    foldFull: [ 1396, 1381 ]
}, {
    code: 64277,
    lower: [ 64277 ],
    title: [ 1348, 1387 ],
    upper: [ 1348, 1339 ],
    fold: 0,
    foldFull: [ 1396, 1387 ]
}, {
    code: 64277,
    lower: [ 64277 ],
    title: [ 1348, 1387 ],
    upper: [ 1348, 1339 ],
    fold: 0,
    foldFull: [ 1396, 1387 ]
}, {
    code: 64278,
    lower: [ 64278 ],
    title: [ 1358, 1398 ],
    upper: [ 1358, 1350 ],
    fold: 0,
    foldFull: [ 1406, 1398 ]
}, {
    code: 64278,
    lower: [ 64278 ],
    title: [ 1358, 1398 ],
    upper: [ 1358, 1350 ],
    fold: 0,
    foldFull: [ 1406, 1398 ]
}, {
    code: 64279,
    lower: [ 64279 ],
    title: [ 1348, 1389 ],
    upper: [ 1348, 1341 ],
    fold: 0,
    foldFull: [ 1396, 1389 ]
}, {
    code: 64279,
    lower: [ 64279 ],
    title: [ 1348, 1389 ],
    upper: [ 1348, 1341 ],
    fold: 0,
    foldFull: [ 1396, 1389 ]
}, {
    code: 65313,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65345,
    foldFull: [ 65345 ]
}, {
    code: 65314,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65346,
    foldFull: [ 65346 ]
}, {
    code: 65315,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65347,
    foldFull: [ 65347 ]
}, {
    code: 65316,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65348,
    foldFull: [ 65348 ]
}, {
    code: 65317,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65349,
    foldFull: [ 65349 ]
}, {
    code: 65318,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65350,
    foldFull: [ 65350 ]
}, {
    code: 65319,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65351,
    foldFull: [ 65351 ]
}, {
    code: 65320,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65352,
    foldFull: [ 65352 ]
}, {
    code: 65321,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65353,
    foldFull: [ 65353 ]
}, {
    code: 65322,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65354,
    foldFull: [ 65354 ]
}, {
    code: 65323,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65355,
    foldFull: [ 65355 ]
}, {
    code: 65324,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65356,
    foldFull: [ 65356 ]
}, {
    code: 65325,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65357,
    foldFull: [ 65357 ]
}, {
    code: 65326,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65358,
    foldFull: [ 65358 ]
}, {
    code: 65327,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65359,
    foldFull: [ 65359 ]
}, {
    code: 65328,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65360,
    foldFull: [ 65360 ]
}, {
    code: 65329,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65361,
    foldFull: [ 65361 ]
}, {
    code: 65330,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65362,
    foldFull: [ 65362 ]
}, {
    code: 65331,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65363,
    foldFull: [ 65363 ]
}, {
    code: 65332,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65364,
    foldFull: [ 65364 ]
}, {
    code: 65333,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65365,
    foldFull: [ 65365 ]
}, {
    code: 65334,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65366,
    foldFull: [ 65366 ]
}, {
    code: 65335,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65367,
    foldFull: [ 65367 ]
}, {
    code: 65336,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65368,
    foldFull: [ 65368 ]
}, {
    code: 65337,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65369,
    foldFull: [ 65369 ]
}, {
    code: 65338,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 65370,
    foldFull: [ 65370 ]
}, {
    code: 66560,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66600,
    foldFull: [ 66600 ]
}, {
    code: 66561,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66601,
    foldFull: [ 66601 ]
}, {
    code: 66562,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66602,
    foldFull: [ 66602 ]
}, {
    code: 66563,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66603,
    foldFull: [ 66603 ]
}, {
    code: 66564,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66604,
    foldFull: [ 66604 ]
}, {
    code: 66565,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66605,
    foldFull: [ 66605 ]
}, {
    code: 66566,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66606,
    foldFull: [ 66606 ]
}, {
    code: 66567,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66607,
    foldFull: [ 66607 ]
}, {
    code: 66568,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66608,
    foldFull: [ 66608 ]
}, {
    code: 66569,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66609,
    foldFull: [ 66609 ]
}, {
    code: 66570,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66610,
    foldFull: [ 66610 ]
}, {
    code: 66571,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66611,
    foldFull: [ 66611 ]
}, {
    code: 66572,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66612,
    foldFull: [ 66612 ]
}, {
    code: 66573,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66613,
    foldFull: [ 66613 ]
}, {
    code: 66574,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66614,
    foldFull: [ 66614 ]
}, {
    code: 66575,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66615,
    foldFull: [ 66615 ]
}, {
    code: 66576,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66616,
    foldFull: [ 66616 ]
}, {
    code: 66577,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66617,
    foldFull: [ 66617 ]
}, {
    code: 66578,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66618,
    foldFull: [ 66618 ]
}, {
    code: 66579,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66619,
    foldFull: [ 66619 ]
}, {
    code: 66580,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66620,
    foldFull: [ 66620 ]
}, {
    code: 66581,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66621,
    foldFull: [ 66621 ]
}, {
    code: 66582,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66622,
    foldFull: [ 66622 ]
}, {
    code: 66583,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66623,
    foldFull: [ 66623 ]
}, {
    code: 66584,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66624,
    foldFull: [ 66624 ]
}, {
    code: 66585,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66625,
    foldFull: [ 66625 ]
}, {
    code: 66586,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66626,
    foldFull: [ 66626 ]
}, {
    code: 66587,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66627,
    foldFull: [ 66627 ]
}, {
    code: 66588,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66628,
    foldFull: [ 66628 ]
}, {
    code: 66589,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66629,
    foldFull: [ 66629 ]
}, {
    code: 66590,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66630,
    foldFull: [ 66630 ]
}, {
    code: 66591,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66631,
    foldFull: [ 66631 ]
}, {
    code: 66592,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66632,
    foldFull: [ 66632 ]
}, {
    code: 66593,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66633,
    foldFull: [ 66633 ]
}, {
    code: 66594,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66634,
    foldFull: [ 66634 ]
}, {
    code: 66595,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66635,
    foldFull: [ 66635 ]
}, {
    code: 66596,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66636,
    foldFull: [ 66636 ]
}, {
    code: 66597,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66637,
    foldFull: [ 66637 ]
}, {
    code: 66598,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66638,
    foldFull: [ 66638 ]
}, {
    code: 66599,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66639,
    foldFull: [ 66639 ]
}, {
    code: 66736,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66776,
    foldFull: [ 66776 ]
}, {
    code: 66737,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66777,
    foldFull: [ 66777 ]
}, {
    code: 66738,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66778,
    foldFull: [ 66778 ]
}, {
    code: 66739,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66779,
    foldFull: [ 66779 ]
}, {
    code: 66740,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66780,
    foldFull: [ 66780 ]
}, {
    code: 66741,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66781,
    foldFull: [ 66781 ]
}, {
    code: 66742,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66782,
    foldFull: [ 66782 ]
}, {
    code: 66743,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66783,
    foldFull: [ 66783 ]
}, {
    code: 66744,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66784,
    foldFull: [ 66784 ]
}, {
    code: 66745,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66785,
    foldFull: [ 66785 ]
}, {
    code: 66746,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66786,
    foldFull: [ 66786 ]
}, {
    code: 66747,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66787,
    foldFull: [ 66787 ]
}, {
    code: 66748,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66788,
    foldFull: [ 66788 ]
}, {
    code: 66749,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66789,
    foldFull: [ 66789 ]
}, {
    code: 66750,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66790,
    foldFull: [ 66790 ]
}, {
    code: 66751,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66791,
    foldFull: [ 66791 ]
}, {
    code: 66752,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66792,
    foldFull: [ 66792 ]
}, {
    code: 66753,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66793,
    foldFull: [ 66793 ]
}, {
    code: 66754,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66794,
    foldFull: [ 66794 ]
}, {
    code: 66755,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66795,
    foldFull: [ 66795 ]
}, {
    code: 66756,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66796,
    foldFull: [ 66796 ]
}, {
    code: 66757,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66797,
    foldFull: [ 66797 ]
}, {
    code: 66758,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66798,
    foldFull: [ 66798 ]
}, {
    code: 66759,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66799,
    foldFull: [ 66799 ]
}, {
    code: 66760,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66800,
    foldFull: [ 66800 ]
}, {
    code: 66761,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66801,
    foldFull: [ 66801 ]
}, {
    code: 66762,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66802,
    foldFull: [ 66802 ]
}, {
    code: 66763,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66803,
    foldFull: [ 66803 ]
}, {
    code: 66764,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66804,
    foldFull: [ 66804 ]
}, {
    code: 66765,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66805,
    foldFull: [ 66805 ]
}, {
    code: 66766,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66806,
    foldFull: [ 66806 ]
}, {
    code: 66767,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66807,
    foldFull: [ 66807 ]
}, {
    code: 66768,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66808,
    foldFull: [ 66808 ]
}, {
    code: 66769,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66809,
    foldFull: [ 66809 ]
}, {
    code: 66770,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66810,
    foldFull: [ 66810 ]
}, {
    code: 66771,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 66811,
    foldFull: [ 66811 ]
}, {
    code: 68736,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68800,
    foldFull: [ 68800 ]
}, {
    code: 68737,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68801,
    foldFull: [ 68801 ]
}, {
    code: 68738,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68802,
    foldFull: [ 68802 ]
}, {
    code: 68739,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68803,
    foldFull: [ 68803 ]
}, {
    code: 68740,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68804,
    foldFull: [ 68804 ]
}, {
    code: 68741,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68805,
    foldFull: [ 68805 ]
}, {
    code: 68742,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68806,
    foldFull: [ 68806 ]
}, {
    code: 68743,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68807,
    foldFull: [ 68807 ]
}, {
    code: 68744,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68808,
    foldFull: [ 68808 ]
}, {
    code: 68745,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68809,
    foldFull: [ 68809 ]
}, {
    code: 68746,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68810,
    foldFull: [ 68810 ]
}, {
    code: 68747,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68811,
    foldFull: [ 68811 ]
}, {
    code: 68748,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68812,
    foldFull: [ 68812 ]
}, {
    code: 68749,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68813,
    foldFull: [ 68813 ]
}, {
    code: 68750,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68814,
    foldFull: [ 68814 ]
}, {
    code: 68751,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68815,
    foldFull: [ 68815 ]
}, {
    code: 68752,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68816,
    foldFull: [ 68816 ]
}, {
    code: 68753,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68817,
    foldFull: [ 68817 ]
}, {
    code: 68754,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68818,
    foldFull: [ 68818 ]
}, {
    code: 68755,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68819,
    foldFull: [ 68819 ]
}, {
    code: 68756,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68820,
    foldFull: [ 68820 ]
}, {
    code: 68757,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68821,
    foldFull: [ 68821 ]
}, {
    code: 68758,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68822,
    foldFull: [ 68822 ]
}, {
    code: 68759,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68823,
    foldFull: [ 68823 ]
}, {
    code: 68760,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68824,
    foldFull: [ 68824 ]
}, {
    code: 68761,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68825,
    foldFull: [ 68825 ]
}, {
    code: 68762,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68826,
    foldFull: [ 68826 ]
}, {
    code: 68763,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68827,
    foldFull: [ 68827 ]
}, {
    code: 68764,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68828,
    foldFull: [ 68828 ]
}, {
    code: 68765,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68829,
    foldFull: [ 68829 ]
}, {
    code: 68766,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68830,
    foldFull: [ 68830 ]
}, {
    code: 68767,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68831,
    foldFull: [ 68831 ]
}, {
    code: 68768,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68832,
    foldFull: [ 68832 ]
}, {
    code: 68769,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68833,
    foldFull: [ 68833 ]
}, {
    code: 68770,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68834,
    foldFull: [ 68834 ]
}, {
    code: 68771,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68835,
    foldFull: [ 68835 ]
}, {
    code: 68772,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68836,
    foldFull: [ 68836 ]
}, {
    code: 68773,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68837,
    foldFull: [ 68837 ]
}, {
    code: 68774,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68838,
    foldFull: [ 68838 ]
}, {
    code: 68775,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68839,
    foldFull: [ 68839 ]
}, {
    code: 68776,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68840,
    foldFull: [ 68840 ]
}, {
    code: 68777,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68841,
    foldFull: [ 68841 ]
}, {
    code: 68778,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68842,
    foldFull: [ 68842 ]
}, {
    code: 68779,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68843,
    foldFull: [ 68843 ]
}, {
    code: 68780,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68844,
    foldFull: [ 68844 ]
}, {
    code: 68781,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68845,
    foldFull: [ 68845 ]
}, {
    code: 68782,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68846,
    foldFull: [ 68846 ]
}, {
    code: 68783,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68847,
    foldFull: [ 68847 ]
}, {
    code: 68784,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68848,
    foldFull: [ 68848 ]
}, {
    code: 68785,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68849,
    foldFull: [ 68849 ]
}, {
    code: 68786,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 68850,
    foldFull: [ 68850 ]
}, {
    code: 71840,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71872,
    foldFull: [ 71872 ]
}, {
    code: 71841,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71873,
    foldFull: [ 71873 ]
}, {
    code: 71842,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71874,
    foldFull: [ 71874 ]
}, {
    code: 71843,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71875,
    foldFull: [ 71875 ]
}, {
    code: 71844,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71876,
    foldFull: [ 71876 ]
}, {
    code: 71845,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71877,
    foldFull: [ 71877 ]
}, {
    code: 71846,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71878,
    foldFull: [ 71878 ]
}, {
    code: 71847,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71879,
    foldFull: [ 71879 ]
}, {
    code: 71848,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71880,
    foldFull: [ 71880 ]
}, {
    code: 71849,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71881,
    foldFull: [ 71881 ]
}, {
    code: 71850,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71882,
    foldFull: [ 71882 ]
}, {
    code: 71851,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71883,
    foldFull: [ 71883 ]
}, {
    code: 71852,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71884,
    foldFull: [ 71884 ]
}, {
    code: 71853,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71885,
    foldFull: [ 71885 ]
}, {
    code: 71854,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71886,
    foldFull: [ 71886 ]
}, {
    code: 71855,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71887,
    foldFull: [ 71887 ]
}, {
    code: 71856,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71888,
    foldFull: [ 71888 ]
}, {
    code: 71857,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71889,
    foldFull: [ 71889 ]
}, {
    code: 71858,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71890,
    foldFull: [ 71890 ]
}, {
    code: 71859,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71891,
    foldFull: [ 71891 ]
}, {
    code: 71860,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71892,
    foldFull: [ 71892 ]
}, {
    code: 71861,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71893,
    foldFull: [ 71893 ]
}, {
    code: 71862,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71894,
    foldFull: [ 71894 ]
}, {
    code: 71863,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71895,
    foldFull: [ 71895 ]
}, {
    code: 71864,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71896,
    foldFull: [ 71896 ]
}, {
    code: 71865,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71897,
    foldFull: [ 71897 ]
}, {
    code: 71866,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71898,
    foldFull: [ 71898 ]
}, {
    code: 71867,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71899,
    foldFull: [ 71899 ]
}, {
    code: 71868,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71900,
    foldFull: [ 71900 ]
}, {
    code: 71869,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71901,
    foldFull: [ 71901 ]
}, {
    code: 71870,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71902,
    foldFull: [ 71902 ]
}, {
    code: 71871,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 71903,
    foldFull: [ 71903 ]
}, {
    code: 93760,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93792,
    foldFull: [ 93792 ]
}, {
    code: 93761,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93793,
    foldFull: [ 93793 ]
}, {
    code: 93762,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93794,
    foldFull: [ 93794 ]
}, {
    code: 93763,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93795,
    foldFull: [ 93795 ]
}, {
    code: 93764,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93796,
    foldFull: [ 93796 ]
}, {
    code: 93765,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93797,
    foldFull: [ 93797 ]
}, {
    code: 93766,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93798,
    foldFull: [ 93798 ]
}, {
    code: 93767,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93799,
    foldFull: [ 93799 ]
}, {
    code: 93768,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93800,
    foldFull: [ 93800 ]
}, {
    code: 93769,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93801,
    foldFull: [ 93801 ]
}, {
    code: 93770,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93802,
    foldFull: [ 93802 ]
}, {
    code: 93771,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93803,
    foldFull: [ 93803 ]
}, {
    code: 93772,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93804,
    foldFull: [ 93804 ]
}, {
    code: 93773,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93805,
    foldFull: [ 93805 ]
}, {
    code: 93774,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93806,
    foldFull: [ 93806 ]
}, {
    code: 93775,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93807,
    foldFull: [ 93807 ]
}, {
    code: 93776,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93808,
    foldFull: [ 93808 ]
}, {
    code: 93777,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93809,
    foldFull: [ 93809 ]
}, {
    code: 93778,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93810,
    foldFull: [ 93810 ]
}, {
    code: 93779,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93811,
    foldFull: [ 93811 ]
}, {
    code: 93780,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93812,
    foldFull: [ 93812 ]
}, {
    code: 93781,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93813,
    foldFull: [ 93813 ]
}, {
    code: 93782,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93814,
    foldFull: [ 93814 ]
}, {
    code: 93783,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93815,
    foldFull: [ 93815 ]
}, {
    code: 93784,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93816,
    foldFull: [ 93816 ]
}, {
    code: 93785,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93817,
    foldFull: [ 93817 ]
}, {
    code: 93786,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93818,
    foldFull: [ 93818 ]
}, {
    code: 93787,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93819,
    foldFull: [ 93819 ]
}, {
    code: 93788,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93820,
    foldFull: [ 93820 ]
}, {
    code: 93789,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93821,
    foldFull: [ 93821 ]
}, {
    code: 93790,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93822,
    foldFull: [ 93822 ]
}, {
    code: 93791,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 93823,
    foldFull: [ 93823 ]
}, {
    code: 125184,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125218,
    foldFull: [ 125218 ]
}, {
    code: 125185,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125219,
    foldFull: [ 125219 ]
}, {
    code: 125186,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125220,
    foldFull: [ 125220 ]
}, {
    code: 125187,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125221,
    foldFull: [ 125221 ]
}, {
    code: 125188,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125222,
    foldFull: [ 125222 ]
}, {
    code: 125189,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125223,
    foldFull: [ 125223 ]
}, {
    code: 125190,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125224,
    foldFull: [ 125224 ]
}, {
    code: 125191,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125225,
    foldFull: [ 125225 ]
}, {
    code: 125192,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125226,
    foldFull: [ 125226 ]
}, {
    code: 125193,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125227,
    foldFull: [ 125227 ]
}, {
    code: 125194,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125228,
    foldFull: [ 125228 ]
}, {
    code: 125195,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125229,
    foldFull: [ 125229 ]
}, {
    code: 125196,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125230,
    foldFull: [ 125230 ]
}, {
    code: 125197,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125231,
    foldFull: [ 125231 ]
}, {
    code: 125198,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125232,
    foldFull: [ 125232 ]
}, {
    code: 125199,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125233,
    foldFull: [ 125233 ]
}, {
    code: 125200,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125234,
    foldFull: [ 125234 ]
}, {
    code: 125201,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125235,
    foldFull: [ 125235 ]
}, {
    code: 125202,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125236,
    foldFull: [ 125236 ]
}, {
    code: 125203,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125237,
    foldFull: [ 125237 ]
}, {
    code: 125204,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125238,
    foldFull: [ 125238 ]
}, {
    code: 125205,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125239,
    foldFull: [ 125239 ]
}, {
    code: 125206,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125240,
    foldFull: [ 125240 ]
}, {
    code: 125207,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125241,
    foldFull: [ 125241 ]
}, {
    code: 125208,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125242,
    foldFull: [ 125242 ]
}, {
    code: 125209,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125243,
    foldFull: [ 125243 ]
}, {
    code: 125210,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125244,
    foldFull: [ 125244 ]
}, {
    code: 125211,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125245,
    foldFull: [ 125245 ]
}, {
    code: 125212,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125246,
    foldFull: [ 125246 ]
}, {
    code: 125213,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125247,
    foldFull: [ 125247 ]
}, {
    code: 125214,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125248,
    foldFull: [ 125248 ]
}, {
    code: 125215,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125249,
    foldFull: [ 125249 ]
}, {
    code: 125216,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125250,
    foldFull: [ 125250 ]
}, {
    code: 125217,
    lower: [  ],
    title: [  ],
    upper: [  ],
    fold: 125251,
    foldFull: [ 125251 ]
} ];
var recCmp = function (v) {
    return function (v1) {
        return compare(v.code)(v1.code);
    };
};
var findRule = function (code) {
    var v = Data_CodePoint_Unicode_Internal.bsearch(zeroRec(code))(rules)(Data_Array.length(rules))(recCmp);
    if (v instanceof Data_Maybe.Nothing) {
        return zeroRec(code);
    };
    if (v instanceof Data_Maybe.Just) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Data.CodePoint.Unicode.Internal.Casing (line 1627, column 17 - line 1629, column 14): " + [ v.constructor.name ]);
};
var fold = function (code) {
    var folded = (findRule(code)).fold;
    var $11 = folded === 0;
    if ($11) {
        return Data_CodePoint_Unicode_Internal.uTowlower(code);
    };
    return folded;
};
var foldFull = function (code) {
    var folded = (findRule(code)).foldFull;
    var $12 = Data_Array["null"](folded);
    if ($12) {
        return [ Data_CodePoint_Unicode_Internal.uTowlower(code) ];
    };
    return folded;
};
var lower = function (code) {
    var lowered = (findRule(code)).lower;
    var $13 = Data_Array["null"](lowered);
    if ($13) {
        return [ Data_CodePoint_Unicode_Internal.uTowlower(code) ];
    };
    return lowered;
};
var title = function (code) {
    var titled = (findRule(code)).title;
    var $14 = Data_Array["null"](titled);
    if ($14) {
        return [ Data_CodePoint_Unicode_Internal.uTowtitle(code) ];
    };
    return titled;
};
var upper = function (code) {
    var uppered = (findRule(code)).upper;
    var $15 = Data_Array["null"](uppered);
    if ($15) {
        return [ Data_CodePoint_Unicode_Internal.uTowupper(code) ];
    };
    return uppered;
};
export {
    rules,
    zeroRec,
    recCmp,
    findRule,
    fold,
    foldFull,
    lower,
    title,
    upper
};
