// Generated by purs version 0.15.4
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var repeat = function (dictMonoid) {
    var append = Data_Semigroup.append(dictMonoid.Semigroup0());
    var repeat$prime = function ($copy_accum) {
        return function ($copy_v) {
            return function ($copy_count) {
                var $tco_var_accum = $copy_accum;
                var $tco_var_v = $copy_v;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(accum, v, count) {
                    if (count < 1) {
                        $tco_done = true;
                        return accum;
                    };
                    $tco_var_accum = append(accum)(v);
                    $tco_var_v = v;
                    $copy_count = count - 1 | 0;
                    return;
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_accum, $tco_var_v, $copy_count);
                };
                return $tco_result;
            };
        };
    };
    return repeat$prime(Data_Monoid.mempty(dictMonoid));
};
var foldDigits = function (dictFoldable) {
    return Data_Foldable.foldl(dictFoldable)(function (acc) {
        return function (d) {
            return (acc * 10 | 0) + d | 0;
        };
    })(0);
};
export {
    foldDigits,
    repeat
};
