// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Formatter_Internal from "../Data.Formatter.Internal/index.js";
import * as Data_Formatter_Parser_Utils from "../Data.Formatter.Parser.Utils/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
import * as Parsing_String from "../Parsing.String/index.js";
import * as Parsing_String_Basic from "../Parsing.String.Basic/index.js";
var oneOfAs = /* #__PURE__ */ Data_Formatter_Parser_Utils.oneOfAs(Data_Functor.functorArray)(Data_Foldable.foldableArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Parsing.functorParserT);
var some = /* #__PURE__ */ Data_Array.some(Parsing.alternativeParserT)(Parsing.lazyParserT);
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Monoid.monoidString);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var foldDigits = /* #__PURE__ */ Data_Formatter_Internal.foldDigits(Data_Foldable.foldableArray);
var apply = /* #__PURE__ */ Control_Apply.apply(Parsing.applyParserT);
var map = /* #__PURE__ */ Data_Functor.map(Parsing.functorParserT);
var add = /* #__PURE__ */ Data_Semiring.add(Data_Semiring.semiringNumber);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Parsing.applyParserT);
var parseDigit = function (dictMonad) {
    return Parsing_Combinators["try"](oneOfAs(dictMonad)(Parsing_String["char"])([ new Data_Tuple.Tuple("0", 0), new Data_Tuple.Tuple("1", 1), new Data_Tuple.Tuple("2", 2), new Data_Tuple.Tuple("3", 3), new Data_Tuple.Tuple("4", 4), new Data_Tuple.Tuple("5", 5), new Data_Tuple.Tuple("6", 6), new Data_Tuple.Tuple("7", 7), new Data_Tuple.Tuple("8", 8), new Data_Tuple.Tuple("9", 9) ]));
};
var parseFractional = function (dictMonad) {
    return bind(mapFlipped(some(parseDigit(dictMonad)))((function () {
        var $32 = foldMap(show);
        return function ($33) {
            return (function (v) {
                return "0." + v;
            })($32($33));
        };
    })()))(function (digitStr) {
        var v = Data_Number.fromString(digitStr);
        if (v instanceof Data_Maybe.Just) {
            return pure(v.value0);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Parsing.fail("Not a number: " + digitStr);
        };
        throw new Error("Failed pattern match at Data.Formatter.Parser.Number (line 32, column 3 - line 34, column 53): " + [ v.constructor.name ]);
    });
};
var parseInteger = function (dictMonad) {
    return mapFlipped(some(parseDigit(dictMonad)))(foldDigits);
};
var parseMaybeInteger = function (dictMonad) {
    return Parsing_Combinators.optionMaybe(parseInteger(dictMonad));
};
var parseNumber = function (dictMonad) {
    return apply(map(add)(mapFlipped(parseInteger(dictMonad))(Data_Int.toNumber)))(Parsing_Combinators.option(0.0)(Parsing_Combinators["try"](applySecond(Parsing_String_Basic.oneOf([ ".", "," ]))(parseFractional(dictMonad)))));
};
export {
    parseInteger,
    parseMaybeInteger,
    parseNumber,
    parseDigit
};
