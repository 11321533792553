// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as TomWellsOrg_Art from "../TomWellsOrg.Art/index.js";
import * as TomWellsOrg_Blog from "../TomWellsOrg.Blog/index.js";
import * as TomWellsOrg_CV from "../TomWellsOrg.CV/index.js";
import * as TomWellsOrg_Dapps_GuestMint_Main from "../TomWellsOrg.Dapps.GuestMint.Main/index.js";
import * as TomWellsOrg_Domain from "../TomWellsOrg.Domain/index.js";
import * as TomWellsOrg_Functions from "../TomWellsOrg.Functions/index.js";
import * as TomWellsOrg_Stream from "../TomWellsOrg.Stream/index.js";
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var p = /* #__PURE__ */ Concur_React_DOM.p(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h1 = /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h3 = /* #__PURE__ */ Concur_React_DOM.h3(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h2 = /* #__PURE__ */ Concur_React_DOM.h2(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var img = /* #__PURE__ */ Concur_React_DOM.img(Concur_Core_LiftWidget.widgetLiftWidget);
var div$prime = /* #__PURE__ */ Concur_React_DOM["div$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Maybe.functorMaybe);
var ul = /* #__PURE__ */ Concur_React_DOM.ul(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var mapFlipped1 = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var li = /* #__PURE__ */ Concur_React_DOM.li(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var iframe = /* #__PURE__ */ Concur_React_DOM.iframe(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var pre = /* #__PURE__ */ Concur_React_DOM.pre(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var code = /* #__PURE__ */ Concur_React_DOM.code(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var withNavbar = function (component) {
    return alt(div([ Concur_React_Props.className("centered") ])([ a([ voidRight(new TomWellsOrg_Domain.GotoPage(new TomWellsOrg_Domain.Stream(TomWellsOrg_Stream.content)))(Concur_React_Props.onClick) ])([ text("Stream") ]), text(" | "), a([ voidRight(new TomWellsOrg_Domain.GotoPage(TomWellsOrg_Domain.Guestbook.value))(Concur_React_Props.onClick) ])([ text("Guestbook") ]), text(" | "), a([ voidRight(new TomWellsOrg_Domain.GotoPage(new TomWellsOrg_Domain.ListOfArticles(TomWellsOrg_Blog.articles)))(Concur_React_Props.onClick) ])([ text("Blog") ]), text(" | "), a([ voidRight(new TomWellsOrg_Domain.GotoPage(TomWellsOrg_Domain.CV.value))(Concur_React_Props.onClick) ])([ text("About me") ]), text(" | "), a([ voidRight(new TomWellsOrg_Domain.GotoPage(TomWellsOrg_Domain.Contact.value))(Concur_React_Props.onClick) ])([ text("Contact") ]) ]))(component);
};
var renderFlowComponent = function (v) {
    if (v instanceof TomWellsOrg_Domain.FlowParagraph) {
        return p([  ])([ text(v.value0) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowMajorHeader) {
        return h1([  ])([ text(v.value0) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowMinorHeader) {
        return h3([  ])([ text(v.value0) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowSection) {
        return h2([  ])([ text(v.value0) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowLink) {
        return a([ Concur_React_Props.href(v.value0.link) ])([ text(v.value0.title) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowQuote) {
        return p([  ])([ text("\"" + (v.value0.quote + ("\" - " + v.value0.author))) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowImage) {
        return alt(img([ Concur_React_Props.src(v.value0.src), Concur_React_Props.alt(v.value0.alt), Concur_React_Props.width("100%") ]))(Data_Maybe.fromMaybe(div$prime([  ]))(mapFlipped(v.value0.caption)(function (caption) {
            return div([ Concur_React_Props.className("caption") ])([ text("(" + (caption + ")")) ]);
        })));
    };
    if (v instanceof TomWellsOrg_Domain.FlowBullets) {
        return alt(p([  ])([ text(v.value0.start) ]))(ul([  ])(mapFlipped1(v.value0.points)(function (point) {
            return li([  ])([ text(point) ]);
        })));
    };
    if (v instanceof TomWellsOrg_Domain.FlowYouTube) {
        return div([ Concur_React_Props.className("iframewrapper") ])([ iframe([ Concur_React_Props.src("https://www.youtube.com/embed/" + v.value0.id), Concur_React_Props.frameBorder("0"), Concur_React_Props.allowFullScreen(true), Concur_React_Props.width("560"), Concur_React_Props.height("349") ])([  ]) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowYouTubeShorts) {
        return div([ Concur_React_Props.className("iframewrapper-shorts") ])([ iframe([ Concur_React_Props.src("https://www.youtube.com/embed/" + v.value0.id), Concur_React_Props.frameBorder("0"), Concur_React_Props.allowFullScreen(true), Concur_React_Props.width("560"), Concur_React_Props.height("1000") ])([  ]) ]);
    };
    if (v instanceof TomWellsOrg_Domain.FlowSourceCode) {
        var langClass = (function () {
            if (v.value0.lang instanceof TomWellsOrg_Domain.Haskell) {
                return "haskell";
            };
            if (v.value0.lang instanceof TomWellsOrg_Domain.PureScript) {
                return "haskell";
            };
            if (v.value0.lang instanceof TomWellsOrg_Domain.JavaScript) {
                return "javascript";
            };
            if (v.value0.lang instanceof TomWellsOrg_Domain.CSharp) {
                return "csharp";
            };
            if (v.value0.lang instanceof TomWellsOrg_Domain.Pseudo) {
                return "plaintext";
            };
            throw new Error("Failed pattern match at TomWellsOrg.Render (line 67, column 13 - line 72, column 37): " + [ v.value0.lang.constructor.name ]);
        })();
        return pre([  ])([ code([ Concur_React_Props.className("language-" + langClass) ])([ text(Data_String_Common.trim(v.value0.body)) ]) ]);
    };
    throw new Error("Failed pattern match at TomWellsOrg.Render (line 27, column 1 - line 27, column 57): " + [ v.constructor.name ]);
};
var renderPage = function (v) {
    if (v instanceof TomWellsOrg_Domain.SingleArticle) {
        return alt(h1([  ])([ text(v.value0.title) ]))(alt(div([  ])(mapFlipped1(v.value0.body)(renderFlowComponent)))(h3([  ])([ text("Published on " + TomWellsOrg_Functions.printDate(v.value0.date)), text(" by " + v.value0.author.name) ])));
    };
    if (v instanceof TomWellsOrg_Domain.Stream) {
        var render$prime = function (v1) {
            return alt(div([  ])(mapFlipped1(v1.entry)(renderFlowComponent)))(div([ Concur_React_Props.className("centered"), Concur_React_Props.className("caption") ])([ text("(uploaded " + (TomWellsOrg_Functions.printDate(v1.date) + ")")) ]));
        };
        var paginate = function (n) {
            return function (as) {
                return bind(div([  ])([ div([  ])(mapFlipped1(Data_Array.take(n)(as))(render$prime)), div([ Concur_React_Props.className("load-more-button") ])([ button([ Concur_React_Props.onClick ])([ text("Load more") ]) ]) ]))(function () {
                    return paginate(n + 3 | 0)(as);
                });
            };
        };
        return alt(div([ Concur_React_Props.className("ascii-art") ])([ TomWellsOrg_Art.rainbow(TomWellsOrg_Art.stream) ]))(paginate(3)(v.value0));
    };
    if (v instanceof TomWellsOrg_Domain.Guestbook) {
        return alt(div([ Concur_React_Props.className("ascii-art") ])([ TomWellsOrg_Art.rainbow(TomWellsOrg_Art.guestbook) ]))(div([  ])([ div([  ])([ text("My guestbook runs on the NEAR protocol blockchain, please try it out!") ]), div([  ])([ text("------------------------") ]), TomWellsOrg_Dapps_GuestMint_Main.root ]));
    };
    if (v instanceof TomWellsOrg_Domain.ListOfArticles) {
        var render$prime = function (v1) {
            return div([  ])([ text("$> "), a([ voidRight(new TomWellsOrg_Domain.GotoPage(new TomWellsOrg_Domain.SingleArticle(v1)))(Concur_React_Props.onClick) ])([ text(v1.title) ]), text(" (" + (TomWellsOrg_Functions.printDate(v1.date) + ")")) ]);
        };
        return alt(div([ Concur_React_Props.className("ascii-art") ])([ TomWellsOrg_Art.rainbow(TomWellsOrg_Art.blog) ]))(div([  ])([ div([  ])([ text("Things i've written down") ]), div([  ])([ text("------------------------") ]), div([  ])(mapFlipped1(TomWellsOrg_Functions.sortedByMostRecent(v.value0))(render$prime)) ]));
    };
    if (v instanceof TomWellsOrg_Domain.CV) {
        return alt(div([ Concur_React_Props.className("ascii-art") ])([ TomWellsOrg_Art.rainbow(TomWellsOrg_Art.cv) ]))(div([  ])([ div([  ])(mapFlipped1(TomWellsOrg_CV.content)(renderFlowComponent)) ]));
    };
    if (v instanceof TomWellsOrg_Domain.Contact) {
        return alt(div([ Concur_React_Props.className("ascii-art") ])([ TomWellsOrg_Art.rainbow(TomWellsOrg_Art.contact) ]))(div([  ])([ renderFlowComponent(new TomWellsOrg_Domain.FlowImage({
            src: "/images/cv/its_me-midjourney.png",
            alt: "Its me!",
            caption: new Data_Maybe.Just("It's me!")
        })), div([  ])([ text("email $> tom(>>=)tomwells.org") ]), div([  ])([ text("linkedin $> "), a([ Concur_React_Props.href("https://www.linkedin.com/in/tomwells80/") ])([ text("https://www.linkedin.com/in/tomwells80/") ]) ]), div([  ])([ text("youtube $> "), a([ Concur_React_Props.href("https://www.youtube.com/c/TomWells") ])([ text("https://www.youtube.com/c/TomWells") ]) ]) ]));
    };
    if (v instanceof TomWellsOrg_Domain.NotFound) {
        return div([ Concur_React_Props.className("ascii-art") ])([ TomWellsOrg_Art.rainbow(TomWellsOrg_Art.notFound) ]);
    };
    throw new Error("Failed pattern match at TomWellsOrg.Render (line 96, column 1 - line 96, column 44): " + [ v.constructor.name ]);
};
export {
    renderFlowComponent,
    withNavbar,
    renderPage
};
