// Generated by purs version 0.15.4
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Bounded from "../Data.Bounded/index.js";
import * as Data_Date from "../Data.Date/index.js";
import * as Data_Date_Component from "../Data.Date.Component/index.js";
import * as Data_DateTime from "../Data.DateTime/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Formatter_DateTime from "../Data.Formatter.DateTime/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Fuzzy from "../Data.Fuzzy/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ratio from "../Data.Ratio/index.js";
import * as Data_Rational from "../Data.Rational/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Time from "../Data.Time/index.js";
import * as Data_Time_Component from "../Data.Time.Component/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var sortWith = /* #__PURE__ */ Data_Array.sortWith(Data_Date.ordDate);
var bottom = /* #__PURE__ */ Data_Bounded.bottom(Data_Time_Component.boundedHour);
var bottom1 = /* #__PURE__ */ Data_Bounded.bottom(Data_Time_Component.boundedMinute);
var bottom2 = /* #__PURE__ */ Data_Bounded.bottom(Data_Time_Component.boundedSecond);
var bottom3 = /* #__PURE__ */ Data_Bounded.bottom(Data_Time_Component.boundedMillisecond);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var sortWith1 = /* #__PURE__ */ Data_Array.sortWith(/* #__PURE__ */ Data_Ratio.ordRatio(Data_Ord.ordInt)(Data_EuclideanRing.euclideanRingInt));
var bottom4 = /* #__PURE__ */ Data_Bounded.bottom(Data_Date_Component.boundedYear);
var toEnum = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumYear);
var bottom5 = /* #__PURE__ */ Data_Bounded.bottom(Data_Date_Component.boundedMonth);
var toEnum1 = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth);
var bottom6 = /* #__PURE__ */ Data_Bounded.bottom(Data_Date_Component.boundedDay);
var toEnum2 = /* #__PURE__ */ Data_Enum.toEnum(Data_Date_Component.boundedEnumDay);
var sortedByMostRecent = function (articles) {
    return Data_Array.reverse(sortWith(function (v) {
        return v.date;
    })(articles));
};
var printDate = function (date) {
    var formatter = new Data_List_Types.Cons(Data_Formatter_DateTime.DayOfMonth.value, new Data_List_Types.Cons(new Data_Formatter_DateTime.Placeholder(" "), new Data_List_Types.Cons(Data_Formatter_DateTime.MonthFull.value, new Data_List_Types.Cons(new Data_Formatter_DateTime.Placeholder(" "), new Data_List_Types.Cons(Data_Formatter_DateTime.YearFull.value, Data_List_Types.Nil.value)))));
    var datetime = new Data_DateTime.DateTime(date, new Data_Time.Time(bottom, bottom1, bottom2, bottom3));
    return Data_Formatter_DateTime.format(formatter)(datetime);
};
var deslugify = function (input) {
    return Data_String_Common.replaceAll("-")(" ")(Data_String_Common.replaceAll("_")(" ")(input));
};
var fuzzyFindArticleBySlug = function (articles) {
    return function (slug) {
        var dists = Data_Array.catMaybes(mapFlipped(articles)(function (v) {
            var fuzzystr = unwrap(Data_Fuzzy.matchStr(true)(deslugify(v.slug))(deslugify(slug)));
            return new Data_Maybe.Just(new Data_Tuple.Tuple(fuzzystr.ratio, v));
        }));
        var sorted = Data_Array.reverse(sortWith1(function (v) {
            return v.value0;
        })(dists));
        return Data_Array.head(mapFlipped(Data_Array.filter(function (v) {
            return Data_Rational.toNumber(v.value0) >= 0.5;
        })(sorted))(function (v) {
            return v.value1;
        }));
    };
};
var constructDate = function (year) {
    return function (month) {
        return function (day) {
            var year$prime = Data_Maybe.fromMaybe(bottom4)(toEnum(year));
            var month$prime = Data_Maybe.fromMaybe(bottom5)(toEnum1(month));
            var day$prime = Data_Maybe.fromMaybe(bottom6)(toEnum2(day));
            return Data_Date.canonicalDate(year$prime)(month$prime)(day$prime);
        };
    };
};
export {
    constructDate,
    printDate,
    deslugify,
    fuzzyFindArticleBySlug,
    sortedByMostRecent
};
