// Generated by purs version 0.15.4
import * as TomWellsOrg_Domain from "../TomWellsOrg.Domain/index.js";
import * as TomWellsOrg_Functions from "../TomWellsOrg.Functions/index.js";
var content = /* #__PURE__ */ (function () {
    return [ {
        date: TomWellsOrg_Functions.constructDate(2023)(8)(4),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("So yeah rust... Why you should consider Rust next!"), new TomWellsOrg_Domain.FlowParagraph("A nice and quick whirl-wind around the Rust programming language, and some of the features that should put it high up on your priority list of languages to learn next!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "LeA10SZPxJg"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(4)(26),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("A vision for building AI-driven applications"), new TomWellsOrg_Domain.FlowParagraph("A fun discussion around the state of building AI-driven applications today (in the AutoGPT / BabyAGI sense) and a vision of what we could expect to see the developer experience looking like in the future."), new TomWellsOrg_Domain.FlowYouTube({
            id: "9_tqXgfgE1o"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(4)(16),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Featured on: AI as part of the developers toolkit"), new TomWellsOrg_Domain.FlowParagraph("Co-Founder of Responsive Digital, Andrew McElroy sits down for a virtual fireside chat with Synthesis Chief Disruption Officer Tom Wells to have a developer centric look at AI as well as programming language Rust."), new TomWellsOrg_Domain.FlowYouTube({
            id: "HWqkfflCZxQ"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(3)(21),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Galactica Demo #shorts #gpt"), new TomWellsOrg_Domain.FlowParagraph("Galactica is a fun developer tool I developed using OpenAI GPT-4 to bring the power closer to your shell"), new TomWellsOrg_Domain.FlowLink({
            title: "More info on using and downloading Galactica here",
            link: "https://galacticai.co"
        }), new TomWellsOrg_Domain.FlowParagraph("Watch a short demo below"), new TomWellsOrg_Domain.FlowYouTubeShorts({
            id: "sy2f-kC9AEI"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(3)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Tom talks Rust - Episode 5"), new TomWellsOrg_Domain.FlowParagraph("Ownership"), new TomWellsOrg_Domain.FlowYouTube({
            id: "xVq5I3Ceyy8"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(3)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Tom talks Rust - Episode 4"), new TomWellsOrg_Domain.FlowParagraph("Syntax and features"), new TomWellsOrg_Domain.FlowYouTube({
            id: "qsauK6j3pIk"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(3)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Tom talks Rust - Episode 3"), new TomWellsOrg_Domain.FlowParagraph("Getting started"), new TomWellsOrg_Domain.FlowYouTube({
            id: "GddOvNtFB1E"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(3)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Tom talks Rust - Episode 2"), new TomWellsOrg_Domain.FlowParagraph("Overview and sales pitch"), new TomWellsOrg_Domain.FlowYouTube({
            id: "ePbp_wco-w4"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2023)(3)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Tom talks Rust - Episode 1"), new TomWellsOrg_Domain.FlowParagraph("Introduction and setting the scene"), new TomWellsOrg_Domain.FlowYouTube({
            id: "0gOJVNH2Lzo"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2022)(6)(15),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Learn Haskell Together! Episode 1 - Lambda Calculus"), new TomWellsOrg_Domain.FlowParagraph("Working through the Haskell Book (haskellbook.com) - starting with Chapter 1!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "LLO9tlNiWfg"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2022)(4)(14),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("The Matt Brown Show Interview"), new TomWellsOrg_Domain.FlowParagraph("Invited to talk on the Matt Brown show around the future of payments, including Halo - our innovative tap-on-phone product"), new TomWellsOrg_Domain.FlowYouTube({
            id: "_qN2nEsu-RY"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2022)(2)(1),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Web3 for developers - Q&A Session"), new TomWellsOrg_Domain.FlowParagraph("An interesting Q&A session following the previous Web3 session."), new TomWellsOrg_Domain.FlowYouTube({
            id: "z0ow5r6mkkM"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2022)(2)(4),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Web3 for developers"), new TomWellsOrg_Domain.FlowParagraph("A deep dive into Web3 from first principles, understanding why it exists, what it is trying to solve, and then ending up with building a very simple Hello World smart-contract and wiring it up to a basic web application to form a complete dApp."), new TomWellsOrg_Domain.FlowYouTube({
            id: "Bh_jFToqvoU"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2022)(1)(11),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("A re-post of my DEVconf May 2019 talk about Streaming Architecture"), new TomWellsOrg_Domain.FlowYouTube({
            id: "yVXpa-KhbaY"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(11)(16),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Using Unity to generate training data for image processing ML"), new TomWellsOrg_Domain.FlowParagraph("Very rough prototype of generating annotated ship gauge images for training a machine learning model"), new TomWellsOrg_Domain.FlowYouTube({
            id: "KlTK8--BxXs"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(10)(14),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Pure FP Sessions - Intro to ELM #5"), new TomWellsOrg_Domain.FlowYouTube({
            id: "01lt9jYqXM8"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(10)(7),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Pure FP Sessions - Intro to ELM #4"), new TomWellsOrg_Domain.FlowYouTube({
            id: "40iYFCf0Jt4"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(9)(16),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Pure FP Sessions - Intro to ELM #3"), new TomWellsOrg_Domain.FlowYouTube({
            id: "so_F4hFSFGY"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(9)(9),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Pure FP Sessions - Intro to ELM #2"), new TomWellsOrg_Domain.FlowYouTube({
            id: "gnjc-dZ6soQ"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(9)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Pure FP Sessions - Intro to ELM #1"), new TomWellsOrg_Domain.FlowYouTube({
            id: "KgKi5hVTvik"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(8)(4),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("A flash-fire interview with Howard and Tom"), new TomWellsOrg_Domain.FlowYouTube({
            id: "M4wVVqdKfxI"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(7)(29),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("A 'fun' tech session I gave at Synthesis stretching JavaScript to use it in a pure functional way - eventually implementing what looks a bunch like the State Monad."), new TomWellsOrg_Domain.FlowYouTube({
            id: "S7_ahn2CGnA"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(7)(22),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("A 15 minute primer on the Synthesis Technical Innovation Strategy (tm, hehe) that has worked for us for the last 22 years!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "RFWeMwEopvE"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(7)(12),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("I got pretty hung-up on a particular topic during an interview around the payments landscape and couldn't stop talking about the 'Distributed Economy'. Was a good opportunity to get my thoughts in order with a willing listener - turned out to be some pearls of wisedom in there (or madness??)!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "nek4EOCOzvE"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(7)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("My Future of Banking Podcast interview was translated into an article."), new TomWellsOrg_Domain.FlowLink({
            title: ">> Brave Banking <<",
            link: "https://www.bbrief.co.za/2021/07/02/brave-banking/"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(6)(11),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("I was invited to talk about the Future of Banking on the Synthesis Podcast with Howard Feldman - check it out below!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "DrcHAMpoZSY"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(3)(23),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #12 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowParagraph("In this one we take a round-trip around Functor, Apply, Applicative and Bind (solid foundational type classes)"), new TomWellsOrg_Domain.FlowYouTube({
            id: "7cmlAuk9JZc"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(3)(16),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #11 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "9opVDV8Fkzo"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(3)(10),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("A glimpse into the future with Synthesis Labs"), new TomWellsOrg_Domain.FlowYouTube({
            id: "eVq11a8rS9Q"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(3)(9),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #10 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "NplZ9s_FX84"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(2)(23),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #9 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "U_0CCmweGvY"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(2)(18),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Synthesis orientation session - talking about culture and some of our rituals"), new TomWellsOrg_Domain.FlowYouTube({
            id: "HXMDwS3W900"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(2)(16),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #8 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "5W1UtykARsI"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2021)(1)(22),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Introduction to functional programming"), new TomWellsOrg_Domain.FlowYouTube({
            id: "hqlR8H7BsP8"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(12)(2),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #7 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "i7Nv_eNLTCA"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(11)(27),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #6 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "tI94CLu4p3k"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(11)(25),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #5 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "RHkS16_9Ha8"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(11)(20),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Exploring event-driven: Hand waving and rabbit holes!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "QxZTTVpJUk0"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(11)(20),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #4 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "zjZjFDe7TSY"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(11)(19),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #3 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "Gs4NlBFDRN4"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(11)(13),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #2 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "qyTcnR7bndo"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(11)(11),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Episode #1 of Learning PureScript together is out!"), new TomWellsOrg_Domain.FlowYouTube({
            id: "bsZvjFAf9ig"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(8)(19),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Mapping my house using photogrammetry"), new TomWellsOrg_Domain.FlowYouTube({
            id: "l1qAHhIXMfs"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(5)(22),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Flink deep dive - conceptual and real examples"), new TomWellsOrg_Domain.FlowYouTube({
            id: "_8fHV5woDtQ"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(4)(9),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("Unity3D hands on introduction"), new TomWellsOrg_Domain.FlowYouTube({
            id: "2LMu2HQl_4c"
        }) ]
    }, {
        date: TomWellsOrg_Functions.constructDate(2020)(4)(8),
        entry: [ new TomWellsOrg_Domain.FlowMinorHeader("WebRTC - Overview and tutorial"), new TomWellsOrg_Domain.FlowYouTube({
            id: "0r3mEBktuxQ"
        }) ]
    } ];
})();
export {
    content
};
