// Generated by purs version 0.15.4
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_RWS_Trans from "../Control.Monad.RWS.Trans/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_State_Trans from "../Control.Monad.State.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT);
var lift1 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT);
var lift2 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT);
var widgetLiftWidget = {
    liftWidget: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var liftWidget = function (dict) {
    return dict.liftWidget;
};
var readerLiftWidget = function (dictMonad) {
    var lift3 = lift(dictMonad);
    return function (dictLiftWidget) {
        return {
            liftWidget: (function () {
                var $32 = liftWidget(dictLiftWidget);
                return function ($33) {
                    return lift3($32($33));
                };
            })()
        };
    };
};
var rwsLiftWidget = function (dictMonoid) {
    var lift3 = Control_Monad_Trans_Class.lift(Control_Monad_RWS_Trans.monadTransRWST(dictMonoid));
    return function (dictMonad) {
        var lift4 = lift3(dictMonad);
        return function (dictLiftWidget) {
            return {
                liftWidget: (function () {
                    var $34 = liftWidget(dictLiftWidget);
                    return function ($35) {
                        return lift4($34($35));
                    };
                })()
            };
        };
    };
};
var stateLiftWidget = function (dictMonad) {
    var lift3 = lift1(dictMonad);
    return function (dictLiftWidget) {
        return {
            liftWidget: (function () {
                var $36 = liftWidget(dictLiftWidget);
                return function ($37) {
                    return lift3($36($37));
                };
            })()
        };
    };
};
var writerLiftWidget = function (dictMonoid) {
    var lift3 = Control_Monad_Trans_Class.lift(Control_Monad_Writer_Trans.monadTransWriterT(dictMonoid));
    return function (dictMonad) {
        var lift4 = lift3(dictMonad);
        return function (dictLiftWidget) {
            return {
                liftWidget: (function () {
                    var $38 = liftWidget(dictLiftWidget);
                    return function ($39) {
                        return lift4($38($39));
                    };
                })()
            };
        };
    };
};
var exceptLiftWidget = function (dictMonad) {
    var lift3 = lift2(dictMonad);
    return function (dictLiftWidget) {
        return {
            liftWidget: (function () {
                var $40 = liftWidget(dictLiftWidget);
                return function ($41) {
                    return lift3($40($41));
                };
            })()
        };
    };
};
export {
    liftWidget,
    widgetLiftWidget,
    exceptLiftWidget,
    rwsLiftWidget,
    readerLiftWidget,
    stateLiftWidget,
    writerLiftWidget
};
