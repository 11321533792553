// Generated by purs version 0.15.4
import * as Control_Alternative from "../Control.Alternative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Routing_Types from "../Routing.Types/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Data_Maybe.bindMaybe);
var guard = /* #__PURE__ */ Control_Alternative.guard(Data_Maybe.alternativeMaybe);
var apply = /* #__PURE__ */ Control_Apply.apply(Data_Maybe.applyMaybe);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var fromFoldable = /* #__PURE__ */ Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe);
var fromFoldable1 = /* #__PURE__ */ Data_List.fromFoldable(Data_Foldable.foldableArray);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_List_Types.semigroupList);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var fromFoldable2 = /* #__PURE__ */ Data_List.fromFoldable(Data_Foldable.foldableMaybe);
var parseQueryPart = function (decoder) {
    var part2tuple = function (input) {
        var keyVal = map(decoder)(Data_String_Common.split("=")(input));
        return discard(guard(Data_Array.length(keyVal) <= 2))(function () {
            return apply(map1(Data_Tuple.Tuple.create)(Data_Array.head(keyVal)))(Data_Array.index(keyVal)(1));
        });
    };
    var $27 = map1(fromFoldable);
    var $28 = traverse(part2tuple);
    var $29 = Data_String_Common.split("&");
    return function ($30) {
        return $27($28($29($30)));
    };
};
var parse = function (decoder) {
    return function (hash) {
        var pathParts = function (str) {
            var parts = fromFoldable1(map(function ($31) {
                return Routing_Types.Path.create(decoder($31));
            })(Data_String_Common.split("/")(str)));
            var v = Data_List.unsnoc(parts);
            if (v instanceof Data_Maybe.Just && (v.value0.last instanceof Routing_Types.Path && v.value0.last.value0 === "")) {
                return v.value0.init;
            };
            return parts;
        };
        var v = map1(Data_Function.flip(Data_String_CodePoints.splitAt)(hash))(Data_String_CodePoints.indexOf("?")(hash));
        if (v instanceof Data_Maybe.Just) {
            return append(pathParts(v.value0.before))(map2(Routing_Types.Query.create)(fromFoldable2(parseQueryPart(decoder)(Data_String_CodePoints.drop(1)(v.value0.after)))));
        };
        if (v instanceof Data_Maybe.Nothing) {
            return pathParts(hash);
        };
        throw new Error("Failed pattern match at Routing.Parser (line 32, column 3 - line 37, column 21): " + [ v.constructor.name ]);
    };
};
export {
    parse
};
