// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Function_Uncurried from "../Data.Function.Uncurried/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
var fromEnum = /* #__PURE__ */ Data_Enum.fromEnum(Data_String_CodePoints.boundedEnumCodePoint);
var mod = /* #__PURE__ */ Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var toEnum = /* #__PURE__ */ Data_Enum.toEnum(Data_Enum.boundedEnumChar);
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Either.functorEither);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var show2 = /* #__PURE__ */ Data_Show.show(Data_Show.showChar);
var alt = /* #__PURE__ */ Control_Alt.alt(Parsing.altParserT);
var tailRecM = /* #__PURE__ */ Control_Monad_Rec_Class.tailRecM(Parsing.monadRecParserT);
var updatePosSingle = function (v) {
    return function (cp) {
        return function (after) {
            var v1 = fromEnum(cp);
            if (v1 === 10) {
                return {
                    index: v.index + 1 | 0,
                    line: v.line + 1 | 0,
                    column: 1
                };
            };
            if (v1 === 13) {
                var v2 = Data_String_CodePoints.codePointAt(0)(after);
                if (v2 instanceof Data_Maybe.Just && fromEnum(v2.value0) === 10) {
                    return {
                        index: v.index + 1 | 0,
                        line: v.line,
                        column: v.column
                    };
                };
                return {
                    index: v.index + 1 | 0,
                    line: v.line + 1 | 0,
                    column: 1
                };
            };
            if (v1 === 9) {
                return {
                    index: v.index + 1 | 0,
                    line: v.line,
                    column: (v.column + 8 | 0) - mod(v.column - 1 | 0)(8) | 0
                };
            };
            return {
                index: v.index + 1 | 0,
                line: v.line,
                column: v.column + 1 | 0
            };
        };
    };
};
var updatePosString = function ($copy_pos) {
    return function ($copy_before) {
        return function ($copy_after) {
            var $tco_var_pos = $copy_pos;
            var $tco_var_before = $copy_before;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(pos, before, after) {
                var v = Data_String_CodePoints.uncons(before);
                if (v instanceof Data_Maybe.Nothing) {
                    $tco_done = true;
                    return pos;
                };
                if (v instanceof Data_Maybe.Just) {
                    var newPos = (function () {
                        if (Data_String_Common["null"](v.value0.tail)) {
                            return updatePosSingle(pos)(v.value0.head)(after);
                        };
                        if (Data_Boolean.otherwise) {
                            return updatePosSingle(pos)(v.value0.head)(v.value0.tail);
                        };
                        throw new Error("Failed pattern match at Parsing.String (line 160, column 7 - line 162, column 52): " + [  ]);
                    })();
                    $tco_var_pos = newPos;
                    $tco_var_before = v.value0.tail;
                    $copy_after = after;
                    return;
                };
                throw new Error("Failed pattern match at Parsing.String (line 156, column 36 - line 163, column 38): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_pos, $tco_var_before, $copy_after);
            };
            return $tco_result;
        };
    };
};
var satisfyCodePoint = function (f) {
    return Data_Function_Uncurried.mkFn5(function (v) {
        return function (v1) {
            return function (v2) {
                return function ($$throw) {
                    return function (done) {
                        var v3 = Data_String_CodePoints.uncons(v.value0);
                        if (v3 instanceof Data_Maybe.Nothing) {
                            return $$throw(v, new Parsing.ParseError("Unexpected EOF", v.value1));
                        };
                        if (v3 instanceof Data_Maybe.Just) {
                            var $66 = f(v3.value0.head);
                            if ($66) {
                                return done(new Parsing.ParseState(v3.value0.tail, updatePosSingle(v.value1)(v3.value0.head)(v3.value0.tail), true), v3.value0.head);
                            };
                            return $$throw(v, new Parsing.ParseError("Predicate unsatisfied", v.value1));
                        };
                        throw new Error("Failed pattern match at Parsing.String (line 131, column 7 - line 138, column 73): " + [ v3.constructor.name ]);
                    };
                };
            };
        };
    });
};
var satisfy = function (f) {
    return Data_Function_Uncurried.mkFn5(function (v) {
        return function (v1) {
            return function (v2) {
                return function ($$throw) {
                    return function (done) {
                        var v3 = Data_String_CodePoints.uncons(v.value0);
                        if (v3 instanceof Data_Maybe.Nothing) {
                            return $$throw(v, new Parsing.ParseError("Unexpected EOF", v.value1));
                        };
                        if (v3 instanceof Data_Maybe.Just) {
                            var cp = fromEnum(v3.value0.head);
                            var $75 = cp < 0 || cp > 65535;
                            if ($75) {
                                return $$throw(v, new Parsing.ParseError("Expected Char", v.value1));
                            };
                            var ch = fromJust(toEnum(cp));
                            var $76 = f(ch);
                            if ($76) {
                                return done(new Parsing.ParseState(v3.value0.tail, updatePosSingle(v.value1)(v3.value0.head)(v3.value0.tail), true), ch);
                            };
                            return $$throw(v, new Parsing.ParseError("Predicate unsatisfied", v.value1));
                        };
                        throw new Error("Failed pattern match at Parsing.String (line 109, column 7 - line 124, column 75): " + [ v3.constructor.name ]);
                    };
                };
            };
        };
    });
};
var match = function (p) {
    return bind(Parsing.getParserT)(function (v) {
        return bind(p)(function (x) {
            return bind(Parsing.getParserT)(function (v1) {
                return pure(new Data_Tuple.Tuple(Data_String_CodeUnits.take(Data_String_CodeUnits.length(v.value0) - Data_String_CodeUnits.length(v1.value0) | 0)(v.value0), x));
            });
        });
    });
};
var eof = /* #__PURE__ */ Data_Function_Uncurried.mkFn5(function (v) {
    return function (v1) {
        return function (v2) {
            return function ($$throw) {
                return function (done) {
                    var $92 = Data_String_Common["null"](v.value0);
                    if ($92) {
                        return done(new Parsing.ParseState(v.value0, v.value1, true), Data_Unit.unit);
                    };
                    return $$throw(v, new Parsing.ParseError("Expected EOF", v.value1));
                };
            };
        };
    };
});
var consumeWith = function (f) {
    return Data_Function_Uncurried.mkFn5(function (v) {
        return function (v1) {
            return function (v2) {
                return function ($$throw) {
                    return function (done) {
                        var v3 = f(v.value0);
                        if (v3 instanceof Data_Either.Left) {
                            return $$throw(v, new Parsing.ParseError(v3.value0, v.value1));
                        };
                        if (v3 instanceof Data_Either.Right) {
                            return done(new Parsing.ParseState(v3.value0.remainder, updatePosString(v.value1)(v3.value0.consumed)(v3.value0.remainder), !Data_String_Common["null"](v3.value0.consumed)), v3.value0.value);
                        };
                        throw new Error("Failed pattern match at Parsing.String (line 297, column 7 - line 301, column 121): " + [ v3.constructor.name ]);
                    };
                };
            };
        };
    });
};
var regex = function (pattern) {
    return function (flags) {
        return mapFlipped(Data_String_Regex.regex("^(" + (pattern + ")"))(flags))(function (regexobj) {
            return consumeWith(function (input) {
                var v = map(Data_Array_NonEmpty.head)(Data_String_Regex.match(regexobj)(input));
                if (v instanceof Data_Maybe.Just && v.value0 instanceof Data_Maybe.Just) {
                    var remainder = Data_String_CodeUnits.drop(Data_String_CodeUnits.length(v.value0.value0))(input);
                    return new Data_Either.Right({
                        value: v.value0.value0,
                        consumed: v.value0.value0,
                        remainder: remainder
                    });
                };
                return new Data_Either.Left("No Regex pattern match");
            });
        });
    };
};
var rest = /* #__PURE__ */ consumeWith(function (consumed) {
    return new Data_Either.Right({
        value: consumed,
        consumed: consumed,
        remainder: ""
    });
});
var string = function (str) {
    return consumeWith(function (input) {
        var v = Data_String_CodeUnits.stripPrefix(str)(input);
        if (v instanceof Data_Maybe.Just) {
            return new Data_Either.Right({
                value: str,
                consumed: str,
                remainder: v.value0
            });
        };
        return new Data_Either.Left("Expected " + show(str));
    });
};
var takeN = function (n) {
    return consumeWith(function (input) {
        var v = Data_String_CodePoints.splitAt(n)(input);
        var $112 = Data_String_CodePoints.length(v.before) === n;
        if ($112) {
            return new Data_Either.Right({
                value: v.before,
                consumed: v.before,
                remainder: v.after
            });
        };
        return new Data_Either.Left("Could not take " + (show1(n) + " characters"));
    });
};
var $$char = function (c) {
    return Parsing_Combinators.withErrorMessage(satisfy(function (v) {
        return v === c;
    }))(show2(c));
};
var anyCodePoint = /* #__PURE__ */ satisfyCodePoint(/* #__PURE__ */ Data_Function["const"](true));
var anyTill = function (dictMonad) {
    return function (p) {
        var go = function (unit) {
            return alt(bind(Parsing.getParserT)(function (v) {
                return bind(Parsing_Combinators["try"](p))(function (t) {
                    return pure(new Control_Monad_Rec_Class.Done(new Data_Tuple.Tuple(v.value0, t)));
                });
            }))(bind(anyCodePoint)(function () {
                return pure(new Control_Monad_Rec_Class.Loop(unit));
            }));
        };
        return bind(Parsing.getParserT)(function (v) {
            return bind(tailRecM(go)(Data_Unit.unit))(function (v1) {
                return pure(new Data_Tuple.Tuple(Data_String_CodeUnits.take(Data_String_CodeUnits.length(v.value0) - Data_String_CodeUnits.length(v1.value0) | 0)(v.value0), v1.value1));
            });
        });
    };
};
var anyChar = /* #__PURE__ */ satisfy(/* #__PURE__ */ Data_Function["const"](true));
export {
    $$char as char,
    string,
    anyChar,
    anyCodePoint,
    satisfy,
    satisfyCodePoint,
    takeN,
    rest,
    eof,
    match,
    regex,
    anyTill,
    consumeWith
};
