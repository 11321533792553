// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Lazy from "../Data.Lazy/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as JSURI from "../JSURI/index.js";
import * as Routing_Duplex_Types from "../Routing.Duplex.Types/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var bimap = /* #__PURE__ */ Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple);
var lookup = /* #__PURE__ */ Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString);
var genericShowArgsArgument = /* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showString);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(genericShowArgsArgument);
var genericShowArgsProduct = /* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "hash";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "params";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "segments";
    }
})(/* #__PURE__ */ Data_Show.showArray(Data_Show.showString)))(/* #__PURE__ */ Data_Show.showArray(/* #__PURE__ */ Data_Tuple.showTuple(Data_Show.showString)(Data_Show.showString))))(Data_Show.showString))));
var SuccessIsSymbol = {
    reflectSymbol: function () {
        return "Success";
    }
};
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(/* #__PURE__ */ Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString)));
var eq2 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(Data_Eq.eqString));
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Array_NonEmpty_Internal.foldableNonEmptyArray);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Array_NonEmpty_Internal.semigroupNonEmptyArray);
var Expected = /* #__PURE__ */ (function () {
    function Expected(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Expected.create = function (value0) {
        return function (value1) {
            return new Expected(value0, value1);
        };
    };
    return Expected;
})();
var ExpectedEndOfPath = /* #__PURE__ */ (function () {
    function ExpectedEndOfPath(value0) {
        this.value0 = value0;
    };
    ExpectedEndOfPath.create = function (value0) {
        return new ExpectedEndOfPath(value0);
    };
    return ExpectedEndOfPath;
})();
var MissingParam = /* #__PURE__ */ (function () {
    function MissingParam(value0) {
        this.value0 = value0;
    };
    MissingParam.create = function (value0) {
        return new MissingParam(value0);
    };
    return MissingParam;
})();
var EndOfPath = /* #__PURE__ */ (function () {
    function EndOfPath() {

    };
    EndOfPath.value = new EndOfPath();
    return EndOfPath;
})();
var Fail = /* #__PURE__ */ (function () {
    function Fail(value0) {
        this.value0 = value0;
    };
    Fail.create = function (value0) {
        return new Fail(value0);
    };
    return Fail;
})();
var Success = /* #__PURE__ */ (function () {
    function Success(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Success.create = function (value0) {
        return function (value1) {
            return new Success(value0, value1);
        };
    };
    return Success;
})();
var Alt = /* #__PURE__ */ (function () {
    function Alt(value0) {
        this.value0 = value0;
    };
    Alt.create = function (value0) {
        return new Alt(value0);
    };
    return Alt;
})();
var Chomp = /* #__PURE__ */ (function () {
    function Chomp(value0) {
        this.value0 = value0;
    };
    Chomp.create = function (value0) {
        return new Chomp(value0);
    };
    return Chomp;
})();
var Prefix = /* #__PURE__ */ (function () {
    function Prefix(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Prefix.create = function (value0) {
        return function (value1) {
            return new Prefix(value0, value1);
        };
    };
    return Prefix;
})();
var take = /* #__PURE__ */ (function () {
    return new Chomp(function (state) {
        var v = Data_Array.uncons(state.segments);
        if (v instanceof Data_Maybe.Just) {
            return new Success({
                segments: v.value0.tail,
                params: state.params,
                hash: state.hash
            }, v.value0.head);
        };
        return new Fail(EndOfPath.value);
    });
})();
var rest = /* #__PURE__ */ (function () {
    return new Chomp(function (state) {
        return new Success({
            segments: [  ],
            params: state.params,
            hash: state.hash
        }, state.segments);
    });
})();
var prefix = /* #__PURE__ */ (function () {
    return Prefix.create;
})();
var parsePath = /* #__PURE__ */ (function () {
    var unsafeDecodeURIComponent = function ($326) {
        return fromJust(JSURI["decodeURIComponent"]($326));
    };
    var toRouteState = function (v) {
        return {
            segments: v.value0.value0,
            params: v.value0.value1,
            hash: v.value1
        };
    };
    var splitNonEmpty = function (v) {
        return function (v1) {
            if (v1 === "") {
                return [  ];
            };
            return Data_String_Common.split(v)(v1);
        };
    };
    var splitSegments = (function () {
        var $327 = splitNonEmpty("/");
        return function ($328) {
            return (function (v) {
                if (v.length === 2 && (v[0] === "" && v[1] === "")) {
                    return [ "" ];
                };
                return map(unsafeDecodeURIComponent)(v);
            })($327($328));
        };
    })();
    var splitAt = function (k) {
        return function (p) {
            return function (str) {
                var v = Data_String_CodeUnits.indexOf(p)(str);
                if (v instanceof Data_Maybe.Just) {
                    return new Data_Tuple.Tuple(Data_String_CodeUnits.take(v.value0)(str), Data_String_CodeUnits.drop(v.value0 + Data_String_CodeUnits.length(p) | 0)(str));
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return k(str);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 185, column 5 - line 187, column 23): " + [ v.constructor.name ]);
            };
        };
    };
    var splitKeyValue = (function () {
        var $329 = bimap(unsafeDecodeURIComponent)(unsafeDecodeURIComponent);
        var $330 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("=");
        return function ($331) {
            return $329($330($331));
        };
    })();
    var splitParams = (function () {
        var $332 = map(splitKeyValue);
        var $333 = splitNonEmpty("&");
        return function ($334) {
            return $332($333($334));
        };
    })();
    var splitPath = (function () {
        var $335 = bimap(splitSegments)(splitParams);
        var $336 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("?");
        return function ($337) {
            return $335($336($337));
        };
    })();
    var $338 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorTuple)(splitPath);
    var $339 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("#");
    return function ($340) {
        return toRouteState($338($339($340)));
    };
})();
var param = function (key) {
    return new Chomp(function (state) {
        var v = lookup(key)(state.params);
        if (v instanceof Data_Maybe.Just) {
            return new Success(state, v.value0);
        };
        return new Fail(new MissingParam(key));
    });
};
var $$int = /* #__PURE__ */ (function () {
    var $341 = Data_Maybe.maybe(new Data_Either.Left("Int"))(Data_Either.Right.create);
    return function ($342) {
        return $341(Data_Int.fromString($342));
    };
})();
var hash = /* #__PURE__ */ (function () {
    return new Chomp(function (state) {
        return new Success(state, state.hash);
    });
})();
var genericRouteResult = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Fail(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new Success(x.value0.value0, x.value0.value1);
        };
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 54, column 1 - line 54, column 64): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Fail) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Success) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Product(x.value0, x.value1));
        };
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 54, column 1 - line 54, column 64): " + [ x.constructor.name ]);
    }
};
var genericShow = /* #__PURE__ */ Data_Show_Generic.genericShow(genericRouteResult);
var genericRouteError = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Expected(x.value0.value0, x.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new ExpectedEndOfPath(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new MissingParam(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return EndOfPath.value;
        };
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 64, column 1 - line 64, column 58): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Expected) {
            return new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1));
        };
        if (x instanceof ExpectedEndOfPath) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof MissingParam) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof EndOfPath) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 64, column 1 - line 64, column 58): " + [ x.constructor.name ]);
    }
};
var showRouteError = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericRouteError)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(genericShowArgsArgument)(genericShowArgsArgument))({
        reflectSymbol: function () {
            return "Expected";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "ExpectedEndOfPath";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "MissingParam";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "EndOfPath";
        }
    })))))
};
var genericShowSum = /* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showRouteError))({
    reflectSymbol: function () {
        return "Fail";
    }
}));
var showRouteResult = function (dictShow) {
    return {
        show: genericShow(genericShowSum(Data_Show_Generic.genericShowConstructor(genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(dictShow)))(SuccessIsSymbol)))
    };
};
var functorRouteResult = {
    map: function (f) {
        return function (m) {
            if (m instanceof Fail) {
                return new Fail(m.value0);
            };
            if (m instanceof Success) {
                return new Success(m.value0, f(m.value1));
            };
            throw new Error("Failed pattern match at Routing.Duplex.Parser (line 0, column 0 - line 0, column 0): " + [ m.constructor.name ]);
        };
    }
};
var map3 = /* #__PURE__ */ Data_Functor.map(functorRouteResult);
var functorRouteParser = {
    map: function (f) {
        return function (m) {
            if (m instanceof Alt) {
                return new Alt(map1(Data_Functor.map(functorRouteParser)(f))(m.value0));
            };
            if (m instanceof Chomp) {
                return new Chomp(map2(map3(f))(m.value0));
            };
            if (m instanceof Prefix) {
                return new Prefix(m.value0, Data_Functor.map(functorRouteParser)(f)(m.value1));
            };
            throw new Error("Failed pattern match at Routing.Duplex.Parser (line 0, column 0 - line 0, column 0): " + [ m.constructor.name ]);
        };
    }
};
var map4 = /* #__PURE__ */ Data_Functor.map(functorRouteParser);
var eqRouteError = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Expected && y instanceof Expected) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            if (x instanceof ExpectedEndOfPath && y instanceof ExpectedEndOfPath) {
                return x.value0 === y.value0;
            };
            if (x instanceof MissingParam && y instanceof MissingParam) {
                return x.value0 === y.value0;
            };
            if (x instanceof EndOfPath && y instanceof EndOfPath) {
                return true;
            };
            return false;
        };
    }
};
var eq3 = /* #__PURE__ */ Data_Eq.eq(eqRouteError);
var eqRouteResult = function (dictEq) {
    var eq4 = Data_Eq.eq(dictEq);
    return {
        eq: function (x) {
            return function (y) {
                if (x instanceof Fail && y instanceof Fail) {
                    return eq3(x.value0)(y.value0);
                };
                if (x instanceof Success && y instanceof Success) {
                    return x.value0.hash === y.value0.hash && eq1(x.value0.params)(y.value0.params) && eq2(x.value0.segments)(y.value0.segments) && eq4(x.value1)(y.value1);
                };
                return false;
            };
        }
    };
};
var end = /* #__PURE__ */ (function () {
    return new Chomp(function (state) {
        var v = Data_Array.head(state.segments);
        if (v instanceof Data_Maybe.Nothing) {
            return new Success(state, Data_Unit.unit);
        };
        if (v instanceof Data_Maybe.Just) {
            return new Fail(new ExpectedEndOfPath(v.value0));
        };
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 256, column 3 - line 258, column 45): " + [ v.constructor.name ]);
    });
})();
var chompPrefix = function (pre) {
    return function (state) {
        var v = Data_Array.head(state.segments);
        if (v instanceof Data_Maybe.Just && pre === v.value0) {
            return new Success({
                segments: Data_Array.drop(1)(state.segments),
                params: state.params,
                hash: state.hash
            }, Data_Unit.unit);
        };
        if (v instanceof Data_Maybe.Just) {
            return new Fail(new Expected(pre, v.value0));
        };
        return new Fail(EndOfPath.value);
    };
};
var $lazy_runRouteParser = /* #__PURE__ */ $runtime_lazy("runRouteParser", "Routing.Duplex.Parser", function () {
    var goAlt = function (v) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Fail) {
                    return $lazy_runRouteParser(153)(v)(v2);
                };
                return v1;
            };
        };
    };
    var go = function ($copy_state) {
        return function ($copy_v) {
            var $tco_var_state = $copy_state;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(state, v) {
                if (v instanceof Alt) {
                    $tco_done = true;
                    return foldl(goAlt(state))(new Fail(EndOfPath.value))(v.value0);
                };
                if (v instanceof Chomp) {
                    $tco_done = true;
                    return v.value0(state);
                };
                if (v instanceof Prefix) {
                    var v1 = chompPrefix(v.value0)(state);
                    if (v1 instanceof Fail) {
                        $tco_done = true;
                        return new Fail(v1.value0);
                    };
                    if (v1 instanceof Success) {
                        $tco_var_state = v1.value0;
                        $copy_v = v.value1;
                        return;
                    };
                    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 149, column 7 - line 151, column 40): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 145, column 14 - line 151, column 40): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_state, $copy_v);
            };
            return $tco_result;
        };
    };
    return go;
});
var runRouteParser = /* #__PURE__ */ $lazy_runRouteParser(142);
var lazyRouteParser = {
    defer: function (k) {
        var parser = Data_Lazy.defer(k);
        return new Chomp(function (state) {
            return runRouteParser(state)(Data_Lazy.force(parser));
        });
    }
};
var many1 = function (dictAlt) {
    var alt1 = Control_Alt.alt(dictAlt);
    return function (dictApplicative) {
        var pure1 = Control_Applicative.pure(dictApplicative);
        return function (p) {
            var go$prime = function ($copy_state) {
                return function ($copy_xs) {
                    var $tco_var_state = $copy_state;
                    var $tco_done = false;
                    var $tco_result;
                    function $tco_loop(state, xs) {
                        var v = runRouteParser(state)(p);
                        if (v instanceof Fail) {
                            $tco_done = true;
                            return new Success(state, xs);
                        };
                        if (v instanceof Success) {
                            $tco_var_state = v.value0;
                            $copy_xs = alt1(xs)(pure1(v.value1));
                            return;
                        };
                        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 223, column 5 - line 225, column 53): " + [ v.constructor.name ]);
                    };
                    while (!$tco_done) {
                        $tco_result = $tco_loop($tco_var_state, $copy_xs);
                    };
                    return $tco_result;
                };
            };
            var go = function (state) {
                var v = runRouteParser(state)(p);
                if (v instanceof Fail) {
                    return new Fail(v.value0);
                };
                if (v instanceof Success) {
                    return go$prime(v.value0)(pure1(v.value1));
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 217, column 5 - line 219, column 46): " + [ v.constructor.name ]);
            };
            return new Chomp(go);
        };
    };
};
var run = function (p) {
    var $343 = Data_Function.flip(runRouteParser)(p);
    return function ($344) {
        return (function (v) {
            if (v instanceof Fail) {
                return new Data_Either.Left(v.value0);
            };
            if (v instanceof Success) {
                return new Data_Either.Right(v.value1);
            };
            throw new Error("Failed pattern match at Routing.Duplex.Parser (line 190, column 49 - line 192, column 29): " + [ v.constructor.name ]);
        })($343(parsePath($344)));
    };
};
var $$boolean = function (v) {
    if (v === "true") {
        return new Data_Either.Right(true);
    };
    if (v === "false") {
        return new Data_Either.Right(false);
    };
    return new Data_Either.Left("Boolean");
};
var as = function (print) {
    return function (decode) {
        return function (p) {
            return new Chomp(function (state) {
                var v = runRouteParser(state)(p);
                if (v instanceof Fail) {
                    return new Fail(v.value0);
                };
                if (v instanceof Success) {
                    var v1 = decode(v.value1);
                    if (v1 instanceof Data_Either.Left) {
                        return new Fail(new Expected(v1.value0, print(v.value1)));
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return new Success(v.value0, v1.value0);
                    };
                    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 244, column 7 - line 246, column 36): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 241, column 3 - line 246, column 36): " + [ v.constructor.name ]);
            });
        };
    };
};
var applyRouteParser = {
    apply: function (fx) {
        return function (x) {
            return new Chomp(function (state) {
                var v = runRouteParser(state)(fx);
                if (v instanceof Fail) {
                    return new Fail(v.value0);
                };
                if (v instanceof Success) {
                    return map3(v.value1)(runRouteParser(v.value0)(x));
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 76, column 5 - line 78, column 56): " + [ v.constructor.name ]);
            });
        };
    },
    Functor0: function () {
        return functorRouteParser;
    }
};
var applicativeRouteParser = {
    pure: /* #__PURE__ */ (function () {
        var $345 = Data_Function.flip(Success.create);
        return function ($346) {
            return Chomp.create($345($346));
        };
    })(),
    Apply0: function () {
        return applyRouteParser;
    }
};
var pure = /* #__PURE__ */ Control_Applicative.pure(applicativeRouteParser);
var altSnoc = function (ls) {
    return function (v) {
        var v1 = function (v2) {
            return Data_Array_NonEmpty.snoc(ls)(v);
        };
        if (v instanceof Prefix) {
            var $286 = Data_Array_NonEmpty.last(ls);
            if ($286 instanceof Prefix) {
                var $287 = v.value0 === $286.value0;
                if ($287) {
                    return Data_Array_NonEmpty["snoc$prime"](Data_Array_NonEmpty.init(ls))(new Prefix(v.value0, Control_Alt.alt(altRouteParser)($286.value1)(v.value1)));
                };
                return v1(true);
            };
            return v1(true);
        };
        return v1(true);
    };
};
var altRouteParser = {
    alt: function (v) {
        return function (v1) {
            if (v instanceof Alt && v1 instanceof Alt) {
                return new Alt(altAppend(v.value0)(v1.value0));
            };
            if (v instanceof Alt) {
                return new Alt(altSnoc(v.value0)(v1));
            };
            if (v1 instanceof Alt) {
                return new Alt(altCons(v)(v1.value0));
            };
            if (v instanceof Prefix && (v1 instanceof Prefix && v.value0 === v1.value0)) {
                return new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)(v1.value1));
            };
            return new Alt(Data_Array_NonEmpty.cons(v)(Data_Array_NonEmpty.singleton(v1)));
        };
    },
    Functor0: function () {
        return functorRouteParser;
    }
};
var altCons = function (v) {
    return function (rs) {
        var v1 = function (v2) {
            return Data_Array_NonEmpty.cons(v)(rs);
        };
        if (v instanceof Prefix) {
            var $306 = Data_Array_NonEmpty.head(rs);
            if ($306 instanceof Prefix) {
                var $307 = v.value0 === $306.value0;
                if ($307) {
                    return Data_Array_NonEmpty["cons$prime"](new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)($306.value1)))(Data_Array_NonEmpty.tail(rs));
                };
                return v1(true);
            };
            return v1(true);
        };
        return v1(true);
    };
};
var altAppend = function ($copy_ls) {
    return function ($copy_rs) {
        var $tco_var_ls = $copy_ls;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(ls, rs) {
            var v = function (v1) {
                if (Data_Boolean.otherwise) {
                    return append(ls)(rs);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 98, column 1 - line 101, column 32): " + [ ls.constructor.name, rs.constructor.name ]);
            };
            var $316 = Data_Array_NonEmpty.last(ls);
            if ($316 instanceof Prefix) {
                var $317 = Data_Array_NonEmpty.head(rs);
                if ($317 instanceof Prefix) {
                    var $318 = $316.value0 === $317.value0;
                    if ($318) {
                        var rs$prime = Data_Array_NonEmpty["cons$prime"](new Prefix($316.value0, Control_Alt.alt(altRouteParser)($316.value1)($317.value1)))(Data_Array_NonEmpty.tail(rs));
                        var v1 = Data_Array_NonEmpty.fromArray(Data_Array_NonEmpty.init(ls));
                        if (v1 instanceof Data_Maybe.Just) {
                            $tco_var_ls = v1.value0;
                            $copy_rs = rs$prime;
                            return;
                        };
                        if (v1 instanceof Data_Maybe.Nothing) {
                            $tco_done = true;
                            return rs$prime;
                        };
                        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 110, column 9 - line 112, column 26): " + [ v1.constructor.name ]);
                    };
                    $tco_done = true;
                    return v(true);
                };
                $tco_done = true;
                return v(true);
            };
            $tco_done = true;
            return v(true);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_ls, $copy_rs);
        };
        return $tco_result;
    };
};
var alt = /* #__PURE__ */ Control_Alt.alt(altRouteParser);
var $$default = /* #__PURE__ */ (function () {
    var $347 = Data_Function.flip(alt);
    return function ($348) {
        return $347(pure($348));
    };
})();
var flag = /* #__PURE__ */ (function () {
    var $349 = $$default(false);
    var $350 = map4(Data_Function["const"](true));
    return function ($351) {
        return $349($350(param($351)));
    };
})();
var optional = /* #__PURE__ */ (function () {
    var $352 = $$default(Data_Maybe.Nothing.value);
    var $353 = map4(Data_Maybe.Just.create);
    return function ($354) {
        return $352($353($354));
    };
})();
var many = function (dictAlternative) {
    var Plus1 = dictAlternative.Plus1();
    var many11 = many1(Plus1.Alt0())(dictAlternative.Applicative0());
    var empty = Control_Plus.empty(Plus1);
    return function (p) {
        return alt(many11(p))(pure(empty));
    };
};
export {
    Expected,
    ExpectedEndOfPath,
    MissingParam,
    EndOfPath,
    Fail,
    Success,
    Alt,
    Chomp,
    Prefix,
    runRouteParser,
    parsePath,
    run,
    prefix,
    take,
    param,
    flag,
    many1,
    many,
    rest,
    $$default as default,
    optional,
    as,
    $$int as int,
    $$boolean as boolean,
    hash,
    end,
    eqRouteResult,
    functorRouteResult,
    genericRouteResult,
    showRouteResult,
    eqRouteError,
    genericRouteError,
    showRouteError,
    functorRouteParser,
    applyRouteParser,
    applicativeRouteParser,
    altRouteParser,
    lazyRouteParser
};
