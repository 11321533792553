// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Aff_Compat from "../Effect.Aff.Compat/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var $$void = /* #__PURE__ */ Data_Functor["void"](Concur_Core_Types.widgetFunctor);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map1 = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.widgetApplicative);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var GeneralNearFailure = /* #__PURE__ */ (function () {
    function GeneralNearFailure(value0) {
        this.value0 = value0;
    };
    GeneralNearFailure.create = function (value0) {
        return new GeneralNearFailure(value0);
    };
    return GeneralNearFailure;
})();
var Update = /* #__PURE__ */ (function () {
    function Update(value0) {
        this.value0 = value0;
    };
    Update.create = function (value0) {
        return new Update(value0);
    };
    return Update;
})();
var Submit = /* #__PURE__ */ (function () {
    function Submit() {

    };
    Submit.value = new Submit();
    return Submit;
})();
var Increment = /* #__PURE__ */ (function () {
    function Increment() {

    };
    Increment.value = new Increment();
    return Increment;
})();
var Decrement = /* #__PURE__ */ (function () {
    function Decrement() {

    };
    Decrement.value = new Decrement();
    return Decrement;
})();
var Reset = /* #__PURE__ */ (function () {
    function Reset() {

    };
    Reset.value = new Reset();
    return Reset;
})();
var wrap = function (f) {
    return Effect_Aff_Compat.fromEffectFnAff(f(Data_Either.Left.create)(Data_Either.Right.create));
};
var walletConnected = /* #__PURE__ */ wrap($foreign["_walletConnected"]);
var signOutWallet = /* #__PURE__ */ wrap($foreign["_signOutWallet"]);
var signOutButton = /* #__PURE__ */ bind(/* #__PURE__ */ $$void(/* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap)([ /* #__PURE__ */ Concur_React_Props.href("#"), Concur_React_Props.onClick ])([ /* #__PURE__ */ text("signout") ])))(function () {
    return bind(liftAff(Control_Monad_Except_Trans.runExceptT(signOutWallet)))(function () {
        return text("Signed out! You should probably also de-authorise the app from the near wallet for hygiene");
    });
});
var signInWallet = /* #__PURE__ */ wrap($foreign["_signInWallet"]);
var messages = /* #__PURE__ */ wrap($foreign["_messages"]);
var genericNearFailure = {
    to: function (x) {
        return new GeneralNearFailure(x);
    },
    from: function (x) {
        return x.value0;
    }
};
var nearFailureShow = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericNearFailure)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showString))({
        reflectSymbol: function () {
            return "GeneralNearFailure";
        }
    }))
};
var show = /* #__PURE__ */ Data_Show.show(nearFailureShow);
var listMessagesWidget = /* #__PURE__ */ bind(/* #__PURE__ */ liftAff(/* #__PURE__ */ Control_Monad_Except_Trans.runExceptT(messages)))(function (stuff) {
    if (stuff instanceof Data_Either.Left) {
        return div([  ])([ text(show(stuff.value0)) ]);
    };
    if (stuff instanceof Data_Either.Right) {
        var renderMessage = function (msg) {
            return span([  ])([ text(Data_Maybe.fromMaybe("unknown")(Data_Array.index(msg)(1))) ]);
        };
        var renderAuthor = function (msg) {
            return span([  ])([ text(Data_Maybe.fromMaybe("unknown")(Data_Array.index(msg)(0))) ]);
        };
        return div([  ])(map(function (msg) {
            return div([  ])([ renderAuthor(msg), text(": "), renderMessage(msg) ]);
        })(stuff.value0));
    };
    throw new Error("Failed pattern match at TomWellsOrg.Dapps.GuestMint.Main (line 62, column 5 - line 73, column 117): " + [ stuff.constructor.name ]);
});
var addMessageWidget = function (msg) {
    return bind(span([  ])([ input([ map1(function ($51) {
        return Update.create(Concur_React_Props.unsafeTargetValue($51));
    })(Concur_React_Props.onChange), Concur_React_Props.placeholder("Write a message") ]), button([ voidRight(Submit.value)(Concur_React_Props.onClick) ])([ text("Send") ]) ]))(function (action) {
        if (action instanceof Update) {
            return addMessageWidget(action.value0);
        };
        if (action instanceof Submit) {
            return pure(msg);
        };
        throw new Error("Failed pattern match at TomWellsOrg.Dapps.GuestMint.Main (line 83, column 5 - line 85, column 26): " + [ action.constructor.name ]);
    });
};
var addMessage = function (message) {
    return wrap($foreign["_addMessage"](message));
};
var accountId = /* #__PURE__ */ wrap($foreign["_accountId"]);
var connectWalletThen = function (widget) {
    return bind(liftAff(Control_Monad_Except_Trans.runExceptT(bind1(walletConnected)(function (connected) {
        return bind1(accountId)(function (account) {
            return pure1({
                connected: connected,
                account: account
            });
        });
    }))))(function (stuff) {
        if (stuff instanceof Data_Either.Left) {
            return div([  ])([ text(show(stuff.value0)) ]);
        };
        if (stuff instanceof Data_Either.Right && !stuff.value0.connected) {
            return bind($$void(button([ Concur_React_Props.onClick ])([ text("Connect Wallet to Post") ])))(function () {
                return bind(liftAff(Control_Monad_Except_Trans.runExceptT(signInWallet)))(function () {
                    return text("Requested wallet connection... waiting for Near to redirect...");
                });
            });
        };
        if (stuff instanceof Data_Either.Right && stuff.value0.connected) {
            return div([  ])([ widget, text(" (connected as " + (stuff.value0.account + " - ")), signOutButton, text(")") ]);
        };
        throw new Error("Failed pattern match at TomWellsOrg.Dapps.GuestMint.Main (line 45, column 5 - line 57, column 18): " + [ stuff.constructor.name ]);
    });
};
var $lazy_guestbook = /* #__PURE__ */ $runtime_lazy("guestbook", "TomWellsOrg.Dapps.GuestMint.Main", function () {
    return bind(div([  ])([ listMessagesWidget, connectWalletThen(addMessageWidget("")) ]))(function (newMessage) {
        return bind(alt(text("Executing..."))(liftAff(Control_Monad_Except_Trans.runExceptT(addMessage(newMessage)))))(function () {
            return $lazy_guestbook(94);
        });
    });
});
var guestbook = /* #__PURE__ */ $lazy_guestbook(87);
var root = guestbook;
export {
    _messages,
    _addMessage,
    _walletConnected,
    _signInWallet,
    _signOutWallet,
    _accountId
} from "./foreign.js";
export {
    GeneralNearFailure,
    Increment,
    Decrement,
    Reset,
    signOutButton,
    connectWalletThen,
    listMessagesWidget,
    Update,
    Submit,
    addMessageWidget,
    guestbook,
    root,
    wrap,
    messages,
    addMessage,
    walletConnected,
    signInWallet,
    signOutWallet,
    accountId,
    genericNearFailure,
    nearFailureShow
};
