// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var PrimProp = /* #__PURE__ */ (function () {
    function PrimProp(value0) {
        this.value0 = value0;
    };
    PrimProp.create = function (value0) {
        return new PrimProp(value0);
    };
    return PrimProp;
})();
var Handler = /* #__PURE__ */ (function () {
    function Handler(value0) {
        this.value0 = value0;
    };
    Handler.create = function (value0) {
        return new Handler(value0);
    };
    return Handler;
})();
var mkProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return v1.value0;
        };
        if (v1 instanceof Handler) {
            return v1.value0(v);
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 18, column 1 - line 22, column 7): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var handleProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return new PrimProp(v1.value0);
        };
        if (v1 instanceof Handler) {
            return new PrimProp(v1.value0(v));
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 27, column 1 - line 31, column 15): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var functorProps = {
    map: function (v) {
        return function (v1) {
            if (v1 instanceof PrimProp) {
                return new PrimProp(v1.value0);
            };
            if (v1 instanceof Handler) {
                return new Handler(function (k) {
                    return v1.value0(function ($27) {
                        return k(v($27));
                    });
                });
            };
            throw new Error("Failed pattern match at Concur.Core.Props (line 13, column 1 - line 15, column 48): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var filterProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return v1;
        };
        if (v1 instanceof Handler) {
            return new Handler(function (h) {
                return v1.value0(function (a) {
                    var $25 = v(a);
                    if ($25) {
                        return h(a);
                    };
                    return pure(Data_Unit.unit);
                });
            });
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 37, column 1 - line 41, column 12): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
export {
    PrimProp,
    Handler,
    mkProp,
    handleProp,
    filterProp,
    functorProps
};
