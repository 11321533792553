// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
var clamp = /* #__PURE__ */ Data_Ord.clamp(Data_Ord.ordInt);
var max = /* #__PURE__ */ Data_Ord.max(Data_Ord.ordInt);
var min = /* #__PURE__ */ Data_Ord.min(Data_Ord.ordInt);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var minimumBy = /* #__PURE__ */ Data_Foldable.minimumBy(Data_Foldable.foldableArray);
var comparing = /* #__PURE__ */ Data_Ord.comparing(Data_Ord.ordNumber);
var clamp1 = /* #__PURE__ */ Data_Ord.clamp(Data_Ord.ordNumber);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var join = /* #__PURE__ */ Control_Bind.join(Data_Maybe.bindMaybe);
var alt = /* #__PURE__ */ Control_Alt.alt(Data_Maybe.altMaybe);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showNumber);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var UnclippedHue = function (x) {
    return x;
};
var RGB = /* #__PURE__ */ (function () {
    function RGB() {

    };
    RGB.value = new RGB();
    return RGB;
})();
var HSL = /* #__PURE__ */ (function () {
    function HSL() {

    };
    HSL.value = new HSL();
    return HSL;
})();
var LCh = /* #__PURE__ */ (function () {
    function LCh() {

    };
    LCh.value = new LCh();
    return LCh;
})();
var Lab = /* #__PURE__ */ (function () {
    function Lab() {

    };
    Lab.value = new Lab();
    return Lab;
})();
var HSLA = /* #__PURE__ */ (function () {
    function HSLA(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    HSLA.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new HSLA(value0, value1, value2, value3);
                };
            };
        };
    };
    return HSLA;
})();
var modPos = function (x) {
    return function (y) {
        return Data_Number.remainder(Data_Number.remainder(x)(y) + y)(y);
    };
};
var rgba = function (red$prime) {
    return function (green$prime) {
        return function (blue$prime) {
            return function (alpha) {
                var red = clamp(0)(255)(red$prime);
                var r = Data_Int.toNumber(red) / 255.0;
                var green = clamp(0)(255)(green$prime);
                var g = Data_Int.toNumber(green) / 255.0;
                var blue = clamp(0)(255)(blue$prime);
                var maxChroma = max(max(red)(green))(blue);
                var minChroma = min(min(red)(green))(blue);
                var chroma = maxChroma - minChroma | 0;
                var chroma$prime = Data_Int.toNumber(chroma) / 255.0;
                var lightness = Data_Int.toNumber(maxChroma + minChroma | 0) / (255.0 * 2.0);
                var saturation = (function () {
                    if (chroma === 0) {
                        return 0.0;
                    };
                    if (Data_Boolean.otherwise) {
                        return chroma$prime / (1.0 - Data_Number.abs(2.0 * lightness - 1.0));
                    };
                    throw new Error("Failed pattern match at Color (line 160, column 3 - line 162, column 64): " + [  ]);
                })();
                var b = Data_Int.toNumber(blue) / 255.0;
                var hue$prime = function (v) {
                    if (v === 0) {
                        return 0.0;
                    };
                    if (maxChroma === red) {
                        return modPos((g - b) / chroma$prime)(6.0);
                    };
                    if (maxChroma === green) {
                        return (b - r) / chroma$prime + 2.0;
                    };
                    if (Data_Boolean.otherwise) {
                        return (r - g) / chroma$prime + 4.0;
                    };
                    throw new Error("Failed pattern match at Color (line 150, column 3 - line 150, column 15): " + [ v.constructor.name ]);
                };
                var hue = 60.0 * hue$prime(chroma);
                return new HSLA(hue, saturation, lightness, alpha);
            };
        };
    };
};
var rgb = function (r) {
    return function (g) {
        return function (b) {
            return rgba(r)(g)(b)(1.0);
        };
    };
};
var rgba$prime = function (r) {
    return function (g) {
        return function (b) {
            return function (a) {
                return rgba(Data_Int.round(r * 255.0))(Data_Int.round(g * 255.0))(Data_Int.round(b * 255.0))(a);
            };
        };
    };
};
var rgb$prime = function (r) {
    return function (g) {
        return function (b) {
            return rgba$prime(r)(g)(b)(1.0);
        };
    };
};
var xyz = function (x) {
    return function (y) {
        return function (z) {
            var f = function (c) {
                if (c <= 3.1308e-3) {
                    return 12.92 * c;
                };
                if (Data_Boolean.otherwise) {
                    return 1.055 * Data_Number.pow(c)(1.0 / 2.4) - 5.5e-2;
                };
                throw new Error("Failed pattern match at Color (line 229, column 3 - line 231, column 56): " + [ c.constructor.name ]);
            };
            var g = f(-0.9689 * x + 1.8758 * y + 4.15e-2 * z);
            var r = f(3.2406 * x - 1.5372 * y - 0.4986 * z);
            var b = f((5.57e-2 * x - 0.204 * y) + 1.057 * z);
            return rgb$prime(r)(g)(b);
        };
    };
};
var interpolate = function (fraction) {
    return function (a) {
        return function (b) {
            return a + fraction * (b - a);
        };
    };
};
var interpolateAngle = function (fraction) {
    return function (a) {
        return function (b) {
            var paths = [ {
                from: a,
                to: b
            }, {
                from: a,
                to: b + 360.0
            }, {
                from: a + 360.0,
                to: b
            } ];
            var dist = function (v) {
                return Data_Number.abs(v.to - v.from);
            };
            var shortest = fromJust(minimumBy(comparing(dist))(paths));
            return interpolate(fraction)(shortest.from)(shortest.to);
        };
    };
};
var mixCubehelix = function (gamma) {
    return function (v) {
        return function (v1) {
            var radians = Data_Number.pi / 180.0;
            var bs = v1.value1 - v.value1;
            var bl = v1.value2 - v.value2;
            var ah = (v.value0 + 120.0) * radians;
            var bh = (v1.value0 + 120.0) * radians - ah;
            return function (t) {
                var fract = Data_Number.pow(v.value2 + bl * t)(gamma);
                var angle = ah + bh * t;
                var amp = (v.value1 + bs * t) * fract * (1.0 - fract);
                var b = fract + amp * (1.97294 * Data_Number.cos(angle));
                var g = fract + amp * (-0.29227 * Data_Number.cos(angle) - 0.90649 * Data_Number.sin(angle));
                var r = fract + amp * (-0.14861 * Data_Number.cos(angle) + 1.78277 * Data_Number.sin(angle));
                var a = interpolate(t)(v.value3)(v1.value3);
                return rgba$prime(r)(g)(b)(a);
            };
        };
    };
};
var hsla = function (h) {
    return function (s) {
        return function (l) {
            return function (a) {
                var s$prime = clamp1(0.0)(1.0)(s);
                var l$prime = clamp1(0.0)(1.0)(l);
                var a$prime = clamp1(0.0)(1.0)(a);
                return new HSLA(h, s$prime, l$prime, a$prime);
            };
        };
    };
};
var hsva = function (h) {
    return function (v) {
        return function (v1) {
            return function (a) {
                var s = v;
                if (v1 === 0.0) {
                    return hsla(h)(s / (2.0 - s))(0.0)(a);
                };
                if (v === 0.0 && v1 === 1.0) {
                    return hsla(h)(0.0)(1.0)(a);
                };
                var tmp = (2.0 - v) * v1;
                var s = (v * v1) / (function () {
                    var $89 = tmp < 1.0;
                    if ($89) {
                        return tmp;
                    };
                    return 2.0 - tmp;
                })();
                var l = tmp / 2.0;
                return hsla(h)(s)(l)(a);
            };
        };
    };
};
var hsv = function (h) {
    return function (s) {
        return function (v) {
            return hsva(h)(s)(v)(1.0);
        };
    };
};
var lighten = function (f) {
    return function (v) {
        return hsla(v.value0)(v.value1)(v.value2 + f)(v.value3);
    };
};
var rotateHue = function (angle) {
    return function (v) {
        return hsla(v.value0 + angle)(v.value1)(v.value2)(v.value3);
    };
};
var saturate = function (f) {
    return function (v) {
        return hsla(v.value0)(v.value1 + f)(v.value2)(v.value3);
    };
};
var hsl = function (h) {
    return function (s) {
        return function (l) {
            return hsla(h)(s)(l)(1.0);
        };
    };
};
var white = /* #__PURE__ */ hsl(0.0)(0.0)(1.0);
var graytone = function (l) {
    return hsl(0.0)(0.0)(l);
};
var fromInt = function (m) {
    var n = clamp(0)(16777215)(m);
    var r = n >> 16 & 255;
    var g = n >> 8 & 255;
    var b = n & 255;
    return rgb(r)(g)(b);
};
var fromHexString = function (str) {
    var parseHex = (function () {
        var $155 = Data_Maybe.fromMaybe(0);
        var $156 = Data_Int.fromStringAs(Data_Int.hexadecimal);
        return function ($157) {
            return $155($156($157));
        };
    })();
    var isShort = Data_String_CodePoints.length(str) < 6;
    var hush = Data_Either.either(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
    var pair = "(" + ("[0-9a-f]" + ("[0-9a-f]" + ")"));
    var single = "(" + ("[0-9a-f]" + ")");
    var variant = (function () {
        if (isShort) {
            return single + (single + (single + (single + "?")));
        };
        return pair + (pair + (pair + (pair + "?")));
    })();
    var mPattern = Data_String_Regex.regex("^#(?:" + (variant + ")$"))(Data_String_Regex.parseFlags("i"));
    return bind(hush(mPattern))(function (pattern) {
        return bind(Data_String_Regex.match(pattern)(str))(function (groups) {
            return bind(map(parseHex)(join(Data_Array_NonEmpty.index(groups)(1))))(function (r) {
                return bind(map(parseHex)(join(Data_Array_NonEmpty.index(groups)(2))))(function (g) {
                    return bind(map(parseHex)(join(Data_Array_NonEmpty.index(groups)(3))))(function (b) {
                        return bind(map(parseHex)(alt(join(Data_Array_NonEmpty.index(groups)(4)))(pure((function () {
                            if (isShort) {
                                return "f";
                            };
                            return "ff";
                        })()))))(function (a) {
                            if (isShort) {
                                var alpha = Data_Int.toNumber(clamp(0)(15)(a)) / 15.0;
                                return pure(rgba((16 * r | 0) + r | 0)((16 * g | 0) + g | 0)((16 * b | 0) + b | 0)(alpha));
                            };
                            var alpha = Data_Int.toNumber(clamp(0)(255)(a)) / 255.0;
                            return pure(rgba(r)(g)(b)(alpha));
                        });
                    });
                });
            });
        });
    });
};
var desaturate = function (f) {
    return saturate(-f);
};
var darken = function (f) {
    return lighten(-f);
};
var d65 = {
    xn: 0.95047,
    yn: 1.0,
    zn: 1.08883
};
var lab = function (l) {
    return function (a) {
        return function (b) {
            var l$prime = (l + 16.0) / 116.0;
            var delta = 6.0 / 29.0;
            var finv = function (t) {
                if (t > delta) {
                    return Data_Number.pow(t)(3.0);
                };
                if (Data_Boolean.otherwise) {
                    return 3.0 * delta * delta * (t - 4.0 / 29.0);
                };
                throw new Error("Failed pattern match at Color (line 255, column 3 - line 257, column 57): " + [ t.constructor.name ]);
            };
            var x = d65.xn * finv(l$prime + a / 500.0);
            var y = d65.yn * finv(l$prime);
            var z = d65.zn * finv(l$prime - b / 200.0);
            return xyz(x)(y)(z);
        };
    };
};
var lch = function (l) {
    return function (c) {
        return function (h) {
            var deg2rad = Data_Number.pi / 180.0;
            var b = c * Data_Number.sin(h * deg2rad);
            var a = c * Data_Number.cos(h * deg2rad);
            return lab(l)(a)(b);
        };
    };
};
var cssStringHSLA = function (v) {
    var toString = function (n) {
        return show(Data_Int.toNumber(Data_Int.round(100.0 * n)) / 100.0);
    };
    var saturation = toString(v.value1 * 100.0) + "%";
    var lightness = toString(v.value2 * 100.0) + "%";
    var hue = toString(v.value0);
    var alpha = show(v.value3);
    var $113 = v.value3 === 1.0;
    if ($113) {
        return "hsl(" + (hue + (", " + (saturation + (", " + (lightness + ")")))));
    };
    return "hsla(" + (hue + (", " + (saturation + (", " + (lightness + (", " + (alpha + ")")))))));
};
var complementary = /* #__PURE__ */ rotateHue(180.0);
var clipHue = function (v) {
    var $119 = 360.0 === v;
    if ($119) {
        return v;
    };
    return modPos(v)(360.0);
};
var toHSLA = function (v) {
    return {
        h: clipHue(v.value0),
        s: v.value1,
        l: v.value2,
        a: v.value3
    };
};
var toHSVA = function (v) {
    var s = v.value1;
    if (v.value2 === 0.0) {
        return {
            h: clipHue(v.value0),
            s: (2.0 * s) / (1.0 + s),
            v: 0.0,
            a: v.value3
        };
    };
    if (v.value1 === 0.0 && v.value2 === 1.0) {
        return {
            h: clipHue(v.value0),
            s: 0.0,
            v: 1.0,
            a: v.value3
        };
    };
    var tmp = v.value1 * (function () {
        var $134 = v.value2 < 0.5;
        if ($134) {
            return v.value2;
        };
        return 1.0 - v.value2;
    })();
    var v1 = v.value2 + tmp;
    var s = (2.0 * tmp) / (v.value2 + tmp);
    return {
        h: clipHue(v.value0),
        s: s,
        v: v1,
        a: v.value3
    };
};
var toRGBA$prime = function (v) {
    var h$prime = clipHue(v.value0) / 60.0;
    var chr = (1.0 - Data_Number.abs(2.0 * v.value2 - 1.0)) * v.value1;
    var m = v.value2 - chr / 2.0;
    var x = chr * (1.0 - Data_Number.abs(Data_Number.remainder(h$prime)(2.0) - 1.0));
    var col = (function () {
        if (h$prime < 1.0) {
            return {
                r: chr,
                g: x,
                b: 0.0
            };
        };
        if (1.0 <= h$prime && h$prime < 2.0) {
            return {
                r: x,
                g: chr,
                b: 0.0
            };
        };
        if (2.0 <= h$prime && h$prime < 3.0) {
            return {
                r: 0.0,
                g: chr,
                b: x
            };
        };
        if (3.0 <= h$prime && h$prime < 4.0) {
            return {
                r: 0.0,
                g: x,
                b: chr
            };
        };
        if (4.0 <= h$prime && h$prime < 5.0) {
            return {
                r: x,
                g: 0.0,
                b: chr
            };
        };
        if (Data_Boolean.otherwise) {
            return {
                r: chr,
                g: 0.0,
                b: x
            };
        };
        throw new Error("Failed pattern match at Color (line 356, column 3 - line 362, column 43): " + [  ]);
    })();
    return {
        r: col.r + m,
        g: col.g + m,
        b: col.b + m,
        a: v.value3
    };
};
var luminance = function (col) {
    var val = toRGBA$prime(col);
    var f = function (c) {
        if (c <= 3.928e-2) {
            return c / 12.92;
        };
        if (Data_Boolean.otherwise) {
            return Data_Number.pow((c + 5.5e-2) / 1.055)(2.4);
        };
        throw new Error("Failed pattern match at Color (line 632, column 3 - line 634, column 50): " + [ c.constructor.name ]);
    };
    var g = f(val.g);
    var r = f(val.r);
    var b = f(val.b);
    return 0.2126 * r + 0.7152 * g + 7.22e-2 * b;
};
var contrast = function (c1) {
    return function (c2) {
        var l2 = luminance(c2);
        var l1 = luminance(c1);
        var $145 = l1 > l2;
        if ($145) {
            return (l1 + 5.0e-2) / (l2 + 5.0e-2);
        };
        return (l2 + 5.0e-2) / (l1 + 5.0e-2);
    };
};
var isReadable = function (c1) {
    return function (c2) {
        return contrast(c1)(c2) > 4.5;
    };
};
var toRGBA = function (col) {
    var c = toRGBA$prime(col);
    var g = Data_Int.round(255.0 * c.g);
    var r = Data_Int.round(255.0 * c.r);
    var b = Data_Int.round(255.0 * c.b);
    return {
        r: r,
        g: g,
        b: b,
        a: c.a
    };
};
var cssStringRGBA = function (col) {
    var c = toRGBA(col);
    var green = show1(c.g);
    var red = show1(c.r);
    var blue = show1(c.b);
    var alpha = show(c.a);
    var $146 = c.a === 1.0;
    if ($146) {
        return "rgb(" + (red + (", " + (green + (", " + (blue + ")")))));
    };
    return "rgba(" + (red + (", " + (green + (", " + (blue + (", " + (alpha + ")")))))));
};
var eqColor = {
    eq: /* #__PURE__ */ Data_Function.on(/* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqRec()(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
        reflectSymbol: function () {
            return "r";
        }
    })(Data_Eq.eqInt))()({
        reflectSymbol: function () {
            return "g";
        }
    })(Data_Eq.eqInt))()({
        reflectSymbol: function () {
            return "b";
        }
    })(Data_Eq.eqInt))()({
        reflectSymbol: function () {
            return "a";
        }
    })(Data_Eq.eqNumber))))(toRGBA)
};
var showColor = {
    show: function (c) {
        var col = toRGBA(c);
        return Data_String_Common.joinWith(" ")([ "rgba", show1(col.r), show1(col.g), show1(col.b), show(col.a) ]);
    }
};
var toHexString = function (color) {
    var toHex = function (num) {
        var repr = Data_Int.toStringAs(Data_Int.hexadecimal)(num);
        var $147 = Data_String_CodePoints.length(repr) === 1;
        if ($147) {
            return "0" + repr;
        };
        return repr;
    };
    var c = toRGBA(color);
    var alpha = (function () {
        if (c.a === 1.0) {
            return "";
        };
        if (Data_Boolean.otherwise) {
            return toHex(Data_Int.round(255.0 * c.a));
        };
        throw new Error("Failed pattern match at Color (line 429, column 3 - line 431, column 46): " + [  ]);
    })();
    return "#" + (toHex(c.r) + (toHex(c.g) + (toHex(c.b) + alpha)));
};
var toXYZ = function (c) {
    var rec = toRGBA$prime(c);
    var finv = function (c$prime) {
        if (c$prime <= 4.045e-2) {
            return c$prime / 12.92;
        };
        if (Data_Boolean.otherwise) {
            return Data_Number.pow((c$prime + 5.5e-2) / 1.055)(2.4);
        };
        throw new Error("Failed pattern match at Color (line 381, column 3 - line 383, column 51): " + [ c$prime.constructor.name ]);
    };
    var g = finv(rec.g);
    var r = finv(rec.r);
    var b = finv(rec.b);
    var x = 0.4124 * r + 0.3576 * g + 0.1805 * b;
    var y = 0.2126 * r + 0.7152 * g + 7.22e-2 * b;
    var z = 1.93e-2 * r + 0.1192 * g + 0.9505 * b;
    return {
        x: x,
        y: y,
        z: z
    };
};
var toLab = function (col) {
    var rec = toXYZ(col);
    var cut = Data_Number.pow(6.0 / 29.0)(3.0);
    var f = function (t) {
        if (t > cut) {
            return Data_Number.pow(t)(1.0 / 3.0);
        };
        if (Data_Boolean.otherwise) {
            return (1.0 / 3.0) * Data_Number.pow(29.0 / 6.0)(2.0) * t + 4.0 / 29.0;
        };
        throw new Error("Failed pattern match at Color (line 400, column 3 - line 402, column 72): " + [ t.constructor.name ]);
    };
    var fy = f(rec.y / d65.yn);
    var l = 116.0 * fy - 16.0;
    var b = 200.0 * (fy - f(rec.z / d65.zn));
    var a = 500.0 * (f(rec.x / d65.xn) - fy);
    return {
        l: l,
        a: a,
        b: b
    };
};
var distance = function (col1) {
    return function (col2) {
        var sq = function (x) {
            return Data_Number.pow(x)(2.0);
        };
        var c2 = toLab(col2);
        var c1 = toLab(col1);
        return Data_Number.sqrt(sq(c1.l - c2.l) + sq(c1.a - c2.a) + sq(c1.b - c2.b));
    };
};
var toLCh = function (col) {
    var rec = toLab(col);
    var rad2deg = 180.0 / Data_Number.pi;
    var c = Data_Number.sqrt(rec.a * rec.a + rec.b * rec.b);
    var h = modPos(Data_Number.atan2(rec.b)(rec.a) * rad2deg)(360.0);
    return {
        l: rec.l,
        c: c,
        h: h
    };
};
var mix = function (v) {
    return function (c1) {
        return function (c2) {
            return function (frac) {
                if (v instanceof HSL) {
                    var t = toHSLA(c2);
                    var f = toHSLA(c1);
                    return hsla(interpolateAngle(frac)(f.h)(t.h))(interpolate(frac)(f.s)(t.s))(interpolate(frac)(f.l)(t.l))(interpolate(frac)(f.a)(t.a));
                };
                if (v instanceof RGB) {
                    var t = toRGBA$prime(c2);
                    var f = toRGBA$prime(c1);
                    return rgba$prime(interpolate(frac)(f.r)(t.r))(interpolate(frac)(f.g)(t.g))(interpolate(frac)(f.b)(t.b))(interpolate(frac)(f.a)(t.a));
                };
                if (v instanceof LCh) {
                    var t = toLCh(c2);
                    var f = toLCh(c1);
                    return lch(interpolate(frac)(f.l)(t.l))(interpolate(frac)(f.c)(t.c))(interpolateAngle(frac)(f.h)(t.h));
                };
                if (v instanceof Lab) {
                    var t = toLab(c2);
                    var f = toLab(c1);
                    return lab(interpolate(frac)(f.l)(t.l))(interpolate(frac)(f.a)(t.a))(interpolate(frac)(f.b)(t.b));
                };
                throw new Error("Failed pattern match at Color (line 545, column 1 - line 545, column 34): " + [ v.constructor.name, c1.constructor.name, c2.constructor.name, frac.constructor.name ]);
            };
        };
    };
};
var toGray = function (col) {
    var res = toLCh(col);
    return desaturate(1.0)(lch(res.l)(0.0)(0.0));
};
var brightness = function (col) {
    var c = toRGBA$prime(col);
    return (299.0 * c.r + 587.0 * c.g + 114.0 * c.b) / 1000.0;
};
var isLight = function (c) {
    return brightness(c) > 0.5;
};
var black = /* #__PURE__ */ hsl(0.0)(0.0)(0.0);
var textColor = function (c) {
    if (isLight(c)) {
        return black;
    };
    if (Data_Boolean.otherwise) {
        return white;
    };
    throw new Error("Failed pattern match at Color (line 673, column 1 - line 673, column 28): " + [ c.constructor.name ]);
};
export {
    RGB,
    HSL,
    LCh,
    Lab,
    rgba,
    rgb,
    rgba$prime,
    rgb$prime,
    hsla,
    hsl,
    hsva,
    hsv,
    xyz,
    lab,
    lch,
    fromHexString,
    fromInt,
    toHSLA,
    toHSVA,
    toRGBA,
    toRGBA$prime,
    toXYZ,
    toLab,
    toLCh,
    toHexString,
    cssStringHSLA,
    cssStringRGBA,
    black,
    white,
    graytone,
    rotateHue,
    complementary,
    lighten,
    darken,
    saturate,
    desaturate,
    toGray,
    mix,
    mixCubehelix,
    brightness,
    luminance,
    contrast,
    isLight,
    isReadable,
    textColor,
    distance,
    showColor,
    eqColor
};
