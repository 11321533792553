// Generated by purs version 0.15.4
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Ring from "../Data.Ring/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
var Ratio = /* #__PURE__ */ (function () {
    function Ratio(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Ratio.create = function (value0) {
        return function (value1) {
            return new Ratio(value0, value1);
        };
    };
    return Ratio;
})();
var showRatio = function (dictShow) {
    var show = Data_Show.show(dictShow);
    return {
        show: function (v) {
            return show(v.value0) + (" % " + show(v.value1));
        }
    };
};
var reduce = function (dictOrd) {
    var gcd = Data_EuclideanRing.gcd(dictOrd.Eq0());
    var signum = Data_Ord.signum(dictOrd);
    var abs = Data_Ord.abs(dictOrd);
    return function (dictEuclideanRing) {
        var gcd1 = gcd(dictEuclideanRing);
        var div = Data_EuclideanRing.div(dictEuclideanRing);
        var Ring0 = (dictEuclideanRing.CommutativeRing0()).Ring0();
        var mul = Data_Semiring.mul(Ring0.Semiring0());
        var signum1 = signum(Ring0);
        var abs1 = abs(Ring0);
        return function (n) {
            return function (d) {
                var g = gcd1(n)(d);
                var d$prime = div(d)(g);
                return new Ratio(mul(div(n)(g))(signum1(d$prime)), abs1(d$prime));
            };
        };
    };
};
var semiringRatio = function (dictOrd) {
    var reduce1 = reduce(dictOrd);
    return function (dictEuclideanRing) {
        var Semiring0 = ((dictEuclideanRing.CommutativeRing0()).Ring0()).Semiring0();
        var one = Data_Semiring.one(Semiring0);
        var reduce2 = reduce1(dictEuclideanRing);
        var mul = Data_Semiring.mul(Semiring0);
        var add = Data_Semiring.add(Semiring0);
        return {
            one: new Ratio(one, one),
            mul: function (v) {
                return function (v1) {
                    return reduce2(mul(v.value0)(v1.value0))(mul(v.value1)(v1.value1));
                };
            },
            zero: new Ratio(Data_Semiring.zero(Semiring0), one),
            add: function (v) {
                return function (v1) {
                    return reduce2(add(mul(v.value0)(v1.value1))(mul(v.value1)(v1.value0)))(mul(v.value1)(v1.value1));
                };
            }
        };
    };
};
var ringRatio = function (dictOrd) {
    var reduce1 = reduce(dictOrd);
    var semiringRatio1 = semiringRatio(dictOrd);
    return function (dictEuclideanRing) {
        var reduce2 = reduce1(dictEuclideanRing);
        var Ring0 = (dictEuclideanRing.CommutativeRing0()).Ring0();
        var sub = Data_Ring.sub(Ring0);
        var mul = Data_Semiring.mul(Ring0.Semiring0());
        var semiringRatio2 = semiringRatio1(dictEuclideanRing);
        return {
            sub: function (v) {
                return function (v1) {
                    return reduce2(sub(mul(v.value0)(v1.value1))(mul(v.value1)(v1.value0)))(mul(v.value1)(v1.value1));
                };
            },
            Semiring0: function () {
                return semiringRatio2;
            }
        };
    };
};
var numerator = function (v) {
    return v.value0;
};
var eqRatio = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return {
        eq: function (v) {
            return function (v1) {
                return eq(v.value0)(v1.value0) && eq(v.value1)(v1.value1);
            };
        }
    };
};
var ordRatio = function (dictOrd) {
    var ringRatio1 = ringRatio(dictOrd);
    var Eq0 = dictOrd.Eq0();
    var eq = Data_Eq.eq(Eq0);
    var greaterThan = Data_Ord.greaterThan(dictOrd);
    var eqRatio1 = eqRatio(Eq0);
    return function (dictEuclideanRing) {
        var sub = Data_Ring.sub(ringRatio1(dictEuclideanRing));
        var zero = Data_Semiring.zero(((dictEuclideanRing.CommutativeRing0()).Ring0()).Semiring0());
        return {
            compare: function (x) {
                return function (y) {
                    var v = sub(x)(y);
                    var $130 = eq(v.value0)(zero);
                    if ($130) {
                        return Data_Ordering.EQ.value;
                    };
                    var v1 = greaterThan(v.value1)(zero);
                    var v2 = greaterThan(v.value0)(zero);
                    if (v2 && v1) {
                        return Data_Ordering.GT.value;
                    };
                    if (!v2 && !v1) {
                        return Data_Ordering.GT.value;
                    };
                    return Data_Ordering.LT.value;
                };
            },
            Eq0: function () {
                return eqRatio1;
            }
        };
    };
};
var divisionRingRatio = function (dictOrd) {
    var ringRatio1 = ringRatio(dictOrd);
    return function (dictEuclideanRing) {
        var ringRatio2 = ringRatio1(dictEuclideanRing);
        return {
            recip: function (v) {
                return new Ratio(v.value1, v.value0);
            },
            Ring0: function () {
                return ringRatio2;
            }
        };
    };
};
var denominator = function (v) {
    return v.value1;
};
var commutativeRingRatio = function (dictOrd) {
    var ringRatio1 = ringRatio(dictOrd);
    return function (dictEuclideanRing) {
        var ringRatio2 = ringRatio1(dictEuclideanRing);
        return {
            Ring0: function () {
                return ringRatio2;
            }
        };
    };
};
var euclideanRingRatio = function (dictOrd) {
    var reduce1 = reduce(dictOrd);
    var semiringRatio1 = semiringRatio(dictOrd);
    var commutativeRingRatio1 = commutativeRingRatio(dictOrd);
    return function (dictEuclideanRing) {
        var reduce2 = reduce1(dictEuclideanRing);
        var mul = Data_Semiring.mul(((dictEuclideanRing.CommutativeRing0()).Ring0()).Semiring0());
        var zero = Data_Semiring.zero(semiringRatio1(dictEuclideanRing));
        var commutativeRingRatio2 = commutativeRingRatio1(dictEuclideanRing);
        return {
            degree: function (v) {
                return 1;
            },
            div: function (v) {
                return function (v1) {
                    return reduce2(mul(v.value0)(v1.value1))(mul(v.value1)(v1.value0));
                };
            },
            mod: function (v) {
                return function (v1) {
                    return zero;
                };
            },
            CommutativeRing0: function () {
                return commutativeRingRatio2;
            }
        };
    };
};
export {
    reduce,
    numerator,
    denominator,
    showRatio,
    eqRatio,
    ordRatio,
    semiringRatio,
    ringRatio,
    commutativeRingRatio,
    euclideanRingRatio,
    divisionRingRatio
};
